import jwt from 'jsonwebtoken'

export const parseLoginData = (token) => {
  try {
    const decoded = jwt.decode(token)
    return decoded
  } catch (err) {
    return 'error'
  }
}

export const validateDueDate = (token) => {
  try {
    const decoded = jwt.decode(token)
    return decoded
  } catch (err) {
    if (err.name === 'TokenExpiredError') {
      return 'expired'
    }
    return 'error'
  }
}
