import React, { useEffect, useMemo, useReducer, useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Typography from 'antd/lib/typography'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { requestAssignTrustPercentage, clearCreateTrustPercentage } from 'store/duck/assignTrustPercentage.duck'
import { requestGetTruckTypes } from 'store/duck/getTruckTypes.duck'
import { requestUpdateTrustPercentage } from 'store/duck/updateTrustPercentage.duck'
import { requestGetHistory } from 'store/duck/getHistory.duck'
import PrimarySubmit from 'components/PrimarySubmit'
import TrustPercentageItem from 'components/TrustPercentageItem'
import StyledTabs from 'components/Tabs'
import { EnumStatus } from 'utils/getStatus'
import Modal from 'components/Modal'
import HistoryItems from 'components/HistoryItem'
import FloatLabel from 'components/Label/FloatLabel'
import Tooltip from 'antd/lib/tooltip'
import { FiPauseCircle } from 'react-icons/fi'
import notify from 'utils/notify'
import colors from 'providers/theme/config/colorPallete'
import { GoInfo } from 'react-icons/go'
import reducer from './utils/reducer'
import StyledItem from './styles'

const TrustPercentageModal = ({ visible, onCloseDrawer }) => {
  const dispatch = useDispatch()

  const { Text } = Typography
  const [form] = Form.useForm()
  const {
    success,
    error,
    message,
    truckTypes,
    successUpdate,
    errorUpdate,
    trustPercentageSelected,
    editTrustPercentage,
    getHistory,
  } = useSelector((state) => ({
    success: state.assignTrustPercentage.success,
    error: state.assignTrustPercentage.error,
    message: state.assignTrustPercentage.message,
    truckTypes: state.getTruckTypes.trucktypes,
    successUpdate: state.updateTrustPercentage.success,
    errorUpdate: state.updateTrustPercentage.error,
    editTrustPercentage: state.assignTrustPercentage.editTrustPercentage,
    trustPercentageSelected: state.assignTrustPercentage.trustPercentageSelected,
    getHistory: state.getHistory.history,
  }))
  const initialState = {
    loading: false,
    isUpdating: true,
    btnSubmit: true,
    percentageWarning: true,
    total: 0,
  }
  const [{ loading, isUpdating, percentageWarning, total, btnSubmit }, setValues] = useReducer(
    reducer,
    initialState
  )
  const defaultValues = useMemo(
    () => ({
      datAvg: editTrustPercentage ? trustPercentageSelected.datAvg : 0,
      low: editTrustPercentage ? trustPercentageSelected.low : 0,
      high: editTrustPercentage ? trustPercentageSelected.high : 0,
      contract: editTrustPercentage ? trustPercentageSelected.contract : 0,
      truckTypeId: editTrustPercentage ? trustPercentageSelected.truckTypeId._id : '',
      datalake_x: editTrustPercentage ? trustPercentageSelected.datalake_x : 0,
      datalake_b: editTrustPercentage ? trustPercentageSelected.datalake_b : 0,
      gsAvg: editTrustPercentage ? trustPercentageSelected.gsAvg : 0,
      gs_threshold: editTrustPercentage ? trustPercentageSelected.gs_threshold : 0,
    }),
    [editTrustPercentage, trustPercentageSelected]
  )

  const [status, setStatus] = useState('Activated')
  useEffect(() => {
    if (editTrustPercentage) {
      setStatus(trustPercentageSelected.status)
      let totalSum = 0
      const avoidProps = ['datalake_x', 'datalake_b', 'gs_threshold', 'truckTypeId']

      for (const property in defaultValues) {
        if (!avoidProps.includes(property)) totalSum += defaultValues[property]
      }
      if (totalSum === 100 && defaultValues.truckTypeId) {
        setValues({ type: 'active-submit', btnSubmit: false, percentageWarning: false, total: totalSum })
      }
    }
  }, [editTrustPercentage, trustPercentageSelected.status, defaultValues])

  const pauseStatus = () => {
    setStatus(EnumStatus.Paused)
  }

  useEffect(() => {
    if (visible) {
      dispatch(requestGetHistory({ id: trustPercentageSelected._id }))
    }
  }, [dispatch, visible, trustPercentageSelected._id])

  useEffect(() => {
    dispatch(requestGetTruckTypes())
  }, [dispatch])

  useEffect(() => {
    if (!loading && Object.keys(trustPercentageSelected).length > 0) {
      form.setFieldsValue(defaultValues)
      setValues({ type: 'updating', btnSubmit: false, total: 100, isUpdating: false })
    }
  }, [isUpdating, defaultValues, form, loading, trustPercentageSelected])

  useEffect(() => {
    if (successUpdate || errorUpdate) {
      setValues({ type: 'loading', loading: false })
      onCloseDrawer(false)
    }
  }, [errorUpdate, successUpdate, onCloseDrawer])

  useEffect(() => {
    if (success) {
      setValues({ type: 'loading', loading: false })
      notify('success', 'Trust percentage saved successfully!')
      form.resetFields()
      dispatch(clearCreateTrustPercentage())
      onCloseDrawer(false)
    }
    if (error) {
      setValues({ type: 'loading', loading: false })
      Swal.fire({
        icon: 'error',
        title: 'Error while saving percentage!',
        text: message,
      })
      dispatch(clearCreateTrustPercentage())
      onCloseDrawer(false)
    }
  }, [form, onCloseDrawer, error, message, success, dispatch])

  const submitTrustPercentage = (data) => {
    setValues({ type: 'loading', loading: true })
    if (editTrustPercentage) {
      const updateData = { ...data }
      updateData.id = trustPercentageSelected._id
      updateData.status = status
      dispatch(requestUpdateTrustPercentage(updateData))
    } else {
      dispatch(requestAssignTrustPercentage(data))
    }
  }

  const handleOnValuesChange = (changedFields, all) => {    
    let totalSum = 0
    const avoidProps = ['datalake_x', 'datalake_b', 'gs_threshold', 'truckTypeId']

    for (const property in all) {
      if (!avoidProps.includes(property)) totalSum += all[property]
    }
    if (totalSum === 100 && all.truckTypeId) {
      setValues({ type: 'active-submit', btnSubmit: false, percentageWarning: false, total: totalSum })
      return
    }
    setValues({ type: 'total-percentage', btnSubmit: true, percentageWarning: true, total: totalSum })
  }
  return (
    <Modal
      title={editTrustPercentage ? 'Edit Rate Source' : 'Create a rate source'}
      visible={visible}
      afterClose={() => form.setFieldsValue(defaultValues)}
      onCancel={() => {
        setValues({ type: 'updating', btnSubmit: false, total: 0, isUpdating: true })
        onCloseDrawer(false)
      }}
      width={480}
      height="590px"
      footer={
        <div style={{ textAlign: 'center', bottom: '5px' }}>
          <PrimarySubmit
            label={editTrustPercentage ? 'Edit Rate Source' : 'Create'}
            loading={loading}
            disable={btnSubmit}
            formName="trust-percentage-form"
          />
        </div>
      }
    >
      <StyledTabs defaultActiveKey="1" tabBarStyle={{ width: '100%', marginBottom: 10 }}>
        <StyledTabs.TabPane tab="Rate Source Info" key="1">
          <Row
            style={{
              margin: '10px 0px 12px 0px',
              padding: '0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{ color: '#545454', fontWeight: 400, fontSize: '13px' }}>
              Edit the information of this Rate Source
            </Text>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FiPauseCircle
                onClick={status !== EnumStatus.Paused && pauseStatus}
                style={
                  status !== EnumStatus.Paused
                    ? { fontSize: '19px', color: colors.dark_blue.default, cursor: 'pointer' }
                    : { fontSize: '19px', color: colors.light_grey.default, cursor: 'not-allowed' }
                }
              />
              <Text
                underline
                disabled={status === EnumStatus.Paused}
                style={
                  status !== EnumStatus.Paused
                    ? {
                        marginLeft: '7px',
                        color: '#002555',
                        fontWeight: 400,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }
                    : {
                        marginLeft: '7px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }
                }
                onClick={status !== EnumStatus.Paused && pauseStatus}
              >
                Pause Rule
              </Text>
            </Row>
          </Row>
          <Form
            layout="vertical"
            name="trust-percentage-form"
            form={form}
            onFinish={submitTrustPercentage}
            onValuesChange={handleOnValuesChange}
            style={{ paddingRight: '0px !important' }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <FloatLabel label="Select Equipment Type" show={truckTypes && truckTypes.length !== 0}>
                  <StyledItem name="truckTypeId">
                    <Select
                      placeholder="Select"
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={!editTrustPercentage}
                    >
                      {truckTypes.map((entry) => (
                        <Select.Option key={entry._id} value={entry._id}>
                          {entry.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </StyledItem>
                </FloatLabel>
              </Col>
            </Row>
            <TrustPercentageItem
              itemLabel="DAT 7 days spot AVG"
              itemName="datAvg"
              placeholder="DAT AVG"
              required
              message="Please enter the DAT AVG"
            />
            <TrustPercentageItem itemLabel="LOW" itemName="low" required={false} />
            <TrustPercentageItem itemLabel="HIGH" itemName="high" required={false} />
            <TrustPercentageItem itemLabel="Contract" itemName="contract" required={false} />
            <TrustPercentageItem itemLabel="GS Target Rate" itemName="gsAvg" />
            <Row>
              <Col span={12} style={{ marginRight: '6px' }}>
                <Text strong>Total: </Text>
              </Col>
              <Col span={10}>
                <Text strong>{total} %</Text>
              </Col>
            </Row>
            {percentageWarning && (
              <Row style={{ marginTop: '10px', fontFamily: 'Roboto', fontStyle: 'italic' }}>
                <Text strong disabled>
                  The total must be equal to 100%
                </Text>
              </Row>
            )}

            <Row style={{ margin: '10px 6px 10px 0px' }}>
              <Text strong>Adjustment</Text>
            </Row>

            <TrustPercentageItem
              itemLabel="GS CL Threshold"
              itemName="gs_threshold"
              icon={
                <Tooltip color="#3594FF" title="When the Confidence Level of the Greenscreens Network rate is above this threshold, DAT will not be called" placement="right">
                  <GoInfo style={{ marginLeft: '5px' }} />
                </Tooltip>
              }
            />
            <TrustPercentageItem itemLabel="Max Datalake Weight" itemName="datalake_x" />
            <TrustPercentageItem
              itemLabel="RMW"
              itemName="datalake_b"
              icon={
                <Tooltip color="#3594FF" title="Number of internal reports required to reach the max datalake weight" placement="right">
                  <GoInfo style={{ marginLeft: '5px' }} />
                </Tooltip>
              }
            />
          </Form>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="History" key="2">
          <HistoryItems data={getHistory} height="420px" />
        </StyledTabs.TabPane>
      </StyledTabs>
    </Modal>
  )
}

export default TrustPercentageModal
