import React from 'react'
import { Button } from 'antd'

export default function (dispatch) {
  return [
    {
      title: 'Id',
      dataIndex: '_id',
      key: 'id',
    },
    {
      title: 'Inbound',
      dataIndex: ['lane', 'origin'],
      key: 'origin',
      render: (origin) => (
        <p>
          {origin.zipCode}-{origin.city},{origin.state}
        </p>
      ),
    },
    {
      title: 'Outbound',
      dataIndex: ['lane', 'destination'],
      key: 'destination',
      render: (destination) => (
        <p>
          {destination.zipCode}-{destination.city},{destination.state}
        </p>
      ),
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Saved on',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'email'],
      key: 'customer',
    },
    {
      title: '',
      key: 'actions',
      render: (data) => <Button onClick={() => dispatch({ type: 'push', payload: data })}>Rate again</Button>,
    },
  ]
}
