import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Tabs from 'containers/settings/Tabs'

const Routes = () => {
  const { login } = useSelector((state) => ({ login: state.login.data.roleId.name }))

  return <>{login !== 'Basic' && <Route exact path="/general-settings" component={Tabs} />}</>
}

export default Routes
