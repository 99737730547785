import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Grid from 'antd/lib/grid'
import Spin from 'antd/lib/spin'
import RouteMap from 'components/RouteMap'
import EmptyMap from 'components/EmptyMap'
import EmptyMapLarge from 'components/EmptyMapLarge'
import RouteMiles from 'components/RouteMiles'
import { saveRouteMarkers } from 'store/duck/getZipHere.duck'
import colors from 'providers/theme/config/colorPallete'

const { useBreakpoint } = Grid

const RateInformation = () => {
  const dispatch = useDispatch()
  const screen = useBreakpoint()
  const { routeMarkers, stopMarkers, markers } = useSelector((state) => state.getZipHere)
  const { success, fetching } = useSelector((state) => state.getRate)
  const { editRate, carrierRate, datRate, gsDatalake } = useSelector((state) => ({
    editRate: state.createRate.editRate,
    carrierRate: state.getRate,
    datRate: state.getRate.rate?.base_rate?.datRatedatRate,
    gsRate: state.getRate.rate?.base_rate?.gsRate,
    gsDatalake: state.getRate.rate?.internal_adjustment,
  }))

  const validateCarrier = () => {
    if (carrierRate.success) {
      if (gsDatalake && gsDatalake.rate !== '$0.00') {
        return true
      }
      if (datRate && datRate.datLow.rate !== '$0.00') {
        return true
      }
    }
    return false
  }

  const [editEnter, setEditEnter] = useState(editRate)
  const [mapParams, setMapParams] = useState({})
  const [serviceComplete, setServiceComplete] = useState(false)

  useEffect(() => {
    if (!editEnter) return
    dispatch(saveRouteMarkers([...markers, ...stopMarkers]))
    setEditEnter(false)
  }, [editEnter, markers, stopMarkers, dispatch])

  useEffect(() => {
    if (success && !serviceComplete && markers.length === 2 && routeMarkers.length > 0) {
      const wayPoints = {}
      wayPoints.waypoint0 = `${markers[0].coordinate.lat},${markers[0].coordinate.lng}`
      stopMarkers.map((item, index) => {
        wayPoints[`waypoint${index + 1}`] = `${item.coordinate.lat},${item.coordinate.lng}`
        return wayPoints
      })
      wayPoints[`waypoint${routeMarkers.length - 1}`] = `${markers[1].coordinate.lat},${markers[1].coordinate.lng}`
      wayPoints.mode = 'fastest;truck;traffic:enabled'
      wayPoints.representation = 'display'
      wayPoints.routeattributes = 'sm'
      setMapParams(wayPoints)
      setServiceComplete(true)
    }
  }, [markers, routeMarkers, stopMarkers, serviceComplete, success])

  useEffect(() => {
    if (!success) {
      setServiceComplete(false)
      setMapParams({})
      setEditEnter(editRate)
    }
  }, [success, editRate])

  return (
    <Spin spinning={fetching}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: ' 216px',
            }}
          >
            <div style={{ margin: '5px', display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  color: colors.dark_blue.default,
                  fontSize: 20,
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                }}
              >
                Route Map
              </span>
              {success && validateCarrier() && (
                <div style={{ display: 'flex' }}>
                  <RouteMiles>
                    <span className="strong">{carrierRate.rate.mileage}</span> Miles
                  </RouteMiles>
                  <RouteMiles>
                    <span className="strong">{(carrierRate.rate.mileage / 50 + 2).toFixed(2)} Hours</span> (
                    {((carrierRate.rate.mileage / 50 + 2) / 14).toFixed(1)} Transit Days)
                  </RouteMiles>
                </div>
              )}
            </div>
            {!serviceComplete ? (
              <>{screen.xxl ? <EmptyMapLarge /> : <EmptyMap />}</>
            ) : (
              <RouteMap
                center={{
                  lat: 39.0,
                  lng: -97.0,
                }}
                zoom={4.9}
                mapParams={mapParams}
                withBubble
                markers={routeMarkers}
                success={success}
              />
            )}
          </div>
        </Col>
      </Row>
    </Spin>
  )
}

export default RateInformation
