import React, { useCallback, useEffect, useMemo, useState, useLayoutEffect } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import { useDispatch, useSelector } from 'react-redux'
import { BsPlusSquareFill } from 'react-icons/bs'
import { MdDateRange } from 'react-icons/md'
import { first } from 'lodash/array'
import { identity } from 'lodash/util'
import { keyBy } from 'lodash/collection'
import { mapValues, pick } from 'lodash/object'
import { clearDeleteRule } from 'store/duck/deleteRule.duck'
import { requestGetRules } from 'store/duck/getRules.duck'
import { requestGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import { clearUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { clearUpdateRuleset } from 'store/duck/updateRuleset.duck'
import notify from 'utils/notify'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import StyledSearch from 'components/Input/Search'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import FloatLabel from 'components/Label/FloatLabel'
import colors from 'providers/theme/config/colorPallete'
import { ArrowDownIcon } from 'assets/svg'
import { clearUpdateMultiStep } from 'store/duck/updateMultiStep.duck'
import { clearCreateMultiStep } from 'store/duck/createMultiStep.duck'
import { StyledItem, StyledOptions } from './styles'

const FilterRules = ({ queryParams, handleColumnsParams, onClickNewRule, changeStatus, page, limit }) => {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(false)
  const [params, setParams] = useState({})
  const [ruleType, setRuleType] = useState('geographicRule')
  const { successDelete } = useSelector((state) => state.deleteRule)
  const { success: successCreate } = useSelector((state) => state.createRule)
  const { success: successUpdate } = useSelector((state) => state.updateRule)
  const { success: successUpdateStatusRule, error, message } = useSelector((state) => state.updateStatusRule)
  const { success: returnSuccess } = useSelector((state) => state.returnVersionRule)
  const { success: successUpdateRuleset } = useSelector((state) => state.updateRuleset)
  const { successUpdate: successUpdateMultiStep } = useSelector((state) => state.updateMultiStep)
  const { success: successCreateMultiStep } = useSelector((state) => state.createMultiStep)

  const ruleShippingDropdown = useCallback(
    () => (
      <Col flex={3}>
        <FloatLabel label="Type" show>
          <StyledItem name="ruleShipping">
            <Select placeholder="Select" size="large">
              <StyledOptions key="outbound" value="outbound">
                Pick up
              </StyledOptions>
              <StyledOptions key="inbound" value="inbound">
                Delivery
              </StyledOptions>
            </Select>
          </StyledItem>
        </FloatLabel>
      </Col>
    ),
    []
  )

  const ruleTypes = useMemo(
    () => ({
      geographicRule: {
        defaultRuleShipping: 'outState',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outState: {
              queryParams: queryParams.geographic.outState,
              columnParams: { type: 'geographicRule', shipping: 'outState', reference: 'state' },
            },
            outZip: {
              queryParams: queryParams.geographic.outZip,
              columnParams: { type: 'geographicRule', shipping: 'outZip', reference: 'zip' },
            },
            inState: {
              queryParams: queryParams.geographic.inState,
              columnParams: { type: 'geographicRule', shipping: 'inState', reference: 'state' },
            },
            inZip: {
              queryParams: queryParams.geographic.inZip,
              columnParams: { type: 'geographicRule', shipping: 'inZip', reference: 'zip' },
            },
          }[ruleShipping]),
        render: () => (
          <Col flex={4}>
            <FloatLabel label="Type" show>
              <StyledItem name="ruleShipping">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="outState" value="outState">
                    Pick up State
                  </StyledOptions>
                  <StyledOptions key="outZip" value="outZip">
                    Pick up Zip
                  </StyledOptions>
                  <StyledOptions key="inState" value="inState">
                    Delivery State
                  </StyledOptions>
                  <StyledOptions key="inZip" value="inZip">
                    Delivery Zip
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
        ),
      },
      lengthRule: {
        getQueryAndColumnParams: () => ({
          queryParams: queryParams.length,
          columnParams: {
            type: 'lengthRule',
            shipping: '',
            reference: '',
          },
        }),
        render: () => null,
      },
      simpleRule: {
        defaultRuleShipping: 'outbound',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outbound: {
              queryParams: queryParams.simple.outbound,
              columnParams: { type: 'simpleRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.simple.inbound,
              columnParams: { type: 'simpleRule', shipping: 'inbound', reference: '' },
            },
          }[ruleShipping]),
        render: ruleShippingDropdown,
      },
      dateRangeRule: {
        defaultRuleShipping: 'outbound',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outbound: {
              queryParams: queryParams.dateRange.outbound,
              columnParams: { type: 'dateRangeRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.dateRange.inbound,
              columnParams: { type: 'dateRangeRule', shipping: 'inbound', reference: '' },
            },
          }[ruleShipping]),
        formItems: ['dateRange'],
        render: () => (
          <>
            {ruleShippingDropdown()}
            <Col flex={3}>
              <FloatLabel label="Date Range" show>
                <StyledItem name="dateRange">
                  <DatePicker.RangePicker
                    format="MM-DD-YYYY"
                    size="large"
                    suffixIcon={<MdDateRange size="20px" color={colors.dark_blue.default} />}
                  />
                </StyledItem>
              </FloatLabel>
            </Col>
          </>
        ),
      },
      truckType: {
        getQueryAndColumnParams: (ruleShipping) => {
          const equipmentsToFind = queryParams.equipment.map((equipment) => ({
            id: equipment.typeReferenceEquipmentId,
            queryParams: pick(equipment, ['typeRuleId', 'typeReferenceEquipmentId']),
            columnParams: {
              type: 'truckType',
            },
          }))

          const matchedEquipment = equipmentsToFind.find((equipment) => equipment.id === ruleShipping)
          if (matchedEquipment) {
            return matchedEquipment
          }

          form.setFieldsValue({
            ruleShipping: first(equipmentsToFind).id,
          })
          return first(equipmentsToFind)
        },
        render: () => (
          <Col flex={4}>
            <FloatLabel label="Equipment Type" show>
              <StyledItem name="ruleShipping">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  {queryParams.equipment.map((equipment) => (
                    <StyledOptions
                      key={equipment.typeReferenceEquipmentId}
                      value={equipment.typeReferenceEquipmentId}
                    >
                      {equipment.name}
                    </StyledOptions>
                  ))}
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
        ),
      },
      marketRule: {
        defaultRuleShipping: 'outbound',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outbound: {
              queryParams: queryParams.market.outbound,
              columnParams: { type: 'marketRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.market.inbound,
              columnParams: { type: 'marketRule', shipping: 'inbound', reference: '' },
            },
          }[ruleShipping]),
        render: ruleShippingDropdown,
      },
    }),
    [
      form,
      ruleShippingDropdown,
      queryParams.geographic.outState,
      queryParams.geographic.outZip,
      queryParams.geographic.inState,
      queryParams.geographic.inZip,
      queryParams.length,
      queryParams.simple.outbound,
      queryParams.simple.inbound,
      queryParams.dateRange.outbound,
      queryParams.dateRange.inbound,
      queryParams.equipment,
      queryParams.market.outbound,
      queryParams.market.inbound,
    ]
  )

  useEffect(() => {
    changeStatus(params.status)
  }, [changeStatus, params])

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page,
      limit,
    }))
  }, [page, limit])

  useEffect(() => {
    if (params.typeRuleId) {
      dispatch(requestGetRules(params))
    }
  }, [dispatch, params])

  const getAfterReturnVersion = useCallback(() => {
    dispatch(requestGetRules(params))
  }, [dispatch, params])

  useEffect(() => {
    if (
      successCreate ||
      successUpdate ||
      successUpdateStatusRule ||
      successCreateMultiStep ||
      successUpdateMultiStep ||
      successUpdateRuleset
    ) {
      dispatch(requestGetRules(params))
      dispatch(clearCreateMultiStep())
      dispatch(clearUpdateMultiStep())
      dispatch(clearUpdateRuleset())
    }
    if (successDelete) {
      notify('success', 'Rule has been deleted')
      dispatch(requestGetRules(params))
      dispatch(requestGetMultiSteps())
      dispatch(requestGetRuleSets())
    }
    if (successDelete) {
      dispatch(clearDeleteRule())
    }
    if (successUpdateStatusRule) {
      dispatch(clearUpdateStatusRule())
      if (message !== '') notify('error', message)
    }
    if (error) notify('error', message)
  }, [
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatusRule,
    dispatch,
    form,
    params,
    error,
    message,
    successUpdateRuleset,
    successUpdateMultiStep,
    successCreateMultiStep,
  ])

  useLayoutEffect(() => {
    if (returnSuccess) {
      getAfterReturnVersion()
    }
  }, [getAfterReturnVersion, returnSuccess])

  const handleValuesChange = (values) => {
    const formRuleType = form.getFieldValue('ruleType')
    const { queryParams: rawQueryParams, columnParams } = ruleTypes[formRuleType].getQueryAndColumnParams(
      values.ruleType ? ruleTypes[formRuleType].defaultRuleShipping : form.getFieldValue('ruleShipping')
    )
    setParams({
      ...rawQueryParams,
      text: formRuleType !== 'simpleRule' ? form.getFieldValue('text') : undefined,
      textAndWeekday: formRuleType === 'simpleRule' ? form.getFieldValue('text') : undefined,
      status: form.getFieldValue('status') !== 'All' ? form.getFieldValue('status') : undefined,
      ...mapValues(keyBy(ruleTypes[formRuleType].formItems, identity), (formItem) => form.getFieldValue(formItem)),
      page,
      limit,
    })
    handleColumnsParams(columnParams)

    if (values.ruleType && values.ruleType !== 'truckType') {
      form.setFieldsValue({
        ruleShipping: ruleTypes[formRuleType].defaultRuleShipping,
      })
    }

    setRuleType(formRuleType)
    setFilter(true)
  }

  useEffect(() => {
    if (filter) {
      return
    }

    const formRuleType = form.getFieldValue('ruleType')
    const { queryParams: rawQueryParams } = ruleTypes[formRuleType].getQueryAndColumnParams(
      form.getFieldValue('ruleShipping')
    )

    setParams({
      ...rawQueryParams,
      status: form.getFieldValue('status'),
      page,
      limit,
    })
  }, [filter, form, limit, page, ruleTypes])

  return (
    <Form
      layout="vertical"
      name="filter-rules-form"
      style={{ width: '100%', margin: '0px', padding: '0px' }}
      form={form}
      initialValues={{
        ruleType: 'geographicRule',
        ruleShipping: 'outState',
        status: 'Activated',
      }}
      onValuesChange={handleValuesChange}
      hideRequiredMark
    >
      <Row gutter={[24, 16]} justify="end">
        <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
          <StyledItem name="text">
            <StyledSearch />
          </StyledItem>
        </Col>
        <Col flex={3}>
          <FloatLabel label="Category" show>
            <StyledItem name="ruleType">
              <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                <StyledOptions key="dateRangeRule" value="dateRangeRule">
                  Date Range
                </StyledOptions>
                <StyledOptions key="truckType" value="truckType">
                  Equipment
                </StyledOptions>
                <StyledOptions key="geographicRule" value="geographicRule">
                  Geographic
                </StyledOptions>
                <StyledOptions key="lengthRule" value="lengthRule">
                  Length of haul
                </StyledOptions>
                <StyledOptions key="marketRule" value="marketRule">
                  Market
                </StyledOptions>
                <StyledOptions key="simpleRule" value="simpleRule">
                  Simple
                </StyledOptions>
              </Select>
            </StyledItem>
          </FloatLabel>
        </Col>
        {ruleTypes[ruleType].render()}
        <Col flex="auto">
          <FloatLabel label="Status" show>
            <StyledItem name="status">
              <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                <StyledOptions key="All" value="All">
                  All
                </StyledOptions>
                <StyledOptions key="Activated" value="Activated">
                  Activated
                </StyledOptions>
                <StyledOptions key="Deactivated" value="Deactivated">
                  Blocked
                </StyledOptions>
                <StyledOptions key="Paused" value="Paused">
                  Paused
                </StyledOptions>
              </Select>
            </StyledItem>
          </FloatLabel>
        </Col>
        <Col flex="none">
          <Form.Item>
            <ButtonPrimary icon={<BsPlusSquareFill size="16px" />} height="40px" onClick={onClickNewRule}>
              New Rule
            </ButtonPrimary>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default FilterRules
