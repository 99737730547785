import React from 'react'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Spin from 'antd/lib/spin'
import { StyleModal, SpinAnimated } from '../styles'

export default function ModalErrorRate({ visible = false, CustomBody, onCancel, messageBody = '' }) {
  const { Text } = Typography
  
  return (
    <StyleModal
      transitionName=""
      footer={null}
      visible={visible}
      height={messageBody.length > 50 ? '220px' : '160px'}
      width={350}
      onCancel={onCancel}
      centered
    >
      {CustomBody ? (
        <CustomBody messageBody={messageBody} />
      ) : (
        <Row gutter={[16, 14]} style={{ padding: '37px 20px 20px 20px' }}>
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: '#3594FF',
                borderRadius: '8px',
              }}
            >
              <Spin indicator={<SpinAnimated />} />
            </div>
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#545454',
              fontWeight: '400',
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            <Text>Please wait while the new rate is applied!</Text>
          </Col>
        </Row>
      )}
    </StyleModal>
  )
}
