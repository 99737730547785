import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  params: {},
}

export const {
  requestGetAutocompleteHere,
  successGetAutocompleteHere,
  failureGetAutocompleteHere,
} = createActions({
  REQUEST_GET_AUTOCOMPLETE_HERE: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_AUTOCOMPLETE_HERE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_AUTOCOMPLETE_HERE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getAutocompleteHere = handleActions(
  {
    [requestGetAutocompleteHere]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetAutocompleteHere]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      const temporal = []
      data.data.items.map((item) => {
        if (item.localityType === 'city')
          temporal.push({
            key: item.id,
            value: item.title.replace(', United States', ''),
            searchparameter: item.title,
          })
        return null
      })
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = temporal
    }),
    [failureGetAutocompleteHere]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default getAutocompleteHere
