import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import ModalRates from 'components/ModalRates/ModalRates'
import { useDispatch, useSelector } from 'react-redux'
import { requestUserList } from 'store/duck/getUsers.duck'
import { clearGetRate } from 'store/duck/getRate.duck'
import { requestGetAllRates } from 'store/duck/getAllRates.duck'
import { clearDownloadRates } from 'store/duck/getDownloadMultipleRates.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { clearDeleteRate, requestDeleteRate } from 'store/duck/deleteRate.duck'
import Table from 'components/Table'
import TitleList from 'components/TitleList'
import DateInput from 'components/Form/DateInput'
import StyledSearch from 'components/Input/Search'
import DropdownApiInput from 'components/Form/DropdownApiInput'
import RulesContainer from 'containers/rules/styles'
import { MdFileDownload } from 'react-icons/md'
import notify from 'utils/notify'
import ModalQuote from 'components/ModalQuote'
import { unloadModalRate } from 'store/duck/getModalRate.duck'

import columns from './utils/TableColumns'
import downloadRates from './utils/downloadRates'

const HistoryLanes = () => {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const dispatch = useDispatch()
  const { rates, success, error, totalRecords } = useSelector((state) => state.getAllRates)
  const download = useSelector((state) => state.getDownloadMultipleRates)
  const { message, error: errorDelete, successDelete } = useSelector((state) => state.deleteRate)
  const {
    data: { data: users = [] },
    fetching: fetchingUsers,
  } = useSelector((state) => state.getUsers)

  const isBulkDeleteRef = useRef(null)
  const [totalRates, setTotalRates] = useState(rates)
  const [params, setParams] = useState({ page: 1 })

  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const { error: errorRate, message: messageErroRate, fetching: fetchingRate } = useSelector(
    (state) => state.getRate
  )
  const [visible, setvisible] = useState(false)

  const { isModalRate, modalRate } = useSelector((state) => state.createModalRate)
  const [displayModalRate, setDisplayModalRate] = useState(false)

  const onCloseModalRate = useCallback(() => {
    dispatch(unloadModalRate())
    setDisplayModalRate(false)
  }, [dispatch])

  useEffect(() => {
    if (isModalRate) {
      if (modalRate.details && modalRate.details.base_rate) {
        setDisplayModalRate(true)
      } else notify('error', 'No information available')
      dispatch(unloadModalRate())
    }
  }, [isModalRate, modalRate, dispatch])

  useEffect(() => {
    if (fetchingRate) {
      setvisible(true)
    }
    if (errorRate) {
      setvisible(false)
      notify('error', messageErroRate)
      dispatch(clearGetRate())
    }
  }, [fetchingRate, messageErroRate, errorRate, dispatch])

  const handleValuesChange = (_, allValues) => {
    setParams({
      ...params,
      ...allValues,
    })
  }

  useEffect(() => {
    dispatch(requestUserList())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestGetAllRates({
        ...params,
        createdAt: params.createdAt ? moment(params.createdAt).format('YYYY-MM-DD') : undefined,
        pickupDate: params.pickupDate ? moment(params.pickupDate).format('YYYY-MM-DD') : undefined,
      })
    )
  }, [dispatch, params])

  useEffect(() => {
    if (successDelete) {
      dispatch(requestGetAllRates(params))
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
    }
    if (errorDelete) {
      notify('error', message)
    }
    dispatch(clearDeleteRate())
  }, [dispatch, params, successDelete, msgSnack, message, errorDelete])

  useEffect(() => {
    setTotalRates(rates)
  }, [rates])

  useEffect(() => {
    const date = new Date()
    if (!download.success) return
    const url = window.URL.createObjectURL(new Blob([download.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `MultipleRates_${date}.csv`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    dispatch(clearDownloadRates())
  }, [download, dispatch])

  useEffect(() => {
    if (error) {
      notify('error', 'Error getting lane history!')
    }
  }, [error])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const [expandedRowKey, setExpandedKey] = useState('')

  const onExpand = (key) => {
    setExpandedKey((prev) => {
      const newKey = key._id
      return prev !== newKey ? newKey : ''
    })
  }

  useEffect(() => {
    if (successDelete) {
      if (isBulkDeleteRef) {
        isBulkDeleteRef.current = false
      } else {
        notify('success', 'Rate has been deleted')
      }

      dispatch(clearDeleteRate())
      dispatch(requestGetAllRates(params))
    }
  }, [dispatch, params, successDelete])

  return (
    <RulesContainer>
      <ModalQuote visible={displayModalRate} onCancel={onCloseModalRate} data={modalRate} />
      <ModalRates visible={visible} messageBody="Please wait while the new rate is applied on the Dashboard!" />
      <div style={{ marginBottom: 15, marginTop: 20 }}>
        <TitleList title="Lane History" />
      </div>
      <Form
        layout="vertical"
        name="filter-history-lanes"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex={xl ? '175px' : undefined} xs={!xl ? 8 : undefined}>
            <DateInput label="Creation Date" name="createdAt" />
          </Col>
          <Col flex={xl ? '175px' : undefined} xs={!xl ? 8 : undefined}>
            <DateInput label="Pickup Date" name="pickupDate" />
          </Col>
          <Col flex={xl ? '175px' : undefined} xs={!xl ? 8 : undefined}>
            <DropdownApiInput
              label="Created By"
              formProps={{
                name: 'createdBy',
              }}
              inputProps={{
                options: users.map((user) => ({ value: user._id, label: user.displayName })),
                keySort: 'label',
              }}
              loading={fetchingUsers}
              onChange={(option) => setParams((prev) => ({ ...prev, createdBy: option?.value }))}
              onSearch={(text) => dispatch(requestUserList({ text }))}
            />
          </Col>
        </Row>
      </Form>

      <Table
        dispatch={dispatch}
        columns={columns(onExpand, expandedRowKey)}
        rowSelection
        data={totalRates}
        loading={!success}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        updatedPagination
        pagination={{ total: totalRecords }}
        limitPerPage={100}
        paginationOptions={['100', '250', '500', '1000']}
        entityNameForBulk="Rate"
        entityNameForTextOnTopOfTableForBulk="lane history"
        onChange={handleTableChange}
        onExpand={onExpand}
        alignRowTable="50px"
        expandedRowKeys={expandedRowKey}
        onRow={(_) => {
          return [expandedRowKey].includes(_._id) && { className: 'expand-parent' }
        }}
        expandedRowRender={(record) => {
          return (
            <Row>
              <Col span={5} style={{ marginLeft: '50.5%' }}>
                {record.stops.map((stop, index) => (
                  <div key={index}>{stop.origin.city}</div>
                ))}
                <div>{record.stops[record.stops.length - 1].destination.city}</div>
              </Col>
            </Row>
          )
        }}
        pauseProps={{
          label: 'Download',
          labelSuccess: 'downloaded',
          // eslint-disable-next-line react/jsx-props-no-spreading
          icon: (props) => <MdFileDownload {...props} />,
          newState: {},
        }}
        openPauseModal={(ratesIds) => {
          isBulkDeleteRef.current = true

          downloadRates({
            title: 'Multiple Rates',
            fileName: `MultipleRates_${moment().format('LL HH:mm:ss').replace(/,/, '')}`,
            rates: totalRates.filter((rate) => ratesIds.includes(rate._id)),
          })
        }}
        deleteRequest={requestDeleteRate}
      />
    </RulesContainer>
  )
}

export default HistoryLanes
