import axios from 'axios'
import { auth } from 'services'

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_URL
const token = window.localStorage.getItem('altptk')
const CONTENT_TYPE = 'application/x-www-form-urlencoded'

class AxiosManager {
  constructor() {
    this.axiosDefault = axios.create()
    this.init()
  }

  init() {
    this.setHeader()
    this.setAuthorization()
    this.handleError()
  }

  api(url = BASE_URL) {
    this.axiosDefault.defaults.baseURL = url
    return this.axiosDefault
  }

  setHeader(contentType = CONTENT_TYPE) {
    this.axiosDefault.defaults.headers.post['Content-Type'] = contentType
  }

  setAuthorization(incomingToken = token) {
    if (incomingToken !== '') {
      this.axiosDefault.defaults.headers.Authorization = `Bearer ${incomingToken}`
    }
  }

  handleError() {
    this.axiosDefault.interceptors.response.use(
      (response) => response.data,
      (error) => {
        const originalRequest = error.config
        if (error.response === undefined || error.messagge === 'Network Error') {
          window.location.href = '/error'
          return Promise.reject(error)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          auth.refreshToken()
          const incomingToken = window.localStorage.getItem('altpntk')
          originalRequest._retry = true
          this.axiosDefault.defaults.headers.Authorization = `Bearer ${incomingToken}`
          originalRequest.headers.Authorization = `Bearer ${incomingToken}`
          return this.axiosDefault(originalRequest)
        }
        return Promise.reject(error)
      }
    )
  }

  deleteInterceptor(interceptor) {
    this.axiosDefault.interceptors.request.eject(interceptor)
  }
}

export default new AxiosManager()
