import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Typography from 'antd/lib/typography'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { requestGetRules } from 'store/duck/getRules.duck'
import { resetEditCustomer } from 'store/duck/editCustomer.duck'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import { resetDeactivateCustomer } from 'store/duck/deleteCustomer.duck'
import { clearDeleteBulkCustomer, requestDeleteBulkCustomer } from 'store/duck/deleteBulkCustomer.duck'
import { clearActivateCustomer, requestActivateCustomer } from 'store/duck/activateCustomer.duck'
import { resetCreateCustomer, unloadCustomer } from 'store/duck/createCustomer.duck'
import { ArrowDownIcon } from 'assets/svg'
import Table from 'components/Table'
import TitleList from 'components/TitleList'
import StyledSearch from 'components/Input/Search'
import FloatLabel from 'components/Label/FloatLabel'
import StyledRow from 'components/BulkActions/styles'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { StyledItem, StyledOptions } from 'components/Form/FilterRules/styles'
import { MdBlock, MdLocalGasStation } from 'react-icons/md'
import { BsPlusSquareFill } from 'react-icons/bs'
import notify from 'utils/notify'
import RulesContainer from 'containers/rules/styles'
import { resetGetCustomer } from 'store/duck/getCustomer.duck'
import Checkbox from 'antd/lib/checkbox'
import CustomerColumns from './utils/CustomerColumns'
import CreateCustomerModal from '../CreateCustomer/CreateCustomerModal'
import BulkFuelPricesModal from '../BulkFuelPrices/BulkFuelPricesModal'

const { Text } = Typography

const ListCustomers = () => {
  const { xl } = useBreakpoint()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [params, setParams] = useState({ text: '', page: 1, active: '' })
  const { success: successUpdateBulkFuelPrice } = useSelector((state) => state.updateBulkFuelPrice)
  const { customers, error: errorGetCustomers, totalRecords, fetching } = useSelector(
    (state) => state.getCustomers
  )
  const deleteCustomer = useSelector((state) => state.deleteCustomer)
  const activateCustomer = useSelector((state) => state.activateCustomer)
  const { success: successCreate } = useSelector((state) => state.createCustomer)
  const { message, success: successUpdate, error: errorUpdateStatusCustomer } = useSelector(
    (state) => state.editCustomer
  )
  const deleteBulk = useSelector((state) => state.deleteBulkCustomer)

  const [openCreateCustomer, setIsOpenCreateModal] = useState(false)
  const [openBulkFuelPrices, setIsOpenBulkFuelPrices] = useState(false)
  const [isDeactivatingBulk, setIsDeactivatingBulk] = useState(false)

  const [customersToUpdate, setCustomersToUpdate] = useState([])

  const handleData = useCallback((rows) => {
    setCustomersToUpdate(rows)
  }, [])

  const handleValuesChange = (values) => {
    const active = values.active ?? params.active
    setParams({ ...params, text: values.text ?? params.text, active: active !== 'All' ? active : undefined })
  }

  const [checkFuel, setcheckFuel] = useState({
    Default: true,
    Updated: false,
    Outdated: false,
  })
  const [doRequest, setDoRequest] = useState(false)
  const onChange = (name) => {
    const newChecks = { ...checkFuel }
    if (name === 'Default') {
      for (const key in newChecks) {
        if (key !== 'Default') {
          newChecks[key] = false
        }
      }
    }
    if (name !== 'Default') {
      newChecks.Default = false
    }
    const value = checkFuel[name]
    newChecks[name] = !value
    setcheckFuel(newChecks)
    setDoRequest(true)
  }
  const updateBulk = () => {
    setIsOpenBulkFuelPrices(true)
  }

  useEffect(() => {
    if (!openCreateCustomer) {
      dispatch(unloadCustomer())
      dispatch(resetEditCustomer())
      dispatch(resetGetCustomer())
    }
  }, [dispatch, openCreateCustomer])

  useEffect(() => {
    const copyParams = { ...params }
    const { text, page, active } = copyParams
    const newParams = { text, page, active }
    const filterPrice = []
    for (const key in checkFuel) {
      if (key !== 'Default' && checkFuel[key] === true) {
        filterPrice.push(key.toLowerCase())
      }
    }
    filterPrice.forEach((element, index) => {
      newParams[`customFuelPriceFilter[${index}]`] = element
    })
    if (doRequest) {
      setParams(newParams)
      setDoRequest(false)
    }

    dispatch(requestGetCustomers(params))
  }, [params, dispatch, doRequest, checkFuel])

  useEffect(() => {
    if (successCreate || successUpdate || successUpdateBulkFuelPrice) {
      dispatch(resetCreateCustomer())
      dispatch(requestGetCustomers(params))
    }
    if (successUpdate) {
      dispatch(resetEditCustomer())
    }
    if (errorUpdateStatusCustomer) {
      notify('error', message)
    }
  }, [
    params,
    dispatch,
    successCreate,
    successUpdate,
    errorUpdateStatusCustomer,
    message,
    successUpdateBulkFuelPrice,
  ])

  useEffect(() => {
    if (errorGetCustomers) {
      notify('error', 'Error getting customers')
    }
  }, [errorGetCustomers])

  useEffect(() => {
    dispatch(requestGetRules())
  }, [dispatch])

  useEffect(() => {
    if (deleteCustomer.success) {
      notify('success', 'Customer has been deleted')

      dispatch(resetDeactivateCustomer())
      dispatch(requestGetCustomers(params))
    }
    if (deleteCustomer.error) {
      notify('error', `${deleteCustomer.error}`)

      dispatch(resetDeactivateCustomer())
      dispatch(requestGetCustomers(params))
    }
  }, [dispatch, deleteCustomer, params])

  useEffect(() => {
    if (activateCustomer.success) {
      if (!isDeactivatingBulk) {
        notify('success', `Customer has been ${activateCustomer.data.active ? 'enabled' : 'disabled'} `)
      } else {
        setIsDeactivatingBulk(false)
      }

      dispatch(clearActivateCustomer())
      dispatch(requestGetCustomers(params))
    }
    if (activateCustomer.error) {
      notify('error', `${activateCustomer.error}`)
      dispatch(clearActivateCustomer())
    }
  }, [dispatch, activateCustomer, params, isDeactivatingBulk])

  useEffect(() => {
    if (deleteBulk.successDeleteBulk) {
      dispatch(clearDeleteBulkCustomer())
      dispatch(requestGetCustomers(params))
    }
    if (deleteBulk.error) {
      notify('error', ` 'Error deleting the ruleset. ${deleteBulk.message}`)
      dispatch(clearDeleteBulkCustomer())
    }
  }, [dispatch, deleteBulk, params])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <RulesContainer>
      <div style={{ marginBottom: 15, marginTop: 20 }}>
        <TitleList title="Customers" />
      </div>

      <Form
        layout="vertical"
        name="filter-customer-list"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          active: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Fuel Price" show>
              <StyledItem name="FuelP">
                <Select
                  defaultValue="Default"
                  suffixIcon={<ArrowDownIcon />}
                  placeholder="Select"
                  size="large"
                  onChange={onChange}
                >
                  <StyledOptions key="Default" value="Default">
                    <Checkbox checked={checkFuel.Default}>Default</Checkbox>
                  </StyledOptions>
                  <StyledOptions key="Updated" value="Updated">
                    <Checkbox checked={checkFuel.Updated}>Updated</Checkbox>
                  </StyledOptions>
                  <StyledOptions key="Outdated" value="Outdated">
                    <Checkbox checked={checkFuel.Outdated}>Outdated</Checkbox>
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="active">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Active" value="true">
                    ON
                  </StyledOptions>
                  <StyledOptions key="Blocked" value="false">
                    OFF
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="none">
            <Form.Item>
              <ButtonPrimary
                icon={<BsPlusSquareFill size="16px" />}
                height="40px"
                onClick={() => setIsOpenCreateModal(true)}
              >
                New Customer
              </ButtonPrimary>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={CustomerColumns(dispatch, setIsOpenCreateModal)}
        data={customers}
        loading={fetching}
        rowKey={(customer) => customer.customer._id}
        width="100%"
        padding="4px 0px"
        margin="0px"
        entityNameForBulk="customer"
        pagination={{ total: totalRecords }}
        updatedPagination
        limitPerPage={50}
        onChange={handleTableChange}
        rowSelection
        dispatch={dispatch}
        deleteRequest={requestDeleteBulkCustomer}
        handleData={handleData}
        pauseProps={{
          label: 'Disable',
          labelSuccess: 'disabled',
          // eslint-disable-next-line react/jsx-props-no-spreading
          icon: (props) => <MdBlock {...props} />,
          newState: {},
        }}
        pauseRequest={(obj) => {
          setIsDeactivatingBulk(true)
          return requestActivateCustomer({ active: false }, obj.id)
        }}
        customBulkActions={(bulkActions, cleanData) => (
          <>
            {bulkActions}
            <Col>
              <StyledRow onClick={updateBulk}>
                <MdLocalGasStation style={{ fontSize: '24px' }} />
                <Text underline style={{ marginLeft: '7px', color: 'inherit', fontWeight: 500, fontSize: '16px' }}>
                  Update fuel prices
                </Text>
              </StyledRow>
            </Col>
            <BulkFuelPricesModal
              cleanData={cleanData}
              customersToUpdate={customersToUpdate}
              visible={openBulkFuelPrices}
              handleOnClose={() => setIsOpenBulkFuelPrices(false)}
            />
          </>
        )}
      />
      <CreateCustomerModal visible={openCreateCustomer} handleOnClose={() => setIsOpenCreateModal(false)} />
    </RulesContainer>
  )
}

export default ListCustomers
