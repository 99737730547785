import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { loadTrustPercentage } from 'store/duck/assignTrustPercentage.duck'
import { requestDeleteRule } from 'store/duck/deleteRule.duck'
import { MdBlock, MdDelete, MdModeEdit, MdPauseCircleFilled } from 'react-icons/md'

import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer }) => {
  const dispatch = useDispatch()

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Rule?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Rule has been deleted',
    actionConfirm: (some) => some,
  })

  const loadRuleSelected = (data) => {
    dispatch(loadTrustPercentage(data))
    openDrawer(true)
  }
  const handleDeleteRule = async (recordId) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this Rule?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'Rule has been deleted',
      actionConfirm: () => {
        dispatch(requestDeleteRule(recordId))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdPauseCircleFilled size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Pause Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdBlock size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Block Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => handleDeleteRule(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{fontSize: '16px'}}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => <ActionsDropdown record={record} openDrawer={openDrawer} />,
  width: '11.1%',
  align: 'center',
})

export default actionsDropdown
