import styled from 'styled-components'
import { Button } from 'antd'


const AddCityButton = styled(Button)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: end;
  
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) =>
    props.secondary === 'true' ? props.theme.colors.dark_grey.default : props.theme.colors.dark_blue.default};
  &:hover {
    background: transparent;
    color: ${(props) =>
      props.secondary === 'true' ? props.theme.colors.dark_grey.default : props.theme.colors.dark_blue.default};
    font-weight: 600 !important;
  }
  &::after {
    all: unset;
  }
`

export default AddCityButton