import React from 'react'
import Col from 'antd/lib/col'
import BoxContainer from './styles'

const Box = ({ children, width, height, title, Header, secondary, borderless, backgroundColor, bordertop }) => {
  return (
    <BoxContainer
      width={width}
      height={height}
      borderless={borderless}
      backgroundColor={backgroundColor}
      bordertop={bordertop}
    >
      <div id="header">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <span className={secondary ? 'titleSecondary' : 'title'}>{title}</span>
        </Col>
        {Header}
      </div>
      <div id="content">{children}</div>
    </BoxContainer>
  )
}

export default Box
