const moment = require('moment')

const handleDate = (date = '') => {
  if (date !== '') {
    if (date) {
      return moment(date).format('MM-DD')
    }
  }

  return ''
}

export const dataGraph = (arr = []) => {
  const newArr = []
  const ids = ['predictedIntervalUpperBound', 'mciScore', 'predictedIntervalLowerBound']
  // create an object for each element of 'ids' with the data needed to render a graph
  if (arr.length > 0)
    ids.forEach((id) => {
      const obj = {}
      obj.id = id
      obj.data = []
      arr.forEach((element) => {
        obj.data.push({
          x: handleDate(element?.date),
          y: element[id] ?? null,
        })
      })
      newArr.push(obj)
    })
  return newArr
}

export const formatLineGraph = (objectMarket = {}) => {
  const graphData = {}
  for (const key in objectMarket) {
    if (Object.hasOwnProperty.call(objectMarket, key)) {
      const element = objectMarket[key]
      graphData[key] = { data: [] }
      if (element.graph) {
        graphData[key].data = dataGraph(element.graph)
      }
    }
  }
  return graphData
}
