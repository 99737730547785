import React from 'react'
import Typography from 'antd/lib/typography'
import { getStatus } from 'utils/getStatus'
import { StyledBadge } from '../styles'
import actionsDropdown from './actions'

const { Text } = Typography

const columns = (dispatch, openDrawer) => {
  return [
    {
      title: <div style={{ fontSize: '16px' }}>Equipment Type</div>,
      dataIndex: ['truckTypeId', 'name'],
      key: 'truckType',
      render: (name) => {
        return <Text style={{ fontSize: '16px' }}>{name}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}> DAT AVG</div>,
      dataIndex: 'datAvg',
      key: 'datAvg',
      render: (datAvg) => {
        return <Text style={{ fontSize: '16px' }}>{datAvg}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Low</div>,
      dataIndex: 'low',
      key: 'low',
      render: (low) => {
        return <Text style={{ fontSize: '16px' }}>{low}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}> High</div>,
      dataIndex: 'high',
      key: 'high',
      render: (high) => {
        return <Text style={{ fontSize: '16px' }}>{high}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>GS Target Rate</div>,
      dataIndex: 'gsAvg',
      key: 'gsAvg',
      render: (gsAvg) => {
        return <Text style={{ fontSize: '16px' }}>{gsAvg}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>CL Threshold</div>,
      dataIndex: 'gs_threshold',
      key: 'gs_threshold',
      render: (gsThreshold) => {
        return <Text style={{ fontSize: '16px' }}>{gsThreshold}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Max Weight</div>,
      dataIndex: 'datalake_x',
      key: 'datalake_x',
      render: (datalakeX) => {
        return <Text style={{ fontSize: '16px' }}>{datalakeX}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>RMW</div>,
      dataIndex: 'datalake_b',
      key: 'datalake_b',
      render: (datalakeB) => {
        return <Text style={{ fontSize: '16px' }}>{datalakeB}</Text>
      },
      width: '11.1%',
      ellipsis: true,
    },    
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: ['rate', 'active'],
      render: (active, rate) => {
        return <StyledBadge variant={getStatus(rate.status, active)} />
      },
      width: '11.1%',
      align: 'center',
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default columns
