import React from 'react'
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Layout from 'components/Layout'
import Login from 'containers/auth/Login'
import UserStatusListener from 'containers/users/UserStatusListener'
import ResetPassword from 'containers/auth/ResetPassword'
import ActiveAccount from 'containers/auth/ActiveAccount'
import ForgotPassword from 'containers/auth/ForgotPassword'
import ErrorServer from 'containers/error/ErrorServer'
import Dashboard from './Dashboard'
import Rates from './Rates'
import Rules from './Rules'
import Settings from './Settings'
import Users from './User'


const Routes = () => {
  const { success, role, login } = useSelector((state) => ({
    success: state.login.success,
    role: state.login.data.roleId ? state.login.data.roleId.name : '',
    login: state.login.data
  }))
  const isUserLogged = !(login && Object.keys(login).length === 0 && login.constructor === Object)
  return (
    <BrowserRouter>
      {success && (
        <Layout role={role}>
          <UserStatusListener />
          <Dashboard />
          <Rates />
          <Rules />
          <Settings />
          <Users />
        </Layout>
      )}
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/error" component={ErrorServer} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/active-account/:token" component={ActiveAccount} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/login" component={Login} />
        <Redirect to={isUserLogged ? '/dashboard' : '/login'} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
