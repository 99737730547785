import styled from 'styled-components'
import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

export const ButtonDatalake = styled(Button)`
  border-radius: 5px;
`

export const SpaceDatalake = styled(Space)`
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 15px 0px;
`
