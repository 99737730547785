export default function (global, filtered) {
  return global.reduce((accum, globalEntry) => {
    const filter = filtered.filter((service) => globalEntry.id === (service.serviceId || service.id), [])
    if (filter.length) {
      accum.push({
        ...globalEntry,
        quantity: filter.reduce((sum, { quantity }) => sum + quantity, 0),
        serviceId: globalEntry.id,
      })
    }
    return accum
  }, [])
}
