import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { sum } from 'lodash/math'
import { sortBy } from 'lodash/collection'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Spin from 'antd/lib/spin'
import { Select } from 'antd/lib'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Text from 'antd/lib/typography/Text'
import { MdClose } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { BsPlusSquareFill } from 'react-icons/bs'
import notify from 'utils/notify'
import { stringRules } from 'utils/inputRules'
import { useDispatch, useSelector } from 'react-redux'
import StyledTabs from 'components/Tabs'
import TitleDrawer from 'components/TitleDrawer'
import PrimarySubmit from 'components/PrimarySubmit'
import DrawerItem from 'components/Form/DrawerItem'
import { InputGeneric } from 'components/Input'
import InputWithLabel from 'components/Form/InputWithLabel'
import AddToRulesetsModal from 'components/AddToRulesetsModal'
import { requestGetAllRules } from 'store/duck/getAllRules.duck'
import { clearCreateMultiStep, requestCreateMultiStep } from 'store/duck/createMultiStep.duck'
import { clearUpdateMultiStep, requestUpdateMultiStep } from 'store/duck/updateMultiStep.duck'
import { EnumStatus } from 'utils/getStatus'
import colors from 'providers/theme/config/colorPallete'
import useGetRulesIds from 'containers/rules/ListRules/utils/useGetRulesIds'
import CreateRuleModal from 'containers/rules/CreateNewRule'
import { FiPauseCircle } from 'react-icons/fi'
import { requestGetMultiStepsHistory } from 'store/duck/getMultiStepsHistory.duck'
import { clearReturnMultistep, requestReturnVersionMultistep } from 'store/duck/returnVersionMultistep.duck'
import { MULTI_STEPS } from 'utils/constants'
import HistoryItems from 'components/HistoryItem'
import jsonDiff from 'utils/jsonDiff'
import { StyledCol, StyledFooter, StyledModal } from './styles'

function DynamicRules({
  fields,
  allValuesForm,
  changedValuesForm,
  add,
  remove,
  rulesData,
  fetchingRules,
  setCreateViewRuleContainer,
}) {
  const dispatch = useDispatch()
  const { success, data, ruleSelected } = useSelector((state) => state.createRule)
  const { success: successUpdate } = useSelector((state) => state.updateRule)
  const [ruleName, setRuleName] = useState('')
  useEffect(() => {
    const ruleNameFromCreateRequest = data?.[0]?.name
    if (ruleNameFromCreateRequest) {
      setRuleName(ruleNameFromCreateRequest)
    }
  }, [data])

  useEffect(() => {
    if (success) {
      remove(fields.length - 1)
      add({ rule: { _id: data.id, name: ruleName }, connector: '' })
    }
  }, [success, add, remove, data.id, fields.length, ruleName])

  useEffect(() => {
    if (successUpdate) {
      remove(fields.length - 1)
      add({ rule: { _id: ruleSelected._id, name: ruleSelected.name }, connector: '' })
    }
  }, [add, data, fields.length, remove, ruleSelected._id, ruleSelected.name, successUpdate])

  useEffect(() => {
    const allRules = allValuesForm.rules
    const changedRules = changedValuesForm.rules

    if (!changedRules) {
      return
    }

    if (!Array.isArray(changedRules)) {
      return
    }
    const isChangedOnlyOneField = sum(changedRules.filter(Boolean).map((rule) => Object.keys(rule).length)) === 1
    const isChangedLatestConnector = changedRules[allRules.length - 1]?.connector
    if (isChangedOnlyOneField && isChangedLatestConnector && isChangedLatestConnector !== 'End') {
      add()
    }
  }, [add, allValuesForm.rules, changedValuesForm.rules])

  return fields.map(({ key, name, ...restField }, index) => (
    <div key={key} style={{ marginBottom: index > 0 ? 26 : undefined }}>
      <Row gutter={16}>
        <Col span={2}>
          <Row>
            <Col span={24} style={{ fontSize: '12px', top: '-8px' }}>
              Not
            </Col>
            <Col span={24} style={{ top: '-8px', display: 'flex', justifyContent: 'center' }}>
              <Form.Item
                dependencies={['rules']}
                shouldUpdate={(prevValues, currValues) => {
                  return prevValues.rules?.[index]?.negation !== currValues.rules?.[index]?.negation
                }}
              >
                {(props) => {
                  return (
                    <Checkbox
                      checked={props?.getFieldValue('rules')[index]?.negation ?? false}
                      onChange={(e) => {
                        const copy = [...props.getFieldValue('rules')]
                        copy[index].negation = e.target.checked
                        return props.setFieldsValue({
                          rules: copy,
                        })
                      }}
                    />
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={14} style={{ height: 1 }}>
          <InputWithLabel label="Rule *">
            {(hasValue) => (
              <DrawerItem
                name={[name, 'rule']}
                rules={[{ required: true, message: 'Please select the rule' }]}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...restField}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      allowClear
                      showSearch
                      size="large"
                      options={sortBy(rulesData, ['name'])}
                      defaultActiveFirstOption={false}
                      notFoundContent={fetchingRules ? <Spin size="small" /> : null}
                      onSearch={(text) => {
                        dispatch(requestGetAllRules({ text }))
                      }}
                      dropdownRender={(menu) => (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              margin: '10px 16px 14px',
                            }}
                          >
                            <BsPlusSquareFill style={{ fontSize: '18px', color: colors.dark_blue.default }} />
                            <Text
                              underline
                              style={{
                                marginLeft: '10px',
                                color: colors.dark_blue.default,
                                fontWeight: 400,
                                fontSize: '14px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setCreateViewRuleContainer(true)
                              }}
                            >
                              Add New Rule
                            </Text>
                          </div>

                          {menu}
                        </>
                      )}
                      value={value?.name}
                      onChange={(_, option) => {
                        onChange(option)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
        <Col span={8}>
          <InputWithLabel label="Connector *">
            {(hasValue) => (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currValues) =>
                  prevValues.rules?.[0]?.rule !== currValues.rules?.[0]?.rule
                }
              >
                {({ getFieldValue }) => (
                  <DrawerItem
                    name={[name, 'connector']}
                    rules={[
                      { required: true, message: 'Please select a connector' },
                      () => ({
                        async validator(_, value) {
                          if (index + 1 === fields.length && !!value && value !== 'End') {
                            throw new Error('The last connector should be End')
                          }
                        },
                      }),
                    ]}
                    dependencies={['rules']}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...restField}
                  >
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <Select
                          size="large"
                          value={value}
                          onChange={onChange}
                          disabled={!getFieldValue('rules')[index]?.rule}
                        >
                          <Select.Option value="And">And</Select.Option>
                          <Select.Option value="Or">Or</Select.Option>
                          {index === 0 ? '' : <Select.Option value="End">End</Select.Option>}
                        </Select>
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </Form.Item>
            )}
          </InputWithLabel>
        </Col>
      </Row>
      {index >= 1 && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -14 }}>
          <IoMdTrash style={{ fontSize: '24px', color: colors.dark_blue.default }} />
          <Text
            underline
            style={{
              marginLeft: '7px',
              color: '#002555',
              fontWeight: 400,
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              remove(index)
            }}
          >
            Delete Rule
          </Text>
        </div>
      )}

      <div
        style={{
          width: '100%',
          height: 1,
          backgroundColor: colors.light_grey.default,
          marginTop: index >= 1 ? 18 : -12,
          marginBottom: index === 0 ? 18 : undefined,
        }}
      />
    </div>
  ))
}

const emptyDefaultValues = {
  name: '',
  rules: [
    {
      rule: '',
      connector: '',
    },
  ],
  margin: '',
  flat: '',
  crossMultistep: false,
}

const ControlInput = ({ value, onChange, hasValue, children }) => {
  useEffect(() => {
    hasValue(value)
  }, [hasValue, value])

  return children({ value, onChange })
}

const CreateMultistepRuleModal = ({ visible, onCloseModal, changedDefaultValues }) => {
  const defaultValues = useMemo(
    () => ({
      ...emptyDefaultValues,
      ...changedDefaultValues,
    }),
    [changedDefaultValues]
  )

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [addToRulesetsVisible, setAddToRulesetsVisible] = useState(false)
  const { rules: rulesData, fetching: fetchingRules } = useSelector((state) => state.getAllRules)
  const { success, error, message, editMultistep, ruleSelected } = useSelector((state) => state.createMultiStep)
  const { successUpdate, error: errorUpdate, message: messageUpdate } = useSelector(
    (state) => state.updateMultiStep
  )
  const [crossMultistep, setcrossMultistep] = useState(false)
  const { shippingRule, referenceRule, typesId } = useGetRulesIds()
  const [loading, setLoading] = useState(false)

  const [defaultEditFormData, setDefaultEditFormData] = useState(null)
  const [changedValuesForm, setChangedValuesForm] = useState(defaultValues)
  const [allValuesForm, setAllValuesForm] = useState(defaultValues)

  const [createViewRuleContainer, setCreateViewRuleContainer] = useState(false)
  const DEFAULTACTIVEKEYEDITTAB = '1'
  const [activeKeyEditTab, setActiveKeyEditTab] = useState(DEFAULTACTIVEKEYEDITTAB)

  const [status, setStatus] = useState('Activated')
  const { multiSteps: multiStepsHistory } = useSelector((state) => state.getMultiStepsHistory)
  const { success: returnVersion, message: messageVersion } = useSelector((state) => state.returnVersionMultistep)

  useEffect(() => {
    if (returnVersion) {
      notify('success', messageVersion)
      dispatch(clearReturnMultistep())
      onCloseModal(false)
    }
  }, [returnVersion, messageVersion, onCloseModal, dispatch])

  const pauseStatus = () => {
    setStatus(EnumStatus.Paused)
  }
  useEffect(() => {
    if (editMultistep) {
      setStatus(ruleSelected.status)
      setcrossMultistep(ruleSelected.crossMultistep)
    }
    if (visible) {
      dispatch(requestGetMultiStepsHistory({ id: ruleSelected._id }))
    }
  }, [dispatch, editMultistep, ruleSelected.status, ruleSelected._id, visible, ruleSelected.crossMultistep])

  const submitCreateMultistepRule = (data) => {
    setLoading(true)
    const multistepData = {
      margin: Number(data.margin),
      flat: Number(data.flat),
      multistepName: data.name,
      sentences: data.rules.map((rule) => ({
        condition: rule.connector,
        ruleId: rule.rule._id,
        negation: rule.negation ?? false,
      })),
    }
    multistepData.crossMultistep = crossMultistep

    if (editMultistep) {
      const previous = { ...ruleSelected }
      delete previous.__v
      delete previous.createdAt
      delete previous.createdBy
      delete previous.deleted
      delete previous.rulesetsInfo
      delete previous.updatedAt
      multistepData.status = status
      previous.sentences = previous.sentences.map((rule) => ({
        condition: rule.condition,
        ruleId: rule.ruleId._id,
        negation: rule?.negation ?? false,
      }))
      const nextData = jsonDiff(previous, multistepData)
      nextData.id = ruleSelected._id
      dispatch(requestUpdateMultiStep(nextData))
    } else {
      dispatch(requestCreateMultiStep(multistepData))
    }
  }

  const handleValuesChange = useCallback((changedValues, allValues) => {
    setChangedValuesForm(changedValues)
    setAllValuesForm(allValues)
  }, [])

  useEffect(() => {
    setLoading(false)
    if (success) {
      notify('success', 'Multistep rule has been successfully created')
      onCloseModal(false)
    } else if (message && error) {
      Swal.fire({
        icon: 'error',
        title: 'Error creating Rule!',
        text: message,
      })
    } else {
      return
    }
    form.resetFields()

    dispatch(clearCreateMultiStep())
  }, [form, error, message, success, dispatch, onCloseModal])

  useEffect(() => {
    setLoading(false)

    if (successUpdate) {
      notify('success', 'Multistep rule has been successfully edited')
      form.resetFields()
      onCloseModal(false)
      dispatch(clearUpdateMultiStep())
    } else if (errorUpdate && messageUpdate) {
      notify('error', `Error editing multistep rule! ${messageUpdate}`)
    }
  }, [form, successUpdate, dispatch, onCloseModal, errorUpdate, messageUpdate])

  useEffect(() => {
    if (!editMultistep) {
      return
    }

    const newFormData = {
      margin: String(ruleSelected.margin),
      flat: String(ruleSelected.flat),
      name: ruleSelected.multistepName,
      rules: ruleSelected.sentences
        ? ruleSelected.sentences.map((rule) => ({
            connector: rule.condition,
            rule: rule.ruleId,
            negation: rule.negation,
          }))
        : [],
    }
    setDefaultEditFormData(newFormData)
    form.setFieldsValue(newFormData)
  }, [ruleSelected, editMultistep, form])

  useEffect(() => {
    if (visible) {
      if (editMultistep) {
        form.setFieldsValue(defaultEditFormData)
      } else {
        form.setFieldsValue(defaultValues)
      }
    }
  }, [defaultEditFormData, editMultistep, form, visible, defaultValues])

  const MultiStepForm = () => {
    return (
      <>
        <Row justify="space-between" style={{ marginBottom: '14px' }}>
          <Col>
            <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
              {editMultistep
                ? 'Edit the information of this Multi-Step Rule'
                : 'Add the information of this new Multi-Step Rule'}
            </Text>
          </Col>
          <Col>
            {editMultistep && (
              <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FiPauseCircle
                  onClick={status !== EnumStatus.Paused && pauseStatus}
                  style={
                    status !== EnumStatus.Paused
                      ? { fontSize: '19px', color: colors.dark_blue.default, cursor: 'pointer' }
                      : { fontSize: '19px', color: colors.light_grey.default, cursor: 'not-allowed' }
                  }
                />
                <Text
                  underline
                  disabled={status === EnumStatus.Paused}
                  style={
                    status !== EnumStatus.Paused
                      ? {
                          marginLeft: '7px',
                          color: '#002555',
                          fontWeight: 400,
                          fontSize: '16px',
                          cursor: 'pointer',
                        }
                      : {
                          marginLeft: '7px',
                          fontWeight: 400,
                          fontSize: '16px',
                        }
                  }
                  onClick={status !== EnumStatus.Paused && pauseStatus}
                >
                  Pause Rule
                </Text>
              </Row>
            )}
          </Col>
        </Row>
        <Form
          form={form}
          layout="vertical"
          name="create-multistep-form"
          initialValues={defaultValues}
          onValuesChange={handleValuesChange}
          onFinish={submitCreateMultistepRule}
          style={{ marginTop: 6 }}
          hideRequiredMark
        >
          <Row gutter={10}>
            <Col span={24}>
              <InputWithLabel label="Multi Step-Rule Name *">
                {(hasValue) => (
                  <DrawerItem name="name" rules={stringRules('Multi Step-Rule´s name')}>
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => <InputGeneric size="large" value={value} onChange={onChange} />}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
            </Col>
            <Col span={24}>
              <Form.List name="rules">
                {(fields, { add, remove }) => (
                  <DynamicRules
                    fields={fields}
                    allValuesForm={allValuesForm}
                    changedValuesForm={changedValuesForm}
                    add={add}
                    remove={remove}
                    rulesData={rulesData}
                    fetchingRules={fetchingRules}
                    setCreateViewRuleContainer={setCreateViewRuleContainer}
                  />
                )}
              </Form.List>
            </Col>
          </Row>
          <Row gutter={10}>
            <StyledCol span={24}>
              <Text
                style={{
                  color: '#545454',
                  fontWeight: 400,
                  fontSize: '16px',
                  fontStyle: 'italic',
                  marginTop: -10,
                  marginBottom: 10,
                }}
              >
                Make these rate adjustments
              </Text>
            </StyledCol>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <InputWithLabel label="Percentage *">
                {(hasValue) => (
                  <DrawerItem
                    name="margin"
                    rules={[
                      { required: true, message: 'Please enter a percentage' },
                      {
                        type: 'number',
                        max: 100,
                        message: 'The percentage should not be greater than 100',
                        transform: Number,
                      },
                      {
                        type: 'number',
                        min: -100,
                        message: 'The percentage should not be less than -100',
                        transform: Number,
                      },
                    ]}
                    style={{ marginBottom: 10 }}
                    shouldUpdate
                  >
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <InputGeneric type="number" size="large" suffix="%" value={value} onChange={onChange} />
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
            </Col>
            <Col span={12}>
              <InputWithLabel label="Flat *" labelStyle={{ leftBeforeFloat: '24px' }}>
                {(hasValue) => (
                  <DrawerItem
                    name="flat"
                    rules={[{ required: true, message: 'Please enter a flat' }]}
                    style={{ marginBottom: 10 }}
                  >
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <InputGeneric type="number" size="large" prefix="$" value={value} onChange={onChange} />
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
            </Col>
            <Col span={24}>
              <Checkbox
                style={{ margin: '7px 0px' }}
                checked={crossMultistep}
                onChange={() => {
                  setcrossMultistep(!crossMultistep)
                }}
              >
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Master Multistep</Text>
              </Checkbox>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  const openAddToRulesets = async () => {
    await form.validateFields()

    setAddToRulesetsVisible(true)
  }

  return (
    <StyledModal
      title={<TitleDrawer title={editMultistep ? 'Edit Multi-Step Rule' : 'Create Multi-Step Rule'} />}
      width={500}
      style={{ top: 100 }}
      afterClose={() => {
        if (editMultistep) {
          setActiveKeyEditTab(DEFAULTACTIVEKEYEDITTAB)
        } else {
          form.setFieldsValue(defaultValues)
        }
      }}
      onCancel={() => {
        onCloseModal(false)
        form.resetFields()
      }}
      visible={visible}
      bodyStyle={{ padding: editMultistep ? '0px 24px' : '16px 24px 0px' }}
      footer={
        <StyledFooter>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 1 }}>
            <PrimarySubmit
              label={editMultistep ? 'Edit Multi-Step Rule' : 'Create Multi-Step Rule'}
              formName="create-multistep-form"
              loading={loading}
            />
            <Button
              type="text"
              style={{ backgroundColor: 'white', marginLeft: 0, marginTop: 16 }}
              onClick={() => openAddToRulesets()}
            >
              <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Add to RuleSets</span>
            </Button>
          </div>
        </StyledFooter>
      }
      closeIcon={<MdClose size={24} color="#000000" />}
    >
      {editMultistep ? (
        <StyledTabs
          activeKey={activeKeyEditTab}
          onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
          tabBarStyle={{ width: '100%' }}
        >
          <StyledTabs.TabPane tab="Rules Info" key="1">
            {MultiStepForm()}
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="History" key="2">
            <HistoryItems
              id="multistepId"
              rules
              data={multiStepsHistory}
              category={MULTI_STEPS}
              request={requestReturnVersionMultistep}
            />
          </StyledTabs.TabPane>
        </StyledTabs>
      ) : (
        MultiStepForm()
      )}

      <CreateRuleModal
        addNewRuleForCreateMultistep
        typesId={typesId}
        shippingRule={shippingRule}
        referenceRule={referenceRule}
        visible={createViewRuleContainer}
        onCloseModal={setCreateViewRuleContainer}
        columnsParams={{
          type: 'geographicRule',
          shipping: 'outState',
          reference: 'state',
        }}
      />

      <AddToRulesetsModal
        visible={addToRulesetsVisible}
        onClose={() => setAddToRulesetsVisible(false)}
        onTriggerSubmitForm={() => form.submit()}
      />
    </StyledModal>
  )
}

export default CreateMultistepRuleModal
