import React from 'react'
import { useSelector } from 'react-redux'
import Graphics from 'containers/dashboard/Graphics'
import DatalakeTable from 'components/DatalakeTable'
import Tabs from 'components/Tabs'
import CardShadow from 'components/Card/CardShadow'
import colors from 'providers/theme/config/colorPallete'
import GraphMCI from 'containers/rates/GraphMCI'

const DashboardTab = () => {
  const { success, rate, mciGraphics } = useSelector((state) => ({
    success: state.getRate.success,
    rate: state.getRate.rate,
    mciGraphics: state.getRate.rate.mciGraphics,
    datalakeTable: state.getRate.rate.datalakeTable
  }))
  const datalakeTable = useSelector((state) => state.getRate.rate.datalakeTable)
  const { TabPane } = Tabs

  const displayGraph = () => {
    if (mciGraphics) {
      const value = JSON.stringify(mciGraphics)
      return value !== '{}'
    }
    return false
  }

  const displayTable = () => {
    const display = false
    if (datalakeTable) {
      const properties = ['items', 'outlierItems']
      let size = 0
      properties.forEach((element) => {
        if (datalakeTable[element]) {
          size += datalakeTable[element].length
        }
      })
      if (size > 0) {
        return !display
      }
    }
    return display
  }
  return (
    <CardShadow borderless title="">
      <span
        style={{
          color: colors.dark_blue.default,
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        Historical Data
      </span>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Graph" key="1">
          {success ? (
            <Graphics />
          ) : (
            <div>
              <p>
                Empty section, please, enter the data to make the quote and you will be able to view the Dashboard
                information.
              </p>
            </div>
          )}
        </TabPane>
        {displayTable() ? (
          <TabPane tab="Historical Data" key="2" style={{ position: 'relative' }}>
            <DatalakeTable Datalake={rate.datalakeTable} />
          </TabPane>
        ) : (
          ''
        )}
        {displayGraph() ? (
          <TabPane tab="Market conditions" key="3" style={{ position: 'relative' }}>
            <GraphMCI data={mciGraphics} />
          </TabPane>
        ) : null}
      </Tabs>
    </CardShadow>
  )
}

export default DashboardTab
