import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const { requestGetMarkets, successGetMarkets, failureGetMarkets } = createActions({
  REQUEST_GET_MARKETS: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_MARKETS: (data) => ({
    data ,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_MARKETS: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const markets = handleActions(
  {
    [requestGetMarkets]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetMarkets]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureGetMarkets]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
  },
  defaultState
)

export default markets
