import { put, takeLatest, call } from 'redux-saga/effects'
import { auth } from 'services'
import { requestPostLogin, successPostLogin, failurePostLogin } from 'store/duck/login.duck'
import { parseLoginData } from 'utils/parseAuthData'
import { saveStorage } from 'utils/storage'
import axiosManager from 'utils/axiosManager'

function* loginRequestAsync(data) {
  try {
    const response = yield call(auth.loginUser, data.payload.data)
    const userData = parseLoginData(response.token)
    saveStorage(response.token, 'altptk')
    saveStorage(response.refreshToken, 'altprtk')
    axiosManager.setAuthorization(response.token)
    yield put(successPostLogin(userData))
  } catch (error) {
    yield put(failurePostLogin(error.response.data.message))
  }
}

function* watchLoginAsync() {
  yield takeLatest(requestPostLogin, loginRequestAsync)
}

export default watchLoginAsync
