import React from 'react'
import { getStatus } from 'utils/getStatus'
import UserNameAvatar from 'components/User/UserNameAvatar'
import Tooltip from 'antd/lib/tooltip'
import colors from 'providers/theme/config/colorPallete'
import Typography from 'antd/lib/typography'
import { Copy } from 'assets/svg'
import decrypt from 'utils/decrypt'
import actionsDropdown from './actionsDropdown'
import { StyledBadge, StyledText, Point, ImgCopy } from './styles'
import CustomerDefaultSwitch from './CustomerDefaultSwitch'

const { Text } = Typography

const Main = ({ children }) => (
  <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
    <StyledText>{children}</StyledText>
  </div>
)

const copyPass = (text) => {
  navigator.clipboard.writeText(decrypt(text))
}

const StylePassword = ({ pass }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Point /> <Point />
    <Point /> <Point />
    <Point /> <Point />
    <Point /> <Point />
    <Point />
    <ImgCopy src={Copy} onClick={() => copyPass(pass)} aria-hidden="true" />
  </div>
)

const CustomerColumns = (dispatch, openDrawer) => {
  return [
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'customer',
      key: 'name',
      render: ({
        name,
        avatar,
        considerCustomFuelPrice,
        flatbedFuelPrice,
        reeferFuelPrice,
        vanFuelPrice,
        lastUpdatedFuelPrice,
        
      }) => (
        <UserNameAvatar
          considerCustomFuelPrice={considerCustomFuelPrice}
          flatbedFuelPrice={flatbedFuelPrice}
          reeferFuelPrice={reeferFuelPrice}
          vanFuelPrice={vanFuelPrice}
          lastUpdatedFuelPrice={lastUpdatedFuelPrice}
          name={name}
          avatar={avatar}
        />
      ),
      width: 290,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>TMS ID</div>,
      dataIndex: ['customer', 'tmsId'],
      key: 'tmsId',
      render: (tmsId) => <Main>{tmsId}</Main>,
      width: 100,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Email</div>,
      dataIndex: ['customer', 'email'],
      key: 'email',
      render: (email) => <Main>{email}</Main>,
      width: 175,
      align: 'left',
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Phone</div>,
      dataIndex: ['customer', 'phone'],
      key: 'phone',
      render: (phone) => <Main>{phone}</Main>,
      width: 100,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Password</div>,
      dataIndex: ['customer', 'apiPassword'],
      key: 'apiPassword',
      render: (apiPassword) => (apiPassword ? <StylePassword pass={apiPassword} /> : null),
      width: 150,
      align: 'left',
      ellipsis: true,
      textWrap: 'ellipsis',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Active Rulesets</div>,
      dataIndex: 'ruleSets',
      key: 'activeRulesets',
      render: (ruleSets) => {
        return (
          <Tooltip
            placement="bottom"
            title={
              ruleSets?.length > 0 &&
              ruleSets.map((ruleSet, index) => <div key={index}>{ruleSet.rulesetName}</div>)
            }
            color="#3594FF"
          >
            <Text
              underline
              style={{ color: colors.light_blue.default, cursor: 'pointer', fontSize: '16px', display: 'flex' }}
            >
              {ruleSets?.length ?? 0}
            </Text>
          </Tooltip>
        )
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Percentage</div>,
      dataIndex: ['customer', 'margin'],
      key: 'margin',
      render: (margin) => <Main>{margin}%</Main>,
      width: 100,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Flat</div>,
      dataIndex: ['customer', 'flat'],
      key: 'flat',
      render: (flat) => <Main>${flat}</Main>,
      width: 50,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Default User</div>,
      dataIndex: ['customer', 'default'],
      key: 'defaultUser',
      render: (active, record) => {
        return <CustomerDefaultSwitch status={active} customerId={record.customer._id} title="Default User" />
      },
      width: 150,
      align: 'center',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: ['customer', 'active'],
      key: ['rate', 'active'],
      render: (active, rate) => (
        <div style={{ display: 'flex' }}>
          <StyledBadge variant={getStatus(rate.status, active)} />
        </div>
      ),
      width: 75,
      align: 'left',
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default CustomerColumns
