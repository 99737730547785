import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from 'components/Box/BoxGeneric'
import Frame from 'components/Frame'
import Spin from 'antd/lib/spin'

const SonarRato = () => {
  const { destination, origin } = useSelector((state) => state.getZipHere)
  const { success, fetching } = useSelector((state) => state.getRate)
  const ref = useRef()
  const [width, setWidth] = useState(0)
  const [delta, setDelta] = useState(0)
  const [signalURL, setSignalURL] = useState('')
  const [serviceComplete, setServiceComplete] = useState(false)
  const baseURL =
    'https://widgets.freightwaves.com/apps/od-heat-guages/dist/?token=b3a96b5a-6ea5-4367-8cbd-e6c079d2a93d'

  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.clientWidth)
      setDelta(width / 690)
    }
    if (ref.current) {
      setWidth(ref.current.clientWidth)
      setDelta(width / 690)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setDelta, delta, width])

  useEffect(() => {
    if (success && !serviceComplete) {
      setSignalURL(`${baseURL}&zips=${origin}-${destination}`)
      setServiceComplete(true)
    }
  }, [success, origin, destination, baseURL, serviceComplete])

  useEffect(() => {
    if (!success) {
      setServiceComplete(false)
      setSignalURL(baseURL)
    }
  }, [success])

  return (
    <Spin spinning={fetching}>
      <Box title="Sonar Signals" height="auto" >
        <div
          ref={ref}
          style={{
            overflow: 'hidden',
            paddingBottom: '35.9375%',
            width: '100%',
            display: 'block',
            height: '0',
          }}
        >
          <Frame title="Sonar Signals" src={signalURL} width={690} height={230} scale={delta} />
        </div>
      </Box>
    </Spin>
  )
}

export default SonarRato
