import styled from 'styled-components'
import { Button } from 'antd'

const TextButton = styled(Button)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) =>
    props.secondary === 'true' ? props.theme.colors.dark_grey.default : props.theme.colors.dark_blue.default};
  &:hover {
    color: ${(props) =>
      props.secondary === 'true' ? props.theme.colors.dark_grey.default : props.theme.colors.dark_blue.default};
    font-weight: 600 !important;
  }
  &::after {
    all: unset;
  }
`

export const LinkButton = styled(Button)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight:400;
  color: #545454
  &:hover {
    color: ${(props) =>
      props.secondary === 'true' ? props.theme.colors.dark_grey.default : props.theme.colors.dark_blue.default};
    font-weight: 600 !important;
  }
  &::after {
    all: unset;
  }
`

export default TextButton
