import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, DatePicker, Button } from 'antd'
import { requestGetAllRates } from 'store/duck/getAllRates.duck'
import Table from 'components/Table'
import columns from './utils/tableColumns'
import reducer from './utils/reducer'

const ListRates = () => {
  const [{ params, push }, setParams] = useReducer(reducer, { params: {}, push: {} })
  const { data, success, fetching } = useSelector((state) => state.getAllRates)
  const dispatch = useDispatch()
  const history = useHistory()
  const columnData = columns(setParams)
  const [form] = Form.useForm()

  const handleTableChange = (pagination) => {
    setParams({ type: 'pagination', payload: { page: pagination.current } })
  }

  const handleOnFinish = (values) => {
    setParams({ type: 'filters', payload: values })
  }

  useEffect(() => {
    dispatch(requestGetAllRates(params))
  }, [dispatch, params])

  useEffect(() => {
    if (Object.keys(push).length > 0) history.push({ pathname: 'create-rate', ...push })
  }, [push, history])

  return (
    <>
      <Form
        form={form}
        name="filterRates"
        layout="vertical"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleOnFinish}
      >
        <Form.Item name="text" label="Search by similarity">
          <Input />
        </Form.Item>
        <Form.Item name="createdAt" label="Search by creation range">
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item name="updatedAt" label="Search by last time updated">
          <DatePicker.RangePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" block loading={fetching}>
            Filter
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columnData}
        data={data.quotes ? data.quotes : null}
        loading={!success}
        rowKey="_id"
        pagination={{ pageSize: 5, total: data.length, current: params.current }}
        onChange={handleTableChange}
      />
    </>
  )
}

export default ListRates
