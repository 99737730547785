const returnStops = (data = {}) => {
    const st = []
    st.push(`${data?.lane.origin.city}, ${data?.lane.origin.zipCode}`)
    if (data?.stops && data?.stops.length > 0) {
      data.stops.forEach((element) => {
        if (st[0].match(element?.origin.city) === null)
          st.push(`${element?.origin.city}, ${element?.origin.zipCode}`)
        if (data?.lane.destination.city.match(element?.destination.city) === null)
          st.push(`${element?.destination.city}, ${element?.destination.zipCode}`)
      })
    }
    return new Set([...st, `${data?.lane.destination.city}, ${data?.lane.destination.zipCode}`])
  }

export default returnStops
