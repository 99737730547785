export const RULE_GEOGRAPHIC = 'RuleGeographic'
export const RULE_SIMPLE = 'RuleSimple'
export const RULE_DATE_RANGE = 'RuleDateRange'
export const RULE_LENGTH = 'RuleLength'
export const RULE_EQUIPMENT = 'RuleEquipment'
export const RULE_MARKET = 'RuleMarket'
export const MULTI_STEPS = 'MultiSteps'
export const RULE_SETS = 'RuleSets'
export const SPECIAL_SERVICE = 'SpecialService'
export const TRUCK_TYPE = 'TruckType'
export const EQUIPMENT_TYPE = 'RuleEquipment'
export const REFERENCE_STATE = 'State'
export const REFERENCE_ZIP = 'Zipcode'
export const SHIPPING_INBOUND = 'inbound'
export const SHIPPING_OUTBOUND = 'outbound'
