import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { MdBlock, MdDelete, MdModeEdit } from 'react-icons/md'
import { loadUser } from 'store/duck/createUser.duck'
import { requestActivateUser } from 'store/duck/activateUser.duck'
import { requestDeactivateUser } from 'store/duck/deactivateUser.duck'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { AiOutlineCheck } from 'react-icons/ai'
import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record }) => {
  const dispatch = useDispatch()
  const current = useSelector((state) => state.login.data.id)
  const isCurrent = current === record._id
  const [isActive, setIsActive] = useState(record.active)

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this User?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'User has been deleted',
    actionConfirm: (some) => some,
  })

  const changeStatus = () => {
    setPropsModal({
      visible: true,
      message: `Are you sure you want to ${isActive ? 'disable' : 'enable'}  this User?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => (isActive ? <MdBlock {...props} /> : <AiOutlineCheck {...props} />),
      textButon: isActive ? 'Disable' : 'Enable',
      textSuccessfull: `User has been ${isActive ? 'disabled' : 'enabled'}`,
      actionConfirm: () => {
        if (!isCurrent) {
          dispatch(requestActivateUser({ active: !isActive }, record._id))
          dispatch(requestMessageSnackBar(`User has been ${isActive ? 'disabled' : 'enabled'}`))
          setIsActive(!isActive)
        }

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }
  const handleDeleteRule = async (userId) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this User?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'User has been deleted',
      actionConfirm: () => {
        dispatch(requestDeactivateUser(userId))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => dispatch(loadUser(record))}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit User</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => changeStatus()}>
            {!isActive ? (
              <StyledRow>
                <StyledCol>
                  <AiOutlineCheck size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Enable User</span>
                </StyledCol>
              </StyledRow>
            ) : (
              <StyledRow>
                <StyledCol>
                  <MdBlock size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Disable User</span>
                </StyledCol>
              </StyledRow>
            )}
          </Menu.Item>

          <Menu.Item style={paddingItem} onClick={() => handleDeleteRule(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete User</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            avoidNotify
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}>Actions </div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => <ActionsDropdown record={record} openDrawer={openDrawer} />,
  align: 'center',
  width: 100,
})

export default actionsDropdown
