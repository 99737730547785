import React from 'react'
import moment from 'moment'
import Typography from 'antd/lib/typography'
import PriceLabel from 'components/Label/PriceLabel'
import colors from 'providers/theme/config/colorPallete'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Fuel } from 'assets/svg'
import Tooltip from 'antd/lib/tooltip'
import { StyledDiv } from '../styles'
import actionsDropdown from './actionsDropdown'

const { Text } = Typography

export default function (onExpand, expandedRowKey) {
  return [
    {
      title: <div style={{ fontSize: '16px' }}>Creation Date</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 260,
      ellipsis: true,
      render: (createdAt, record) => {
        return (
          <div style={{ fontSize: '16px' }}>
            <div style={{ color: colors.dark_grey.default, marginBottom: 4 }}>
              {moment(createdAt).format('MM/DD/YYYY HH:mm')}
            </div>
            <div style={{ color: '#A1A1A1' }}>Quote ID: {record._id}</div>
          </div>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Created By</div>,
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      render: (createdBy) => (
        <div style={{ color: colors.dark_grey.default, fontSize: '16px' }}>
          {createdBy ? createdBy.name : ''} {createdBy ? createdBy.lastName : ''}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize: '16px' }}>Customer</div>,
      dataIndex: ['customer', 'name'],
      key: 'customer',
      width: 120,
      ellipsis: true,
      render: (customerName) => (
        <div style={{ color: colors.dark_grey.default, fontSize: '16px' }}>{customerName}</div>
      ),
    },
    {
      title: <div style={{ fontSize: '16px' }}> Lane</div>,
      dataIndex: ['stops'],
      key: 'origin',
      width: 200,
      ellipsis: true,
      render: (stops, record) => {
        if (stops.length > 1) {
          return (
            <div style={{ color: colors.dark_grey.default, fontSize: '16px' }}>
              {stops.length + 1} Stops
              {record._id === expandedRowKey ? (
                <MdKeyboardArrowUp
                  fontSize="18px"
                  style={{
                    color: '#3594FF',
                    cursor: 'pointer',
                    marginLeft: 4,
                    verticalAlign: 'middle',
                  }}
                  onClick={() => onExpand(record)}
                />
              ) : (
                <MdKeyboardArrowDown
                  fontSize="18px"
                  style={{ color: '#3594FF', cursor: 'pointer', marginLeft: 4, verticalAlign: 'middle' }}
                  onClick={() => onExpand(record)}
                />
              )}
            </div>
          )
        }
        const { lane } = record
        if (lane) {
          if (lane.origin && lane.destination) {
            return (
              <div style={{ color: colors.dark_grey.default, fontSize: '16px' }}>
                {lane.origin.city} - {lane.destination.city}
              </div>
            )
          }          
        }
        return null
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Pickup Date</div>,
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      width: 120,
      ellipsis: true,
      align: 'left',
      render: (pickupDate) => {
        return (
          <StyledDiv>
            <Text>{moment(pickupDate).format('MM-DD-YYYY')}</Text>
          </StyledDiv>
        )
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}>Equipment</div>,
      dataIndex: ['truckTypeId', 'name'],
      key: 'truckTypeId',
      width: 100,
      ellipsis: true,
      align: 'left',
      render: (equipment) => <div style={{ fontSize: '16px' }}>{equipment}</div>,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Weight</div>,
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      ellipsis: true,
      align: 'left',
      render: (weight) => <div style={{ fontSize: '16px' }}>{weight}</div>,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Rate</div>,
      dataIndex: 'rate',
      key: 'rate',
      width: 90,
      ellipsis: true,
      align: 'left',
      render: (rate, record) => {
        const lh = record.LH ?? ''
        return (
          <div style={{ lineHeight: '20px', display: 'flex', alignItems: 'center' }}>
            <PriceLabel>$ {rate}</PriceLabel>
            {lh !== '' ? (
              <Tooltip
                placement="bottom"
                title={
                  <span style={{ color: '#545454' }}>
                    <Text style={{ fontStyle: 'italic', fontWeight: 600 }}>LH:</Text>
                    <Text style={{ fontStyle: 'italic' }}>{` $${record.LH}`}</Text>
                  </span>
                }
                color="#D9D9D9"
              >
                <img
                  alt="fuel"
                  style={{ marginLeft: '3px', width: '16px', height: '16px', paddingBottom: '1px' }}
                  src={Fuel}
                />
              </Tooltip>
            ) : null}
          </div>
        )
      },
    },
    {
      ...actionsDropdown(),
    },
  ]
}
