/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useReducer, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Form, Select, Button as AButton, InputNumber, Tooltip } from 'antd'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import { FormItem, InputDatePicker } from 'components/Input'
import { AiOutlinePlus, AiTwotoneCalendar } from 'react-icons/ai'
import Swal from 'sweetalert2'
import Box from 'components/Box/BoxGeneric'
import Button from 'components/Button/ButtonPrimary'
import ButtonQuaternary from 'components/Button/ButtonQuaternary'
import SpinLoading from 'components/SpinLoading'
import StyledSelect from 'components/Input/Select'
import { requestSingleCustomer } from 'store/duck/getCustomers.duck'
import { requestGetAllCustomer } from 'store/duck/getAllCustomer.duck'
import { requestGetTruckTypes } from 'store/duck/getTruckTypes.duck'
import { requestGetSpecialServices } from 'store/duck/getSpecialServices.duck'
import { requestGetDefaultCustomer } from 'store/duck/getDefaultCustomer.duck'
import { saveServices, clearSpecialServices, updateSpecialService } from 'store/duck/saveSpecialServices.duck'
import {
  saveRate,
  saveTotalMilles,
  removeWayPoint,
  addWayPoint as addSpotWay,
  clearRate,
} from 'store/duck/saveQuote.duck'
import { saveRouteMarkers, clearGetZipHere, finishRate, resetRate } from 'store/duck/getZipHere.duck'
import { requestGetRate, clearGetRate } from 'store/duck/getRate.duck'
import { clearCreateRate } from 'store/duck/createRate.duck'
import { requestGetRouteData, clearGetRouteData } from 'store/duck/getRouteData.duck'
import { saveSendEmail } from 'store/duck/sendEmail.duck'
import { requiredSimple, requiredWeight, requiredQuantity } from 'utils/inputRules'
import useAutocomplete from 'hooks/useAutocomplete'
import { ArrowDownIcon } from 'assets/svg'
import FloatLabel from 'components/Label/FloatLabel'
import { BsFillPlusSquareFill } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import ModalRates from 'components/ModalRates'
import { useLocation, useHistory } from 'react-router-dom'
import { GoAlert } from 'react-icons/go'
import Typography from 'antd/lib/typography'
import CustomSelect from 'components/CustomSelect'
import reducer from './utils/reducer'
import matchServices from '../ListSpecialServices/utils/matchArrays'
import AddCityButton from '../AddWayPoints/styles'
import { CitiesList, Container, LabelForm, SpecialService, SpecialServiceList, StyledTag } from './styles'
import CityFormItem from './CityFormItem'
import ModalErrorRate from './ModalErrorRate'

const GetRates = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [deliveryState, setDeliveryState] = useState(false)
  const [totalCustomer, setTotalCustomers] = useState([])
  const [rateGenerated, setRateGenerated] = useState(false)
  const [pickupDate, setPickupDate] = useState(moment())
  const [weightController, setWeight] = useState(35000)
  const [deliveryDate, setDeliveryDate] = useState('')
  const [specialServicesState, setSpecialServicesState] = useState([])
  const {
    customers,
    defaultCustomer,
    editRate,
    error,
    fetching,
    hereData,
    laneData,
    rateSelected,
    routeData,
    savedServices,
    services,
    singleCustomer,
    success,
    successSaveRate,
    truckTypes,
    message,
    dataZip,
    wayPoints,
    successZip,
  } = useSelector((state) => ({
    customers: state.getAllCustomer.data,
    defaultCustomer: state.getDefaultCustomer.data,
    editRate: state.createRate.editRate,
    error: state.getRate.error,
    fetching: state.getRate.fetching,
    hereData: state.getZipHere,
    laneData: state.saveQuote.data,
    rateSelected: state.createRate.rateSelected,
    routeData: state.getRouteData,
    savedServices: state.saveSpecialServices,
    services: state.getSpecialServices.services,
    singleCustomer: state.getCustomers.singleCustomer,
    success: state.getRate.success,
    successSaveRate: state.saveQuote.success,
    truckTypes: state.getTruckTypes.trucktypes,
    message: state.getRate.message,
    dataZip: state.getZipHere.data,
    wayPoints: state.saveQuote.wayPoints,
    successZip: state.getZipHere.success,
  }))

  const [editEnter, setEditEnter] = useState(editRate)
  const [
    { currentSpecialService, currentQuantity, specialServices, params, servicesParams },
    setValues,
  ] = useReducer(reducer, {
    specialServices: editRate || success ? savedServices.data : [],
    customer: '',
    currentSpecialService: undefined,
    currentQuantity: 1,
    params: {},
    servicesParams: {},
  })
  const defaultValues = {
    origin: null,
    pickup: moment(),
    destination: null,
    delivery: '',
    weight: 35000,
    quantity: 1,
    names: ['', ''],
  }

  const [dataDestination, setPlaceDestination, handleSelect] = useAutocomplete()
  const [openError, setopenError] = useState(false)
  const { Text } = Typography

  const BodyError2 = ({ messageBody }) => {
    return (
      <Row
        gutter={[16, 14]}
        style={messageBody.length > 50 ? { display: 'inline' } : { padding: '37px 20px 20px 20px' }}
      >
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '45px',
              height: '45px',
              borderRadius: '8px',
              backgroundColor: '#F85359',
            }}
          >
            <GoAlert size={30} color="white" />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text style={{ wordWrap: 'break-word' }}>
            {messageBody ===
              'Due to rules configurations the total rate is under DAT Contract, review and try again' ||
            messageBody === 'No rate information available for this request.' ||
            messageBody === 'Rates not available!'
              ? messageBody
              : `Error while executing the lane criteria. Details: ${messageBody}`}
          </Text>
        </Col>
      </Row>
    )
  }

  useEffect(() => {
    batch(() => {
      dispatch(requestGetAllCustomer({ active: true }))
      dispatch(requestGetDefaultCustomer())
      dispatch(requestGetTruckTypes())
      dispatch(requestGetSpecialServices(servicesParams))
    })
  }, [dispatch, servicesParams])

  useEffect(() => {
    setTotalCustomers(customers)
  }, [customers])

  useEffect(() => {
    if (customers.find((entry) => entry.value === defaultCustomer.value)) return
    const _customers = [...customers]
    _customers.push(defaultCustomer)
    setTotalCustomers(_customers)
  }, [customers, defaultCustomer])

  useEffect(() => {
    const _default = form
      ?.getFieldInstance('customer')
      ?.props.options.find((entry) => entry.value === singleCustomer.value)
    if (_default === undefined || Object.keys(_default).length === 0) return
    dispatch(requestSingleCustomer(_default))
  }, [form, defaultCustomer, totalCustomer, dispatch, singleCustomer])

  useEffect(() => {
    dispatch(saveServices(specialServices))
  }, [specialServices, dispatch])

 
  function returnEntity(name, markersParam) {
    return markersParam.filter((ele) => ele?.content?.type === name)[0] ?? {}
  }

  function fullNameCity(obj) {
    const city = obj?.content?.city ?? ''
    const zipCode = obj?.content?.zipCode ?? ''
    if (city !== '' && zipCode !== '') {
      return `${obj?.content?.city ?? ''} - ${obj?.content?.zipCode ?? ''}`
    }
    return ''
  }

  function formatStops(st = []) {
    return st.map((item) => {
      return fullNameCity(item)
    })
  }

  const origin = fullNameCity(returnEntity('Origin', hereData.markers))
  const destination = fullNameCity(returnEntity('Destination', hereData.markers))
  const stopPoints = formatStops(hereData.stopMarkers)

  useEffect(() => {
    if (editRate && editEnter) {
      let names = []
      if (stopPoints.length > 0) {
        names = [origin, ...stopPoints, destination]
      } else {
        names = [origin, destination]
      }

      const loadRate = { ...rateSelected }
      form.setFieldsValue({
        customer: loadRate.customer.name,
        pickup: moment(loadRate.pickupDate),
        delivery: moment(loadRate.deliveryDate),
        truckTypeId: loadRate.truckTypeId._id,
        weight: loadRate.weight,
        names,
      })
      setEditEnter(false)
    }
    if (!editRate && success && !editEnter && Object.keys(laneData).length > 0) {
      const originLaneCityWithZip = `${laneData.lane.origin.city} - ${laneData.lane.origin.zipCode}`
      const destinationLaneCityWithZip = `${laneData.lane.destination.city} - ${laneData.lane.destination.zipCode}`
      form.setFieldsValue({
        customer: laneData.customerName,
        weight: laneData.weight,
        origin: originLaneCityWithZip,
        destination: destinationLaneCityWithZip,
        delivery: moment(laneData.deliveryDate),
        pickup: moment(laneData.pickupDate),
        truckTypeId: laneData.truckTypeId,
      })
      setEditEnter(true)
    }
  }, [
    defaultValues,
    editRate,
    success,
    form,
    rateSelected,
    laneData,
    handleSelect,
    editEnter,
    origin,
    stopPoints,
    destination,
  ])

  useEffect(() => {
    if (error && message !== '') {
      setopenError(true)
    }
  }, [dispatch, error, message])

  const closeModalCleanRate = () => {
    dispatch(clearGetRate())
    setopenError(false)
  }

  useEffect(() => {
    if (Object.keys(defaultCustomer).length === 0) return
    if (!editRate && !success && !editEnter) {
      form.setFieldsValue({ customer: defaultCustomer.value })
      setEditEnter(true)
    }
  }, [defaultCustomer, form, success, editRate, editEnter])

  useEffect(() => {
    if (routeData.success && routeData.data.routes.length > 0) {
      const spotPoints = []
      const rateData = { ...params }
      let totalMilles = 0
      const totalMovements = routeData.data.routes[0].sections.length
      routeData.data.routes[0].sections.map((item, index) => {
        const { duration, length: totalDistance } = item.summary
        const travelDistance = parseInt(totalDistance / 1609.34, 10)
        const travelTime = parseInt(duration / 3600, 10)
        totalMilles += travelDistance
        const addWayPoint = { milles: travelDistance, travelTime }
        if (index === 0) {
          addWayPoint.origin = {
            city: hereData.routeMarkers[index]?.content.city,
            zipCode: hereData.routeMarkers[index]?.content.zipCode,
            coordinate: hereData.routeMarkers[index]?.coordinate,
          }
          if (totalMovements > 1) {
            addWayPoint.destination = {
              city: hereData.routeMarkers[2]?.content.city,
              zipCode: hereData.routeMarkers[2]?.content.zipCode,
              coordinate: hereData.routeMarkers[2]?.coordinate,
            }
          } else {
            addWayPoint.destination = {
              city: hereData.routeMarkers[1].content.city,
              zipCode: hereData.routeMarkers[1].content.zipCode,
              coordinate: hereData.routeMarkers[1].coordinate,
            }
          }
        }
        if (index > 0 && index === totalMovements - 1) {
          addWayPoint.origin = {
            city: hereData.routeMarkers[totalMovements]?.content.city,
            zipCode: hereData.routeMarkers[totalMovements]?.content.zipCode,
            coordinate: hereData.routeMarkers[totalMovements]?.coordinate,
          }
          addWayPoint.destination = {
            city: hereData.routeMarkers[1]?.content.city,
            zipCode: hereData.routeMarkers[1]?.content.zipCode,
            coordinate: hereData.routeMarkers[1]?.coordinate,
          }
        }
        if (index > 0 && index < totalMovements - 1) {
          addWayPoint.origin = {
            city: hereData.routeMarkers[index + 1]?.content.city,
            zipCode: hereData.routeMarkers[index + 1]?.content.zipCode,
            coordinate: hereData.routeMarkers[index + 1]?.coordinate,
          }
          addWayPoint.destination = {
            city: hereData.routeMarkers[index + 2]?.content.city,
            zipCode: hereData.routeMarkers[index + 2]?.content.zipCode,
            coordinate: hereData.routeMarkers[index + 2]?.coordinate,
          }
        }
        spotPoints.push(addWayPoint)
        return spotPoints
      })
      rateData.totalStops = hereData.stopMarkers.length
      rateData.totalMilles = totalMilles
      dispatch(saveTotalMilles(totalMilles, rateData.totalStops, spotPoints))
      dispatch(requestGetRate(rateData))
      dispatch(clearGetRouteData())
    }
  }, [dispatch, success, routeData, params, hereData.stopMarkers, hereData.routeMarkers])

  useEffect(() => {
    if (
      routeData.success &&
      !routeData.fetching &&
      routeData.data.notices &&
      routeData.data.notices.length > 0 &&
      routeData.data.routes &&
      routeData.data.routes.length < 1
    ) {
      Swal.fire({
        icon: 'error',
        title: 'There are not available routes for these points',
      })
    }
  }, [success, routeData])

  useEffect(() => {
    if (rateGenerated) {
      setTimeout(() => {
        setRateGenerated(false)
      }, 4200)
    }
  }, [rateGenerated])

  useEffect(() => {
    if (successSaveRate) {
      dispatch(clearCreateRate())
      dispatch(clearGetZipHere())
      dispatch(finishRate())
      dispatch(clearSpecialServices())
      dispatch(removeWayPoint([]))
      dispatch(requestSingleCustomer(defaultCustomer))
      setValues({ type: 'remove-special-services' })
      setDeliveryDate(false)
      form.setFieldsValue({ ...defaultValues, customer: defaultCustomer.value })
      dispatch(clearRate())
      history.push('/dashboard')
    }
  }, [successSaveRate, dispatch, defaultCustomer, form, defaultValues, history])

  const handleFinish = (values) => {
    setRateGenerated(true)
    const pickupDateFormated = moment(values.pickup).format('YYYY/MM/DD')
    // eslint-disable-next-line no-extra-boolean-cast
    const deliveryDay = !!values.delivery
      ? moment(values.delivery).format('YYYY/MM/DD')
      : moment(pickupDateFormated).add(3, 'days').format('YYYY/MM/DD')
    form.setFieldsValue({
      delivery: moment(deliveryDay),
    })

    const rateParams = {
      customer: singleCustomer.id ?? defaultCustomer.id,
      lane: JSON.stringify(laneData.lane),
      pickupDate: pickupDateFormated,
      deliveryDate: deliveryDay,
      truckTypeId: values.truckTypeId,
      weight: weightController,
    }
    if (!singleCustomer.id) {
      dispatch(requestSingleCustomer(defaultCustomer))
    }

    if (savedServices.data.length > 0) rateParams.specialServices = JSON.stringify(savedServices.data)
    let routeMarkers = []
    if (hereData.stopMarkers === 0) routeMarkers = [...hereData.markers]
    else routeMarkers = [...hereData.markers, ...hereData.stopMarkers]
    values.delivery = moment(deliveryDay)
    dispatch(saveRouteMarkers(routeMarkers)) 
    dispatch(
      saveRate(
        { ...values, weight: weightController },
        savedServices.data,
        singleCustomer.id ?? defaultCustomer.id
      )
    )
    let routeParams = ''
    routeParams += `origin=${hereData.markers[0].coordinate.lat},${hereData.markers[0].coordinate.lng}`
    routeParams += `&destination=${hereData.markers[1].coordinate.lat},${hereData.markers[1].coordinate.lng}`

    hereData.stopMarkers.map((item) => {
      routeParams += `&via=${item.coordinate.lat},${item.coordinate.lng}`
      return routeParams
    })
    dispatch(requestGetRouteData({ routeParams }))
    setValues({ type: 'rate-params', params: rateParams })
    const stops = []
    hereData.stopMarkers.map((entry) => stops.push(entry.content.city))

    dispatch(
      saveSendEmail({
        customer: singleCustomer.value ?? defaultCustomer.value,
        customerEmail: singleCustomer.email ?? defaultCustomer.email,
        pickupDate: moment(values.pickup).format('YYYY/MM/DD'),
        deliveryDate: moment(values.delivery).format('YYYY/MM/DD'),
        origin,
        destination,
        equipmentType: truckTypes.find((entry) => entry._id === values.truckTypeId).name,
        weight: weightController,
        totalSpecialServices: savedServices.data.length,
        stops,
      })
    )
    if (successZip && Object.keys(dataZip).length > 0 && dataZip.wayPoint) {
      let isValid = true
      wayPoints.map((item) => {
        if (item.city === dataZip.wayPoint.city) isValid = false
        return isValid
      })
      if (isValid) dispatch(addSpotWay(dataZip))
    }
  }

  useEffect(() => {
    const active = matchServices(services, savedServices.data)
    setSpecialServicesState(active)
  }, [dispatch, savedServices.data, services])

  const handleAddService = () => {
    const actualQuantity = currentQuantity === '' ? form.getFieldValue('quantity') : currentQuantity

    const tags = specialServicesState.map((tag) => {
      if (tag.name === currentSpecialService.name) {
        return { ...tag, quantity: actualQuantity + 1 }
      }

      return tag
    })
    const currentTag = specialServicesState.find((tag) => tag.name === currentSpecialService.name)
    if (currentTag) {
      setSpecialServicesState(tags)
    } else {
      setSpecialServicesState([...specialServicesState, { ...currentSpecialService, quantity: actualQuantity }])
    }

    if (currentSpecialService !== undefined) {
      setValues({
        type: 'addService',
        payload: { id: currentSpecialService.id, quantity: actualQuantity },
      })
      form.setFieldsValue({ services: null, quantity: defaultValues.quantity })
    }
  }

  const handleServiceUpdate = (updatedServices) => {
    setValues({ type: 'remove-special-services' })
    updatedServices.forEach((service) => {
      setValues({
        type: 'addService',
        payload: { id: service.id, quantity: service.quantity },
      })
    })
  }

  const mathHandler = (index) => {
    const newData = [...specialServicesState]
    const service = { ...specialServicesState[index] }

    service.quantity = Number(service.quantity) - 1
    if (service.quantity >= 1) {
      newData[index] = service
    } else {
      newData.splice(index, 1)
    }

    setSpecialServicesState(newData)
    dispatch(updateSpecialService(newData))
    handleServiceUpdate(newData)
  }

  const handleCloseTag = (removedTag, index) => {
    mathHandler(index)
  }

  const handleReset = () => {
    dispatch(clearGetRate())
    dispatch(clearCreateRate())
    dispatch(clearGetZipHere())
    dispatch(resetRate())
    dispatch(finishRate())
    dispatch(clearSpecialServices())
    dispatch(removeWayPoint([]))
    dispatch(requestSingleCustomer(defaultCustomer))
    setValues({ type: 'remove-special-services' })
    setDeliveryDate(false) 
    form.setFieldsValue({ ...defaultValues, customer: defaultCustomer.value })
    history.replace({ pathname: location.pathname, state: {} })
  }

  const onPickupDateChange = () => {
    setDeliveryState(false)
    setPickupDate(form.getFieldValue('pickup'))
  }

  const onDeliveryDateChange = () => {
    setDeliveryDate(form.getFieldValue('delivery'))
  }

  const disabledDeliveryDates = (current) => {
    return current && current < pickupDate
  }

  const disabledPickupDates = (current) => {
    if (deliveryDate) {
      return current && (current > deliveryDate || current < moment().subtract(3, 'months'))
    }
    return current < moment().subtract(3, 'months')
  }

  return (
    <Box height="auto" title="Lane Criteria" backgroundColor>
      <ModalRates messageBody="Please wait while the new rate is applied!" visible={rateGenerated} />
      {message === '' ? null : (
        <ModalErrorRate
          messageBody={message}
          visible={openError}
          onCancel={closeModalCleanRate}
          CustomBody={BodyError2}
        />
      )}
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="createRate"
        id="createRate"
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <FloatLabel label="Customer" show>
              <FormItem name="customer" rules={requiredSimple}>
                <CustomSelect
                  onSearch={(value) => {
                    dispatch(requestGetAllCustomer({ text: value, active: true }))
                  }}
                  showSearch
                  placeholder="Customer"
                  onChange={(value, entry) => {
                    dispatch(requestSingleCustomer(entry))
                  }}
                  disabled={fetching || editRate}
                  suffixIcon={<ArrowDownIcon />}
                  options={totalCustomer}
                />
              </FormItem>
            </FloatLabel>
          </Col>
          <Container>
            <Col span={12}>
              <FloatLabel label="Pick up Date" show>
                <FormItem name="pickup" rules={requiredSimple}>
                  <InputDatePicker
                    style={{ width: '100%', paddingLeft: '12px' }}
                    allowClear
                    placeholder="Pickup date"
                    disabled={fetching || editRate}
                    onChange={onPickupDateChange}
                    disabledDate={disabledPickupDates}
                    suffixIcon={<AiTwotoneCalendar />}
                    format="MM/DD/YYYY"
                  />
                </FormItem>
              </FloatLabel>
            </Col>
            <Col span={12}>
              <FloatLabel label="Delivery Date" show>
                <FormItem name="delivery">
                  <InputDatePicker
                    style={{ width: '100%', paddingLeft: '12px' }}
                    allowClear
                    placeholder="Delivery date"
                    disabled={fetching || deliveryState || editRate}
                    disabledDate={disabledDeliveryDates}
                    onChange={onDeliveryDateChange}
                    suffixIcon={<AiTwotoneCalendar />}
                    format="MM/DD/YYYY"
                  />
                </FormItem>
              </FloatLabel>
            </Col>
          </Container>

          <Form.List name="names">
            {(fields, { add, remove, move }) => (
              <>
                <CitiesList>
                  <CityFormItem
                    isDragDisabled={editRate}
                    fields={fields}
                    remove={remove}
                    move={move}
                    requiredSimple={requiredSimple}
                    setPlaceDestination={setPlaceDestination}
                    dataDestination={dataDestination}
                    fetching={fetching}
                    editRate={editRate}
                  />
                </CitiesList>

                <Col
                  style={{
                    display: 'flex',
                    alignSelf: 'end',
                    marginTop: '-10px',
                    marginBottom: '10px',
                    width: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <AddCityButton htmlType="button" onClick={() => add()} disabled={fields.length === 6}>
                    <BsFillPlusSquareFill style={{ marginRight: '3px' }} /> Add another city
                  </AddCityButton>
                </Col>
              </>
            )}
          </Form.List>
          <Container>
            <Col span={12}>
              {truckTypes.length > 0 && (
                <FloatLabel label="Equipment type" show>
                  <FormItem
                    name="truckTypeId"
                    initialValue={truckTypes.find((entry) => entry.name === 'Van')?._id}
                    rules={requiredSimple}
                  >
                    <StyledSelect
                      style={{ width: '100%', borderRadius: '4px' }}
                      placeholder="Select"
                      showSearch
                      suffixIcon={<ArrowDownIcon />}
                      disabled={fetching || editRate}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {truckTypes.map((entry) => (
                        <Select.Option key={entry._id} value={entry._id}>
                          {entry.name}
                        </Select.Option>
                      ))}
                    </StyledSelect>
                  </FormItem>
                </FloatLabel>
              )}
            </Col>
            <Col span={12}>
              <FloatLabel label="Weight" show>
                <FormItem name="weight" rules={requiredWeight}>
                  <InputNumber
                    style={{ width: '100%', borderRadius: '4px' }}
                    value={weightController}
                    onChange={(value) => {
                      setWeight(value)
                    }}
                    disabled={fetching || editRate}
                  />
                </FormItem>
              </FloatLabel>
            </Col>
          </Container>
          <SpecialService>
            <Col span={12}>
              <LabelForm>Add special services</LabelForm>
            </Col>
            <Container>
              <Col span={12} style={{ width: '160px' }}>
                <FloatLabel label="Special Services" show>
                  <FormItem name="services">
                    <CustomSelect
                      style={{ width: '100%', color: '#545454' }}
                      onSearch={(value) => {
                        dispatch(requestGetSpecialServices({ text: value, stops: 'false' }))
                      }}
                      showSearch
                      placeholder="Select a service"
                      onChange={() => setValues({ type: 'clearSpecial' })}
                      onSelect={(value, entry) => {
                        setValues({
                          type: 'addCurrentService',
                          payload: { data: entry, quantity: currentQuantity },
                        })
                      }}
                      options={services.filter((service) => service.active === true)}
                      disabled={fetching || editRate}
                      suffixIcon={<ArrowDownIcon />}
                    />
                  </FormItem>
                </FloatLabel>
              </Col>
              <Col span={10}>
                <FloatLabel label="Quantity" show>
                  <FormItem name="quantity" rules={requiredQuantity}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={currentQuantity}
                      min={1}
                      max={99}
                      disabled={fetching || editRate || currentSpecialService === undefined}
                      onChange={(value) => setValues({ type: 'addCurrentQuantity', payload: { data: value } })}
                    />
                  </FormItem>
                </FloatLabel>
              </Col>
              <Col span={2}>
                <FormItem>
                  <Tooltip title="Add a new special service">
                    <AButton
                      type="primary"
                      size="small"
                      style={{ backgroundColor: '#3594FF', borderRadius: '4px' }}
                      disabled={currentSpecialService === undefined || currentQuantity === null}
                      onClick={() => handleAddService()}
                      icon={
                        <AiOutlinePlus
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </FormItem>
              </Col>
            </Container>
          </SpecialService>
          <Col span={24}>
            <LabelForm>Special services added</LabelForm>
            <SpecialServiceList>
              {specialServicesState.map((item, index) => (
                <StyledTag>
                  <div style={{ display: 'flex' }}>
                    <span
                      style={{
                        width: '100px',
                        overflow: 'hidden',
                        display: 'inline-block',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.name}.
                    </span>
                    ({item.quantity})
                  </div>
                  <IoMdClose
                    onClick={() => handleCloseTag(item, index)}
                    style={{
                      backgroundColor: '#bdc8d5',
                      color: '#002555',
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                  />
                </StyledTag>
              ))}
            </SpecialServiceList>
          </Col>
        </Row>
      </Form>
      <Row justify="end" gutter={16} style={{ marginTop: '24px' }}>
        <Col>
          <ButtonQuaternary onClick={handleReset}>Cancel</ButtonQuaternary>
        </Col>
        <Col>
          <Button type="primary" form="createRate" htmlType="submit" disabled={fetching}>
            {fetching ? <SpinLoading /> : 'Get Rate'}
          </Button>
        </Col>
      </Row>
    </Box>
  )
}

export default GetRates
