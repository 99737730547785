import axios from 'axios'
import queryString from 'querystring'

export default function (api) {
  return {
    getDatHistory: (body) => api.get('api/dat/history-rate', { params: { ...body } }),
    getAutocompleteHere: (params) => {
      return axios.get(process.env.REACT_APP_HERE_MAPS_URL_AUTOSUGGEST, {
        params: {
          q: params,
          at: '40.71455,-74.00714',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
          in: 'countryCode:USA',
          resultTypes: 'locality',
          limit: 6,
        },        
      })
    },
    getRouteData: async (params) => {
      const response = await axios.get(
        `${process.env.REACT_APP_HERE_MAPS_ROUTE_URL}?transportMode=truck&apiKey=${process.env.REACT_APP_HERE_MAPS_KEY}&${params.routeParams}&return=summary`
      )
      return response
    },
    getZipHere: (entry) =>
      axios.get(process.env.REACT_APP_HERE_MAPS_URL, {
        params: {
          q: entry,
          country: 'USA',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
        },
      }),
    getAllZipHeres: async (entry) => {
      const promiseArray = entry.map(element => axios.get(process.env.REACT_APP_HERE_MAPS_URL, {
        params: {
          q: element.value,
          country: 'USA',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
        },
      }))
      const results = await axios.all(promiseArray)
      return results
    },
    getCityFromZip: async ( entry ) => {
      const payload = {
        app_id: process.env.REACT_APP_HERE_MAPS_APP_ID,
        app_code: process.env.REACT_APP_HERE_MAPS_APP_CODE,
        query: `${entry} usa`,
        country: 'usa'
      };
      const response = await axios.get(`${process.env.REACT_APP_HERE_v6_MAPS_URL_AUTOSUGGEST}${queryString.stringify(payload)}`)
      return response 
    }
  }
}

