import moment from 'moment'
import {
  RULE_DATE_RANGE,
  RULE_GEOGRAPHIC,
  RULE_LENGTH,
  RULE_SIMPLE,
  MULTI_STEPS,
  RULE_SETS,
  SPECIAL_SERVICE,
  TRUCK_TYPE,
  EQUIPMENT_TYPE,
  RULE_MARKET,
} from 'utils/constants'

export const cleanUserName = (userName = '') => {
  return userName
    .trim()
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
}

const labels = {
  margin: 'Percentage',
  flat: 'Flat',
  status: 'Status',
  name: 'Name',
  typeRuleId: 'Category',
  crossRule: 'Master Rule',
}
const labelsFormEquipmentType = {
  ...labels,
  typeReferenceEquipmentId: 'Type',
}

const labelsForm = {
  truckTypeId: 'Equipment Type',
  datAvg: 'DAT 7 days spot AVG',
  low: 'LOW',
  high: 'HIGH',
  contract: 'Contract',
  itsAvg: 'ITS spot AVG',
  sonarAvg: 'Sonar AVG',
}

const labelsFormGeographic = {
  ...labels,
  typeShippingId: 'Type',
  typeReference: 'Category',
  guide: 'State',
}

const labelsFormDateRange = {
  typeShippingId: 'Type',
  ...labels,
  dateInit: 'Start date',
  dateEnd: 'End date',
  description: 'Description',
}

const labelsFormLength = {
  mileageInit: 'Start mileage',
  mileageEnd: 'End mileage',
  ...labels,
}

const labelsFormSimple = {
  typeShippingId: 'Type',
  day: 'Day',
  ...labels,
}

const labelsFormMultiSteps = {
  multistepName: 'Multi Step-Rule´s name',
  margin: 'Percentage',
  flat: 'Flat',
  sentences: 'Rules',
  status: 'Status',
}

const labelsFormRuleSets = {
  customers: 'Customer Company',
  multisteps: 'Multi-Steps',
  rules: 'Rules',
  rulesetName: 'RuleSets Name',
  status: 'Status',
}

const labelsFormSpecialService = {
  name: 'Name',
  description: 'Description',
  price: 'Price',
  stops: 'Way points',
  status: 'Status',
}

const labelsFormTruckType = {
  name: 'Name',
  description: 'Description',
  price: 'Price',
  status: 'Status',
}
const labelsFormMarket = {
  typeShippingId: 'Type',
  market: 'Market',
  ...labels,
}

const isValidIsoDate = (dateTime = '') => {
  const utc = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true)
  return utc.isValid()
}

const toHumanFormat = (dateTime = '') => {
  const time = new Date(dateTime)
  const date = `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear()}`
  const hoursDay = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return `${date} ${hoursDay}`
}

export const toHumanDate = (dateTime = '') => {
  return isValidIsoDate(dateTime) ? toHumanFormat(dateTime) : ''
}

const selectCategory = (category = '') => {
  const categories = {
    [RULE_GEOGRAPHIC]: labelsFormGeographic,
    [RULE_DATE_RANGE]: labelsFormDateRange,
    [RULE_LENGTH]: labelsFormLength,
    [RULE_SIMPLE]: labelsFormSimple,
    [MULTI_STEPS]: labelsFormMultiSteps,
    [RULE_SETS]: labelsFormRuleSets,
    [SPECIAL_SERVICE]: labelsFormSpecialService,
    [TRUCK_TYPE]: labelsFormTruckType,
    [EQUIPMENT_TYPE]: labelsFormEquipmentType,
    [RULE_MARKET]: labelsFormMarket,
  }
  return categories[category] ?? labelsForm
}

const matchLabelValue = (editedFields = [], category = '') => {
  return editedFields.filter((el) => selectCategory(category)[el]).map((el) => selectCategory(category)[el])
}

const allChanges = (editedFields = [], category = '') => {
  return ` Change the ${matchLabelValue(editedFields, category).join(', ')}.`.replace(/,\./g, '.')
}

const transformText = (action = '') => {
  const validParam = action ?? ''
  if (validParam === '') {
    return validParam
  }
  const labelsActions = {
    Edit: 'Edited',
    Create: 'Created',
    Change: 'Changed',
    Deactivated: 'Blocked',
  }
  return labelsActions[validParam] ?? action
}

export const generateTitle = (action = '', editedFields = [], category = '') => {
  const fieldsEdited = action === 'Edit' ? allChanges(editedFields, category) : ''
  return `has ${transformText(action)} this Rate Source.${fieldsEdited}`
}
