import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'
import { editFormat } from 'utils/dateManager'
import { formatLineGraph } from 'utils/formatGraph'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rate: {},
  params: {},
  years: [],
  graphics: [],
  mciGraphics: {},
  filter: { id: [], year: null },
}

export const {
  requestGetRate,
  successGetRate,
  failureGetRate,
  clearGetRate,
  filterGraphics,
  setGraphics,
} = createActions({
  REQUEST_GET_RATE: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_RATE: (rate) => ({
    rate,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RATE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RATE: () => ({
    ...defaultState,
  }),
  FILTER_GRAPHICS: (filter) => ({
    filter,
  }),
  SET_GRAPHICS: () => ({}),
})

const getRate = handleActions(
  {
    [requestGetRate]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetRate]: produce((draft, { payload: { message, fetching, success, rate, error } }) => {
      const formatData = (element) => {
        const newData = []
        const newElement = { ...element }
        element.data.forEach((e) => {
          newData.push({ ...e, x: editFormat(e?.x) })
        })
        if (newData.length > 0) newElement.data = newData
        return newElement
      }

      const format = (arr) => {
        const clear = []
        arr.forEach((element) => {
          if (element.id === 'Dat') {
            const modify = formatData(element)
            clear.push({ ...modify, [element.id]: element.highest.ratePerMile })
          } else if (element.id !== 'Dat-Contract')
            clear.push({ ...element, [element.id]: element.highest.ratePerMile })
        })

        return clear
      }

      const clearGraph = rate?.graphics ? format(rate?.graphics) : []
      const clearMciGraph = rate?.marketConditions ? formatLineGraph(rate?.marketConditions) : {}
      const formatRate = { ...rate }
      formatRate.graphics = clearGraph
      formatRate.mciGraphics = clearMciGraph

      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rate = formatRate
      draft.graphics = clearGraph
      draft.mciGraphics = clearMciGraph
      if (rate.graphics.length > 0)
        draft.years.push(clearGraph[0].data[0].x.slice(0, 4), clearGraph[0].data.slice(-1).pop().x.slice(0, 4))
    }),
    [failureGetRate]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetRate]: produce((draft, { payload: { message, fetching, success, rate, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rate = rate
      draft.years = []
      draft.filter = {}
    }),
    [filterGraphics]: produce((draft, { payload: { filter } }) => {
      draft.filter = { ...draft.filter, ...filter }
    }),
    [setGraphics]: produce((draft) => {
      const arr = draft.rate.graphics
      const { id } = draft.filter
      const yeart = draft.filter?.year
      const respon = []
      for (const i of arr) {
        if (!id.find((element) => element === i.id) || i.id === null) continue
        const newObj = {
          id: i.id,
          data: [],
        }
        if (yeart) {
          for (const points of i.data) {
            if (points.x.substring(0, 4) !== yeart) continue
            newObj.data.push({ ...points })
          }
        } else {
          newObj.data = [...i.data]
        }
        respon.push(newObj)
      }
      draft.graphics = respon
    }),
  },
  defaultState
)

export default getRate
