import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { addRuleset } from 'store/duck/createRuleSet.duck'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import { clearUpdateRuleset, requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { clearDeleteRuleset } from 'store/duck/deleteRuleset.duck'
import { clearDeleteBulkRuleset, requestDeleteBulkRuleset } from 'store/duck/deleteBulkRuleset.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import notify from 'utils/notify'
import CreateRulesetModal from 'containers/rules/Ruleset/CreateRuleset'
import Table from 'components/Table'
import StyledSearch from 'components/Input/Search'
import FloatLabel from 'components/Label/FloatLabel'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { StyledItem, StyledOptions } from 'components/Form/FilterRules/styles'
import { BsPlusSquareFill } from 'react-icons/bs'
import { ArrowDownIcon } from 'assets/svg'
import { requestGetMasterRules } from 'store/duck/getMasterRules.duck'

import columns from './utils/TableColumns'

const ListRuleset = () => {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const dispatch = useDispatch()
  const history = useHistory()
  const [params, setParams] = useState({ text: '', page: 1, status: '' })
  const { data, fetching, success } = useSelector((state) => state.getRuleSets)
  const { success: successCreate } = useSelector((state) => state.createRuleSet)
  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.updateRuleset
  )
  const { data: rulesSets, totalRecords } = data
  const { successDelete, error: deleteUpdate, message: msmDelete } = useSelector((state) => state.deleteRuleset)
  const { successDeleteBulk, error: errorDeleteBulk, message: msmDeleteBulk } = useSelector(
    (state) => state.deleteBulkRuleset
  )
  const fetchedMasterRules = useSelector((state) => state.getMasterRules)

  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [rulesData, setRulesData] = useState(rulesSets)
  const tableColumns = columns(dispatch, history, setIsOpenCreateModal, fetchedMasterRules)
  const { success: successReturn, error: errorReturn, message: messageReturn } = useSelector(
    (state) => state.returnRuleset
  )

  useEffect(() => {
    setRulesData([])
    if (success) {
      setRulesData(rulesSets)
    }
  }, [dispatch, success, rulesSets])

  useEffect(() => {
    dispatch(requestGetRuleSets(params))
    dispatch(requestGetMasterRules())
  }, [dispatch, params])

  useEffect(() => {
    if (successCreate) {
      dispatch(requestGetRuleSets(params))
    }
  }, [dispatch, params, successCreate])

  useEffect(() => {
    if (successUpdate) {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
      dispatch(requestGetRuleSets(params))
      dispatch(clearUpdateRuleset())
    }

    if (errorUpdate) {
      notify('error', 'Error updating the ruleset.')
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateRuleset())
    }
  }, [successUpdate, errorUpdate, msmUpdate, dispatch, params, msgSnack])

  useEffect(() => {
    if (successDelete) {
      notify('success', 'RuleSet has been deleted')
      dispatch(requestGetRuleSets(params))
      dispatch(clearDeleteRuleset())
    }
    if (deleteUpdate) {
      notify('error', ` 'Error deleting the ruleset. ${msmDelete}`)
      dispatch(clearDeleteRuleset())
    }
  }, [successDelete, deleteUpdate, msmDelete, dispatch, params])

  useEffect(() => {
    if (successDeleteBulk) {
      notify('success', 'The rules has been deleted successfully!')
      dispatch(requestGetRuleSets(params))
      dispatch(clearDeleteBulkRuleset())
    }
    if (errorDeleteBulk) {
      notify('error', ` 'Error deleting the ruleset. ${msmDeleteBulk}`)
      dispatch(clearDeleteBulkRuleset())
    }
  }, [successDeleteBulk, errorDeleteBulk, msmDeleteBulk, dispatch, params])

  const handleCreateRulesetRule = () => {
    setIsOpenCreateModal(true)
    dispatch(addRuleset())
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const handleValuesChange = (values) => {
    const status = values.status ?? params.status
    setParams({ ...params, text: values.text ?? params.text, status: status !== 'All' ? status : undefined })
  }

  useEffect(() => {
    if (successReturn) {
      notify('success', messageReturn)
      dispatch(requestGetRuleSets(params))
    }

    if (errorReturn) {
      notify('error', messageReturn)
    }
  }, [successReturn, errorReturn, messageReturn, dispatch, params])

  return (
    <div style={{ width: '96%', margin: '10px auto 15px' }}>
      <Form
        layout="vertical"
        name="filter-ruleset-rules-form"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          status: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="status">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Activated" value="Activated">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Deactivated" value="Deactivated">
                    Blocked
                  </StyledOptions>
                  <StyledOptions key="Paused" value="Paused">
                    Paused
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="none">
            <Form.Item>
              <ButtonPrimary
                icon={<BsPlusSquareFill size="16px" />}
                height="40px"
                onClick={handleCreateRulesetRule}
              >
                New RuleSet
              </ButtonPrimary>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="space-between" gutter={[0, 32]} style={{ width: '100%' }}>
        <Col span={24}>
          <CreateRulesetModal visible={isOpenCreateModal} handleOnClose={setIsOpenCreateModal} />
          <Table
            columns={tableColumns}
            data={rulesData}
            loading={fetching}
            rowKey="_id"
            pagination={{ total: totalRecords ?? 0 }}
            updatedPagination
            dispatch={dispatch}
            onChange={handleTableChange}
            width="100%"
            padding="4px 0px"
            rowSelection
            status={params.status}
            deleteRequest={requestDeleteBulkRuleset}
            pauseRequest={requestUpdateRuleset}
            avoidNotify
            entityNameForBulk="RuleSet"
            addHeight={-30}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ListRuleset
