import { saveStorage } from 'utils/storage'

export default function (api) {
  return {
    loginUser: (data) => api.post('api/authentications/login', data),
    activeAccount: (data) => api.post('api/authentications/reset-password', data),
    resetPassword: (data) => api.post('api/authentications/reset-password', data),
    forgotPassword: (data) => api.get(`api/authentications/forgot-password/${data.email}`),
    refreshToken: () => {
      const refreshToken = window.localStorage.getItem('altprtk')
      if (refreshToken !== null)
        return api.post('/api/authentications/token', { token: refreshToken }).then((resp) => {
          saveStorage(resp.token, 'altpntk')
        })
      else return null
    },
  }
}
