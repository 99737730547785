/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { Form, Col } from 'antd'
import { MdLocationOn } from 'react-icons/md' 
import FloatLabel from 'components/Label/FloatLabel'
import FormItem from 'antd/lib/form/FormItem'
import { removeStopMarkers, requestGetZipHere, saveRouteMarkers, updateMarkers } from 'store/duck/getZipHere.duck'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DragIndicator } from 'assets/svg' 
import CustomSelect from 'components/CustomSelect'
import { CityPoint, ContainerItem, StyledSpace } from './styles'

const CityFormItem = ({
  fields,
  remove,
  requiredSimple,
  setPlaceDestination,
  dataDestination,
  fetching,
  editRate,
  move,
  isDragDisabled = false,
}) => {
  const dispatch = useDispatch()
  const { stopMarkers, markers } = useSelector((state) => ({
    stopMarkers: state.getZipHere.stopMarkers,
    markers: state.getZipHere.markers,
  }))
  const [cities, setCities] = useState([...fields])
  const alphabet = 'ABCDEF'

  useEffect(() => {
    setCities([...fields])
  }, [fields])

  const handleSelectPoint = (value, index) => {
    dispatch(requestGetZipHere(value, index))
  }

  const handleRemoveCity = (name, position) => {
    let sortMarkers = markers[0] ? [markers[0]] : []
    stopMarkers.forEach((element) => {
      sortMarkers.push(element)
    })
    if (markers[1]) sortMarkers.push(markers[1])
    sortMarkers.splice(position, 1)

    sortMarkers = sortMarkers.map((item, index) => {
      let modifiedItem = { ...item }
      if (index === 0) {
        modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Origin' } }
        return modifiedItem
      }
      if (index === sortMarkers.length - 1) {
        modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Destination' } }
        return modifiedItem
      }
      modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Way Point' } }
      return modifiedItem
    })
    const markrs = sortMarkers.filter((item) => item.content.type !== 'Way Point')
    const stops = sortMarkers.filter((item) => item.content.type === 'Way Point')
    dispatch(updateMarkers(markrs))
    dispatch(removeStopMarkers(stops))
    dispatch(saveRouteMarkers(sortMarkers))
    remove(name)
  }
 

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result
        if (!destination) {
          return
        }
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
          return
        }
        const startIndex = source.index
        const endIndex = destination.index
        let sortMarkers = markers[0] ? [markers[0]] : []
        stopMarkers.forEach((element) => {
          sortMarkers.push(element)
        })
        if (markers[1]) sortMarkers.push(markers[1])
        let getItem = { ...sortMarkers[startIndex] }
        if (endIndex === 0) {
          getItem = { ...getItem, content: { ...getItem.content, type: 'Origin' } }
        }
        if (endIndex === sortMarkers.length - 1) {
          getItem = { ...getItem, content: { ...getItem.content, type: 'Destination' } }
        } else {
          getItem = { ...getItem, content: { ...getItem.content, type: 'Way Point' } }
        }

        sortMarkers.splice(startIndex, 1)
        sortMarkers.splice(endIndex, 0, getItem)
        sortMarkers = sortMarkers.map((item, index) => {
          let modifiedItem = { ...item }
          if (index === 0) {
            modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Origin' } }
            return modifiedItem
          }
          if (index === sortMarkers.length - 1) {
            modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Destination' } }
            return modifiedItem
          }
          modifiedItem = { ...modifiedItem, content: { ...modifiedItem.content, type: 'Way Point' } }
          return modifiedItem
        })
        const markrs = sortMarkers.filter((item) => item.content.type !== 'Way Point')
        const stops = sortMarkers.filter((item) => item.content.type === 'Way Point')

        dispatch(updateMarkers(markrs))
        dispatch(removeStopMarkers(stops))
        dispatch(saveRouteMarkers(sortMarkers))
        move(source.index, destination.index)
      }}
    >
      <Droppable droppableId="cities">
        {(droppableProvided) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ width: '100%' }}>
            {cities.map((field, index) => (
              <Draggable
                key={field.key}
                draggableId={field.key.toString()}
                index={index}
                isDragDisabled={isDragDisabled}
              >
                {(draggableProvided) => (
                  <StyledSpace style={{ width: '100%', display: 'flex' }}>
                    <Form.Item noStyle shouldUpdate={() => null}>
                      {() => (
                        <Form.Item {...field} style={{ marginBottom: 0 }}>
                          <ContainerItem
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Col
                              span={2}
                              style={{ paddingLeft: '12px', alignSelf: 'center', justifyContent: 'center' }}
                            >
                              <DragIndicator fill="#3594FF" />
                            </Col>
                            <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                              <CityPoint>{alphabet[index]}</CityPoint>
                            </Col>
                            <Col span={17}>
                              <FloatLabel label="City" show style={{ marginBottom: '0px' }}>
                                <FormItem name={field.name} rules={requiredSimple}>
                                  <CustomSelect
                                    style={{
                                      width: '100%',
                                      borderRadius: '4px',
                                      paddingLeft: '0px',
                                      paddingTop: '4px',
                                    }}
                                    onSearch={setPlaceDestination}
                                    // options={AlphabethicSort(dataDestination, 'value')}
                                    options={dataDestination}
                                    showSearch
                                    value={cities[index]}
                                    onChange={(value) => handleSelectPoint(value, index)}
                                    disabled={fetching || editRate}
                                    placeholder={
                                      <>
                                        <MdLocationOn style={{ color: '#002555' }} />
                                        &nbsp; City
                                      </>
                                    }
                                    defaultActiveFirstOption={false}
                                    notFoundContent={null}
                                    suffixIcon={null}
                                  />
                                </FormItem>
                              </FloatLabel>
                            </Col>
                            <Col span={1} style={{ marginLeft: '10px' }}>
                              <IoMdTrash
                                style={{
                                  cursor: 'pointer',
                                  color: '#3594FF',
                                }}
                                onClick={() => {
                                  handleRemoveCity(field.name, index)
                                }}
                              />
                            </Col>
                          </ContainerItem>
                        </Form.Item>
                      )}
                    </Form.Item>
                  </StyledSpace>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default CityFormItem
