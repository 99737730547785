/**
 *
 * @param {*} obj property datalakeTable from the getRate
 * @param {*} filter one of the 3 posibles options to filter (items, outlierItems or empty)
 * @returns
 */

const filterDatalake = (obj = {}, filter = '') => {
  const filteredObject = []
  if (filter === '') {
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        obj[key].forEach((element) => {
          filteredObject.push(element)
        })
      }
    }
    return filteredObject
  }

  if (Object.hasOwnProperty.call(obj, filter)) {
    obj[filter].forEach((element) => {
      filteredObject.push(element)
    })
  }

  return filteredObject
}

export default filterDatalake
