import React from 'react'
import { useHistory } from 'react-router-dom'
import TitleList from 'components/TitleList'
import Tabs from 'antd/lib/tabs'
import TrustPercentageList from 'containers/settings/TrustPercentageList'
import ListMultiStep from 'containers/rules/ListMultiStep'
import ListRuleset from 'containers/rules/Ruleset/ListRuleset'
import ListRules from '../ListRules/ListRules'
import { StyledDiv, StyledTabs } from './styles'

export default function TabsRules() {
  const history = useHistory()
  const handleTabClick = (key) => {
    history.push(`/${key}`)
  }

  return (
    <>
      <StyledDiv>
        <TitleList title="Rules" />
      </StyledDiv>
      <StyledTabs
        defaultActiveKey="history"
        onChange={handleTabClick}
        tabBarStyle={{ width: '96%', margin: '0px auto', color: '#002555', fontWeight: 600, marginBottom: 15 }}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Rate Source ‘Weighting’" key="rules/rate-source-weight">
          <TrustPercentageList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rules" key="rules/list">
          <ListRules />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Multi-Step Rules" key="rules/multi-step">
          <ListMultiStep />
        </Tabs.TabPane>
        <Tabs.TabPane tab="RuleSets" key="rules/ruleset">
          <ListRuleset />
        </Tabs.TabPane>
      </StyledTabs>
    </>
  )
}
