/* eslint-disable react/destructuring-assignment */
import React from 'react'
import StyledSelect from 'components/Input/Select'
import { AlphabethicSort } from 'utils/AlphabeticSort'

/**
 * 
 * @param {*} props All props what it can receive Select from Ant-design
 * @param {*} disableSort Boolean value to avoid the default Alphabethic Sort
 * @param {*} keySort string to indicate a diferent key to evaluate the Alphabethic Sort
 * @returns 
 */
export default function CustomSelect(props) {
  const key = props.keySort ?? 'value'
  const options = React.useMemo(() => AlphabethicSort(props?.options, key), [props,key])
  const newProps = { ...props }
  if (!props.disableSort && props?.options) {
    newProps.options = options
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledSelect {...newProps} />
}
