import React, { useState, useEffect } from 'react'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import { useDispatch } from 'react-redux'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import { requestDefaultCustomer } from 'store/duck/defaultCustomer.duck'

const UserActivateSwitch = ({ status, customerId, title }) => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(status)

  useEffect(() => {
    setActive(status)
  }, [status])

  const handleOnClick = (checked, event) => {
    event.stopPropagation()
    dispatch(requestDefaultCustomer(customerId))
    dispatch(requestGetCustomers({ page: 1 }))
    dispatch(requestGetCustomers({ page: 1 }))

    setActive(status)
  }

  return (
    <Tooltip title={title}>
      <Switch checked={active} onClick={handleOnClick} />
    </Tooltip>
  )
}

export default UserActivateSwitch
