import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Swal from 'sweetalert2'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { batch, useDispatch, useSelector } from 'react-redux'
import { addMultiStep } from 'store/duck/createMultiStep.duck'
import { requestGetMultiSteps, clearGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetRules } from 'store/duck/getRules.duck'
import { clearDeleteMultiStep, requestDeleteMultiStep } from 'store/duck/deleteMultiStep.duck'
import { clearUpdateMultiStep } from 'store/duck/updateMultiStep.duck'
import { requestUpdateStatusMultiStep, clearUpdateStatusMultiStep } from 'store/duck/updateStatusMultiStep.duck'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import FloatLabel from 'components/Label/FloatLabel'
import ModalDeleteRules from 'components/ModalDeleteRules'
import { StyledItem, StyledOptions } from 'components/Form/FilterRules/styles'
import StyledSearch from 'components/Input/Search'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { BsPlusSquareFill } from 'react-icons/bs'
import { ArrowDownIcon } from 'assets/svg'
import notify from 'utils/notify'
import Table from 'components/Table'
import MultiStepColumns from './utils/MultiStepColumns'
import RulesContainer from '../styles'
import CreateMultistepRuleModal from './utils/CreateMultistepRule'
import { ShowItems } from './utils/ShowItems'

const ListMultiStep = () => {
  const { xl } = useBreakpoint()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [params, setParams] = useState({ text: '', page: 1, status: '' })
  const { multiSteps, success, error, fetching, totalRecords } = useSelector((state) => state.getMultiSteps)
  const { successDelete, message: messageDeleteMulti, fetching: fetchingDelete } = useSelector(
    (state) => state.deleteMultiStep
  )
  const { success: successCreate } = useSelector((state) => state.createMultiStep)
  const { message, successUpdateStatus, error: errorUpdateStatusMultiStep } = useSelector(
    (state) => state.updateStatusMultiStep
  )
  const { successUpdate } = useSelector((state) => state.updateMultiStep)
  const { success: returnVersion } = useSelector((state) => state.returnVersionMultistep)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [rulesData, setRulesData] = useState(multiSteps)
  const [openDelete, setOpenDelete] = useState(false)

  const handleValuesChange = (values) => {
    const status = values.status ?? params.status
    setParams({ ...params, text: values.text ?? params.text, status: status !== 'All' ? status : undefined })
  }

  const handleCreateMultistepRule = () => {
    setIsOpenCreateModal(true)
    dispatch(addMultiStep())
  }

  useEffect(() => {
    dispatch(requestGetMultiSteps(params))
  }, [params, dispatch])

  useEffect(() => {
    setRulesData([])
    if (success) {
      setRulesData(multiSteps)
    }
  }, [dispatch, success, multiSteps])

  useEffect(() => {
    if (successCreate || successUpdate || successUpdateStatus || returnVersion) {
      dispatch(requestGetMultiSteps(params))
    }
    if (successUpdate) {
      dispatch(clearUpdateMultiStep())
    }
    if (successUpdateStatus) {
      dispatch(clearUpdateStatusMultiStep())
    }
    if (successDelete) {
      setOpenDelete(false)
      notify('success', 'Rules has been deleted')
      dispatch(clearDeleteMultiStep())
      dispatch(requestGetMultiSteps(params))
      dispatch(requestGetRuleSets())
    }
    if (errorUpdateStatusMultiStep) {
      notify('error', message)
    }
  }, [
    params,
    dispatch,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    errorUpdateStatusMultiStep,
    message,
    returnVersion,
  ])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error getting multi-step rules!',
      })
      dispatch(clearGetMultiSteps())
    }
    if (messageDeleteMulti) {
      notify('error', messageDeleteMulti)
      dispatch(clearDeleteMultiStep())
    }
  }, [dispatch, error, messageDeleteMulti])

  useEffect(() => {
    dispatch(requestGetRules())
  }, [dispatch])

  const [expandedRowKeys, setExpandedKey] = useState('')

  const onExpand = (key) => {
    setExpandedKey((prev) => {
      const newKey = key._id
      return prev !== newKey ? newKey : null
    })
  }

  const columns = useMemo(() => MultiStepColumns(dispatch, setIsOpenCreateModal, onExpand, expandedRowKeys), [
    dispatch,
    expandedRowKeys,
  ])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const showModalDelete = () => {
    setOpenDelete(true)
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
  }
  const [rulesToDelete, setRulesToDelete] = useState({ rules: [], multiStep: [] })
  const handleData = useCallback(
    (rows) => {
      setRulesToDelete({
        rules: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((sen) => [...sen?.rulesetsInfo?.ruleSetsNames]),
        multiStep: rows,
      })
    },
    [rulesData]
  )

  const textBody = () => (
    <div style={{ fontWeight: 400, fontSize: '18px', textAlign: 'center' }}>
      Are you sure you want to delete this <span style={{ fontWeight: 700 }}>Multi-Step Rule</span>? If you accept,
      the rule will be removed from the following RuleSets as well:
    </div>
  )

  const multipleDelete = () => {
    batch(() => {
      rulesToDelete.multiStep.map((id) => dispatch(requestDeleteMultiStep(id)))
    })
  }

  return (
    <RulesContainer>
      <Form
        layout="vertical"
        name="filter-multistep-rules-form"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          status: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="status">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Activated" value="Activated">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Deactivated" value="Deactivated">
                    Blocked
                  </StyledOptions>
                  <StyledOptions key="Paused" value="Paused">
                    Paused
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="none">
            <Form.Item>
              <ButtonPrimary
                icon={<BsPlusSquareFill size="16px" />}
                height="40px"
                onClick={handleCreateMultistepRule}
              >
                New Multi-Step Rule
              </ButtonPrimary>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CreateMultistepRuleModal visible={isOpenCreateModal} onCloseModal={setIsOpenCreateModal} />
      <Table
        columns={columns}
        data={rulesData}
        loading={fetching}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRecords }}
        updatedPagination
        onChange={handleTableChange}
        rowSelection
        dispatch={dispatch}
        onExpand={onExpand}
        expandedRowKeys={expandedRowKeys}
        onRow={(_) => {
          return [expandedRowKeys].includes(_._id) && { className: 'expand-parent' }
        }}
        expandedRowRender={(record) => {
          return (
            <Row>
              <Col
                span={10}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '100%' }}>
                  <ShowItems sentences={record?.sentences} />
                </Row>
              </Col>
              <Col span={14}>{null}</Col>
            </Row>
          )
        }}
        status={params.status}
        pauseRequest={requestUpdateStatusMultiStep}
        openDeleteModal={showModalDelete}
        handleData={handleData}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.rules ?? []}
            close={closeModalDelete}
            componentBody={textBody}
            onClick={() => multipleDelete()}
            fetching={fetchingDelete}
          />
        }
        addHeight={-30}
        entityNameForBulk="Multi-Step"
      />
    </RulesContainer>
  )
}

export default ListMultiStep
