import React, { useEffect } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import notification from 'antd/lib/notification'
import { useSelector } from 'react-redux'
import CreateRate from 'containers/rates/CreateRate'
import RateInformation from 'containers/dashboard/RateInformation'
import SonarRatio from 'containers/dashboard/SonarRatio'
import DashboardTab from 'containers/dashboard/DashboardTab'
import CustomerRate from 'containers/rates/CustomerRate'
import CarrierCost from 'containers/dashboard/CarrierCost'
import { Container } from './styles'

const Dashboard = () => {
  const { success, rate } = useSelector((state) => ({
    success: state.getRate.success,
    rate: state.getRate.rate,
    graphic: state.getRate.rate.graphics,
  }))

  

  useEffect(() => {
    if (!success) return
    rate.notifications.forEach((entry, key) => {
      notification.error({
        message: 'Third Party Integration error',
        description: entry.message,
        placement: 'topRight',
        duration: 5,
        key,
      })
    })
  }, [rate, success])

  return (
    <Container>
      <Row gutter={[16, 16, 16]}>
        <Col md={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 7 }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <CreateRate />
            </Col>
            <Col span={24}>
              <RateInformation />
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 10 }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <DashboardTab />
            </Col>
            <Col span={24}>
              <SonarRatio />
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 7 }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <CustomerRate />
            </Col>
            <Col span={24}>
              <CarrierCost />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Dashboard
