import React from 'react'
import moment from 'moment'
import { StyledText } from 'containers/dashboard/DashboardTab/utils/styles'
import DateLabel from 'components/Label/DateLabel'

const Main = ({ children }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} id={10000}>
    <StyledText>{children}</StyledText>
  </div>
)

export default function () {
  return [
    {
      title: <div style={{ color: '#545454' }}> Pro #</div>,
      dataIndex: 'loadProNumber',
      key: 'loadProNumber',
      render: (tmsId) => <Main>{tmsId}</Main>,
      width: 140,
      align: 'center',
    },
    {
      title: <div style={{ color: '#545454' }}>Customer Quote</div>,
      dataIndex: 'sellRate',
      key: 'sellRate',
      render: (sellRate) => <Main>{`$${sellRate}`}</Main>,
      width: 150,
      align: 'center',
    },

    {
      title: <div style={{ color: '#545454' }}>Carrier Quote</div>,
      dataIndex: 'totalCarrierCost',
      key: 'totalCarrierCost',
      width: 150,
      ellipsis: true,
      align: 'center',
      render: (totalCarrierCost) => <Main>{`$${totalCarrierCost}`}</Main>,
    },
    {
      title: <div style={{ color: '#545454' }}>Pickup Date</div>,
      dataIndex: 'loadPickupDate',
      key: 'loadPickupDate',
      width: 100,
      ellipsis: true,
      align: 'center',
      render: (loadPickupDate) => <DateLabel date={moment(loadPickupDate).format('MM/DD/YYYY')} />,
    },
    {
      title: <div style={{ color: '#545454' }}>Pickup location</div>,
      dataIndex: 'originCity',
      key: ['originCity', 'item'],
      render: (originCity, item) => <Main>{`${originCity}, ${item.originState}`}</Main>,
      width: 180,
      align: 'center',
    },
    {
      title: <div style={{ color: '#545454' }}>Delivery location</div>,
      dataIndex: 'destinationCity',
      key: ['destinationCity', 'item'],
      render: (destinationCity, item) => <Main>{`${destinationCity}, ${item.destinationState}`}</Main>,
      width: 180,
      align: 'center',
    },
   
   
  ]
}
