import React, { useEffect, useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Badge from 'antd/lib/badge'
import Avatar from 'antd/lib/avatar'
import Upload from 'antd/lib/upload'
import Select from 'antd/lib/select'
import Text from 'antd/lib/typography/Text'
import Swal from 'sweetalert2'
import { omit, pick } from 'lodash/object'
import { MdPhotoCamera } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import TitleDrawer from 'components/TitleDrawer'
import PrimarySubmit from 'components/PrimarySubmit'
import { AvatarContainer } from 'containers/users/UserProfileInfo/styles'
import TextInput from 'components/Form/TextInput'
import SelectInput from 'components/Form/SelectInput'
import PasswordInput from 'components/Form/PasswordInput'
import { StyledModal } from 'containers/rules/ListMultiStep/utils/CreateMultistepRule/styles'
import notify from 'utils/notify'
import { emailRules, phoneRulesNoRequired, stringRules } from 'utils/inputRules'
import UserPhoto from 'assets/img/UserPhoto.png'
import colors from 'providers/theme/config/colorPallete'

import { requestUserList } from 'store/duck/getUsers.duck'
import { requestEditUser, resetEditUser } from 'store/duck/editUser.duck'
import { requestCreateUser, resetCreateUser } from 'store/duck/createUser.duck'

const formName = 'create-user-form'

const defaultValues = {
  name: '',
  lastName: '',
  roleId: '',
  email: '',
  phone: '',
  datUsername: '',
  datPassword: '',
  itsUsername: '',
  itsPassword: '',
  sonarUsername: '',
  sonarPassword: '',
}

const CreateRulesetForm = ({ onSubmit }) => {
  const [form] = Form.useForm()

  const { editUser } = useSelector((state) => state.createUser)
  const { data: loadedUser, success, error, message: errorMessage } = useSelector((state) => state.userEditAdmin)

  const [avatar, setAvatar] = useState(UserPhoto)
  const [userAvatar, setUserAvatar] = useState('')
  const { data: roles } = useSelector((state) => state.getRoles)

  const handlePreview = (file) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setAvatar(reader.result)
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleBeforeUpload = (file) => {
    if (file.size > 300000) {
      Swal.fire({
        icon: 'error',
        title: 'File Upload Error!',
        text: 'Image must be smaller than 300KB!',
      })
      setUserAvatar('')
      setAvatar(UserPhoto)
      return false
    }
    handlePreview(file)
    setUserAvatar(file)
    return false
  }

  const handleSubmit = (formProps) => {
    onSubmit({ ...formProps, userAvatar })
  }

  useEffect(() => {
    if (editUser && success) {
      if (loadedUser.avatar && loadedUser.avatar !== 'undefined') {
        setAvatar(loadedUser.avatar)
      }

      form.setFieldsValue({
        name: loadedUser.name,
        lastName: loadedUser.lastName,
        roleId: loadedUser.roleId._id,
        email: loadedUser.email,
        phone: loadedUser.phone,
        datUsername: loadedUser.authApis.datUsername,
        datPassword: loadedUser.authApis.datPassword,
        itsUsername: loadedUser.authApis.itsUsername,
        itsPassword: loadedUser.authApis.itsPassword,
        sonarUsername: loadedUser.authApis.sonarUsername,
        sonarPassword: loadedUser.authApis.sonarPassword,
      })
    }
  }, [loadedUser, editUser, form, success])

  useEffect(() => {
    if (error) {
      notify('error', `Get user Data error! ${errorMessage}`)
    }
  }, [error, errorMessage])

  const backendUrl = process.env.REACT_APP_BASE_BACKEND_URL
  const displayInputPassword = backendUrl ? backendUrl.includes('apiqa') : false

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '14px' }}>
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
            {editUser ? 'Edit the information of this new User' : 'Add the information of this new User'}
          </Text>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={handleSubmit}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row justify="center">
          <AvatarContainer>
            <Upload accept=".jpg,.jpeg,.png" fileList={[]} name="file" beforeUpload={handleBeforeUpload}>
              <Badge
                offset={[0, 20]}
                count={
                  <MdPhotoCamera
                    style={{
                      color: 'white',
                      fontSize: '31px',
                      padding: '4px',
                      borderRadius: '50%',
                      backgroundColor: colors.dark_blue.default,
                    }}
                  />
                }
              >
                <Avatar size={70} src={avatar} />
              </Badge>
            </Upload>
          </AvatarContainer>
        </Row>

        <Row gutter={[26, 10]}>
          <Col span={24}>
            <TextInput label="First Name *" name="name" rules={stringRules('User´s First Name')} />
          </Col>
          <Col span={24}>
            <TextInput label="Last Name *" name="lastName" rules={stringRules('User´s Last Name')} />
          </Col>
          <Col span={24}>
            <SelectInput
              label="Role *"
              name="roleId"
              rules={[{ required: true, message: 'Please select a role' }]}
            >
              {roles.map((role) => (
                <Select.Option key={role._id} value={role._id}>
                  {role.name}
                </Select.Option>
              ))}
            </SelectInput>
          </Col>
          <Col span={24}>
            <TextInput label="Email *" name="email" rules={emailRules} />
          </Col>
          <Col span={24}>
            <TextInput label="Phone" name="phone" rules={phoneRulesNoRequired} />
          </Col>
          {!displayInputPassword ? (
            <Col span={12}>
              <TextInput label="DAT Username" name="datUsername" />
            </Col>
          ) : (
            <Col span={24}>
              <TextInput label="DAT Username" name="datUsername" />
            </Col>
          )} 
          {!displayInputPassword ? (
            <Col span={12}>
              <PasswordInput label="DAT Password" name="datPassword" />
            </Col>
          ) : null}

          <Col span={12}>
            <TextInput label="Sonar Username" name="sonarUsername" />
          </Col>
          <Col span={12}>
            <PasswordInput label="Sonar Password" name="sonarPassword" />
          </Col>
        </Row>
      </Form>
    </>
  )
}

const CreateUserModal = ({ visible, handleOnClose }) => {
  const dispatch = useDispatch()
  const {
    success: successCreateUser,
    error: errorCreateUser,
    message: errorMessageCreateUser,
    editUser,
    userSelected,
  } = useSelector((state) => state.createUser)
  const { success: successEditUser, error: errorEditUser, message: errorMessageEditUser } = useSelector(
    (state) => state.editUser
  )

  const [loading, setLoading] = useState(false)

  const submitCreateRuleset = (rawDataToSubmit) => {
    setLoading(true)
    const authApisProperties = [
      'datUsername',
      'datPassword',
      'itsUsername',
      'itsPassword',
      'sonarUsername',
      'sonarPassword',
    ]

    const authApis = pick(rawDataToSubmit, authApisProperties)
    const dataToSubmit = omit(rawDataToSubmit, authApisProperties)

    const formData = new FormData()
    for (const property in dataToSubmit) {
      if (property) {
        formData.set(property, dataToSubmit[property])
      }
    }

    formData.set('authApis', JSON.stringify(authApis))

    if (dataToSubmit.userAvatar) {
      formData.set('avatar', dataToSubmit.userAvatar)
    }

    if (editUser) {
      dispatch(requestEditUser(formData, userSelected._id))
    } else {
      dispatch(requestCreateUser(formData))
    }
  }

  useEffect(() => {
    if (successCreateUser || errorCreateUser) {
      if (successCreateUser) {
        notify('success', 'User created successfully!')
        handleOnClose()

        dispatch(requestUserList())
      }
      if (errorCreateUser) {
        notify('error', `User creation Error! ${errorMessageCreateUser}`)
      }

      setLoading(false)
      dispatch(resetCreateUser())
    }
  }, [errorCreateUser, errorMessageCreateUser, successCreateUser, dispatch, handleOnClose])

  useEffect(() => {
    if (successEditUser || errorEditUser) {
      if (successEditUser) {
        notify('success', 'User edited successfully!')
        handleOnClose()

        dispatch(requestUserList())
      }
      if (errorEditUser) {
        notify('error', `User edition Error! ${errorMessageEditUser}`)
      }

      setLoading(false)
      dispatch(resetEditUser())
    }
  }, [errorEditUser, errorMessageEditUser, successEditUser, dispatch, handleOnClose])

  return (
    <StyledModal
      width={500}
      title={<TitleDrawer title={editUser ? 'Edit User' : 'Create New User'} />}
      visible={visible}
      bodyStyle={{ padding: '10px 25px 0px' }}
      onCancel={() => handleOnClose()}
      footer={
        <div>
          <PrimarySubmit label={editUser ? 'Edit User' : 'Save User'} formName={formName} loading={loading} />
        </div>
      }
      destroyOnClose
    >
      <CreateRulesetForm onSubmit={submitCreateRuleset} />
    </StyledModal>
  )
}

export default CreateUserModal
