/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { addSpecialService } from 'store/duck/createSpecialService.duck'
import { clearDeleteSpecialService, requestDeleteSpecialService } from 'store/duck/deleteSpecialService.duck'
import { clearUpdateSpecialService, requestUpdateSpecialService } from 'store/duck/updateSpecialService.duck'
import { clearGetSpecialServicesList, requestGetSpecialServicesList } from 'store/duck/getSpecialServices.duck'
import { MdBlock } from 'react-icons/md'
import { BsPlusSquareFill } from 'react-icons/bs'
import notify from 'utils/notify'
import Table from 'components/Table'
import StyledSearch from 'components/Input/Search'
import FloatLabel from 'components/Label/FloatLabel'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { StyledItem, StyledOptions } from 'components/Form/FilterRules/styles'
import CreateSpecialServiceModal from 'containers/settings/SpecialService'
import { ArrowDownIcon } from 'assets/svg'

import columns from './utils/TableColumns'

const ServicesList = () => {
  const { xl } = useBreakpoint()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { success: successSpecialService } = useSelector((state) => state.createSpecialService)
  const { successList, errorList, servicesList, totalRecords } = useSelector((state) => state.getSpecialServices)
  const { successDelete, error, message } = useSelector((state) => state.deleteSpecialService)
  const { success: successUpdate, message: messageUpdate, error: errorUpdate } = useSelector(
    (state) => state.updateSpecialService
  )

  const { success: successActivate } = useSelector((state) => state.activateSpecialService)
  const [createVisible, setCreateVisible] = useState(false)
  const [params, setParams] = useState({ text: '', page: 1, status: '' })
  const tableColumns = columns(dispatch, setCreateVisible)
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)

  const handleValuesChange = (values) => {
    const active = values.active ?? params.active
    setParams({ ...params, text: values.text ?? params.text, active: active !== 'All' ? active : undefined })
  }

  useEffect(() => {
    dispatch(requestGetSpecialServicesList(params))
  }, [params, dispatch])

  useEffect(() => {
    if (successSpecialService || successActivate || successUpdate) {
      dispatch(requestGetSpecialServicesList(params))
    }
    if (successUpdate) {
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateSpecialService())
    }
    if (successActivate && msgSnack !== '') {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateSpecialService())
    }
    if (errorUpdate || messageUpdate !== '') {
      notify('error', `Error update: ${messageUpdate}`)
    }
  }, [
    dispatch,
    params,
    successSpecialService,
    successUpdate,
    msgSnack,
    messageUpdate,
    errorUpdate,
    successActivate,
  ])

  useEffect(() => {
    if (errorList) {
      notify('error', 'Error getting special services!')
      dispatch(clearGetSpecialServicesList())
    }
  }, [dispatch, errorList])

  useEffect(() => {
    if (successDelete) {
      notify('success', 'Special service has been deleted')
      dispatch(clearMessageSnackBar())
      dispatch(clearDeleteSpecialService())
      dispatch(requestGetSpecialServicesList(params))
    }
    if (error) {
      notify('error', `Error deleting special service: ${message}`)
      dispatch(clearDeleteSpecialService())
    }
  }, [dispatch, successDelete, error, message, params, msgSnack])

  const handleAddService = () => {
    dispatch(addSpecialService())
    setCreateVisible(true)
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <div style={{ width: '96%', margin: '10px auto 15px' }}>
      <Form
        layout="vertical"
        name="filter-special-service-list"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          active: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="active">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Active" value="true">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Blocked" value="false">
                    Blocked
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          <Col flex="none">
            <Form.Item>
              <ButtonPrimary
                icon={<BsPlusSquareFill size="16px" />}
                height="40px"
                onClick={() => handleAddService()}
              >
                New Special Service
              </ButtonPrimary>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CreateSpecialServiceModal visible={createVisible} onClose={() => setCreateVisible(false)} />
      <Table
        width="100%"
        padding="4px 0px"
        margin="0px"
        columns={tableColumns}
        data={servicesList}
        loading={!successList}
        pagination={{ total: totalRecords ?? 0 }}
        onChange={handleTableChange}
        rowKey="_id"
        rowSelection
        updatedPagination
        dispatch={dispatch}
        avoidNotify
        status={params.status}
        entityNameForBulk="Special Service"
        pauseProps={{
          label: 'Block',
          labelSuccess: 'blocked',
          icon: (props) => <MdBlock {...props} />,
          newState: { active: false },
        }}
        pauseRequest={requestUpdateSpecialService}
        deleteRequest={requestDeleteSpecialService}
      />
    </div>
  )
}

export default ServicesList
