import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Divider from 'antd/lib/divider'
import { Popover } from 'antd'
import RulesModal from 'containers/dashboard/RulesModal'
import SpecialServicesModal from 'containers/dashboard/SpecialServicesModal'
import Box from 'components/Box/BoxGeneric'
import TextPrimary, { StyledCol } from 'components/Text/TextPrimary'

import Collapse from 'components/Collapse'
import Tabs from 'components/Tabs'
import Modal from 'components/Modal'
import { LinkButton } from 'components/Button/TextButton'
import BoxSnapshot, { BoxSnapshotTitle, BoxSnaphotConent } from 'components/Box/BoxSnapshot'
import { toNumber } from 'lodash'

const colors = [
  '#002555',
  '#3594FF',
  '#8C54FF',
  '#F6A15B',
  '#F44D6C',
  '#56B158',
  '#d73027',
  '#542788',
  '#545454',
  '#EFF3FF',
  '#EAFFFD',
  '#FEF4F5',
  '#FFFCE2',
  '#FFFFFF',
]

const CarrierCost = () => {
  const { TabPane } = Tabs
  const { Panel } = Collapse
  const {
    rateBasic,
    success,
    ruleAdjustments,
    multiSteptAdjustments,
    datRate,
    gsRate,
    gsDatalake,
    mci,
  } = useSelector((state) => ({
    rateBasic: state.getRate.rate,
    datRate: state.getRate.rate?.base_rate?.datRate,
    gsRate: state.getRate.rate?.base_rate?.gsRate,
    gsDatalake: state.getRate.rate?.internal_adjustment,
    success: state.getRate.success,
    ruleAdjustments: state.getRate.rate.ruleAdjustment,
    multiSteptAdjustments: state.getRate.rate.multiStepAdjustment,
    mci: state.getRate.rate?.marketConditions,
  }))
  const [visible, setVisible] = useState(false)

  const convertToNumber = (string) => `$${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed())}`
  const stringIsNegative = (value) => {
    return String(value ?? '').match(/-/) !== null
  }

  const addStyle = (value) => {
    return stringIsNegative(value) ? {} : { color: 'red' }
  }
  const mciComponent = (component) => {
    if (mci) return component
    return null
  }

  const greenScreensComponent = (component) => {
    if (gsRate && gsRate.rate) {
      if (gsRate?.rate === '$0.00') {
        return null
      }
      return component
    }
    return null
  }

  const gsDatalakeComponent = (component) => {
    if (gsDatalake && gsDatalake.rate) {
      return component
    }
    return null
  }

  const datContractComponent = (component) => {
    if (!datRate.datContract) {
      return null
    }

    if (datRate.datLow.rate === '$0.00' || datRate.datContract.rate === '$0.00') {
      return null
    }

    return component
  }

  const datRateComponent = (component) => {
    if (!datRate.datLow) {
      return null
    }
    if (datRate?.datLow?.rate === '$0.00' || datRate?.datContract?.rate === '$0.00') {
      return null
    }

    return component
  }

  const validateDATTooltip = () => {
    if (datRate && datRate.datLow) {
      return datRate.datLow.rate === '$0.00'
    }
    return true
  }
  const validateRateBasic = () => {
    if (rateBasic && rateBasic.priceBase) {
      return rateBasic.priceBase === '$0.00'
    }
    return true
  }

  const validatePrice = () => {
    if (rateBasic && rateBasic.priceSpecialServices) {
      return rateBasic.priceSpecialServices === '$0.00'
    }
    return true
  }
  const validateRateCost = () => {
    if (rateBasic && rateBasic.rateCost) {
      return rateBasic.rateCost === '$0.00'
    }
    return true
  }
  const validateGS = () => {
    if (gsRate) {
      return gsRate.rate === '$0.00'
    }
    return true
  }

  const validateDataLake = () => {
    if (gsDatalake && gsDatalake.rate) {
      return gsDatalake.rate === '$0.00'
    }
    return true
  }

  const inNumber = (string, fixed) => `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  const datRateTotal = validateDATTooltip() ? 0 : inNumber(datRate.total)
  const gsRateTotal = validateGS() ? 0 : inNumber(gsRate?.total ?? '')
  const sumDG = toNumber(datRateTotal) + toNumber(gsRateTotal)

  return (
    <Box title="Carrier Cost Estimate" secondary height="auto">
      {success ? (
        <>
          <Tabs defaultActiveKey="1" size="small">
            <TabPane tab="Snapshot" key="1">
              {datRateComponent(
                <Popover
                  title={
                    <p
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      DAT Review
                    </p>
                  }
                  content={
                    <div>
                      <p>
                        <strong>Origin:</strong> {datRate?.datExtraInfo?.originGeography || 'N/A'}
                      </p>
                      <br />
                      <p>
                        <strong>Destination:</strong> {datRate?.datExtraInfo?.destinationGeography || 'N/A'}
                      </p>
                      <br />
                      <p>
                        <strong>Miles:</strong> {datRate?.datExtraInfo?.miles || 'N/A'}
                      </p>
                      <br />
                      <p>
                        <strong>Contributors:</strong> {datRate?.datExtraInfo?.contributors || 'N/A'}
                      </p>
                      <br />
                      <p>
                        <strong>Reports:</strong> {datRate?.datExtraInfo?.moves || 'N/A'}
                      </p>
                      <br />
                      <p>
                        <strong>Time Frame:</strong>{' '}
                        {datRate?.datExtraInfo?.daysBack ? `${datRate?.datExtraInfo?.daysBack} days` : 'N/A'}
                      </p>
                    </div>
                  }
                  placement="right"
                >
                  <BoxSnapshot color={colors[9]}>
                    <BoxSnapshotTitle color={colors[8]}>DAT Rateview</BoxSnapshotTitle>
                    <BoxSnaphotConent>
                      <div className="spanContainer ">
                        <span className="label">Low</span>
                        <span className="tagValue">{convertToNumber(datRate?.datLow?.rate ?? '')} USD</span>
                      </div>
                      <div className="spanContainer">
                        <span className="label">Avg</span>

                        <span className="tagValue">{convertToNumber(datRate?.datAvg?.rate ?? '')} USD</span>
                      </div>
                      <div className="spanContainer ">
                        <span className="label">High</span>
                        <span className="tagValue">{convertToNumber(datRate?.datHigh?.rate ?? '')} USD</span>
                      </div>
                    </BoxSnaphotConent>
                  </BoxSnapshot>
                </Popover>
              )}
              {datContractComponent(
                <BoxSnapshot color={colors[10]}>
                  <BoxSnapshotTitle color={colors[8]}>Contract</BoxSnapshotTitle>
                  <div className="spanContainer">
                    <span className="tagValue">{convertToNumber(datRate?.datContract?.rate ?? '')} USD</span>
                  </div>
                </BoxSnapshot>
              )}
              {greenScreensComponent(
                <BoxSnapshot color={colors[11]}>
                  <BoxSnapshotTitle color={colors[8]}>Greenscreens</BoxSnapshotTitle>
                  <BoxSnaphotConent style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="spanContainer">
                      <span className="label">Target</span>
                      <span className="tagValue">{convertToNumber(gsRate.rate ?? '')} USD</span>
                    </div>
                    <div className="spanContainer ">
                      <span className="label">Confidence Level</span>
                      <span className="tagValue"> {`${gsRate.gsConfidenceLevel} %` || 'N/A'}</span>
                    </div>
                  </BoxSnaphotConent>
                </BoxSnapshot>
              )}
              {gsDatalakeComponent(
                <Popover
                  title={
                    <p
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Ally Lane History
                    </p>
                  }
                  content={
                    <div>
                      <p>
                        <strong># Reports (outlier):</strong> {gsDatalake?.extraInfo?.reports_outlier || 'N/A'}
                      </p>
                      <p>
                        <strong>Timeframe:</strong> {gsDatalake?.extraInfo?.timeframe || 'N/A'}
                      </p>
                    </div>
                  }
                  placement="right"
                >
                  <BoxSnapshot color={colors[12]}>
                    <BoxSnapshotTitle color={colors[8]}>Ally Lane History</BoxSnapshotTitle>
                    <BoxSnaphotConent style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className="spanContainer">
                        <span className="label">Avg</span>
                        <span className="tagValue">{convertToNumber(gsDatalake?.rate ?? '')} USD</span>
                      </div>
                      <div className="spanContainer ">
                        <span className="label"># of Reports</span>
                        <span className="tagValue"> {gsDatalake?.extraInfo?.reports || 'N/A'}</span>
                      </div>
                    </BoxSnaphotConent>
                  </BoxSnapshot>
                </Popover>
              )}
              {mciComponent(
                <BoxSnapshot color={colors[10]}>
                  <BoxSnapshotTitle color={colors[8]}>Market Conditions</BoxSnapshotTitle>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className="tagValue">OUTBOUND</span>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="spanContainer">
                          <span className="label">Last 7 Days </span>
                          <span className="tagValue" style={addStyle(mci?.OUTBOUND?.historyMciScore ?? '')}>
                            {mci?.OUTBOUND?.historyMciScore ?? ''}
                          </span>
                        </div>
                        <div className="spanContainer ">
                          <span className="label">Forecast</span>
                          <span className="tagValue" style={addStyle(mci?.OUTBOUND?.forecastMciScore ?? '')}>
                            {mci?.OUTBOUND?.forecastMciScore ?? ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className="tagValue">INBOUND</span>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="spanContainer">
                          <span className="label">Last 7 Days</span>
                          <span className="tagValue" style={addStyle(mci?.INBOUND?.historyMciScore ?? '')}>
                            {mci?.INBOUND?.historyMciScore ?? ''}
                          </span>
                        </div>
                        <div className="spanContainer ">
                          <span className="label">Forecast</span>
                          <span className="tagValue" style={addStyle(mci?.INBOUND?.forecastMciScore ?? '')}>
                            {mci?.INBOUND?.forecastMciScore ?? ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxSnapshot>
              )}
            </TabPane>
            <TabPane tab="Details" key="2">
              <Row gutter={[16, 16]} justify="space-between" style={{ marginLeft: '0' }}>
                <StyledCol span={24}>BASE RATE</StyledCol>

                <StyledCol span={7}>DAT Weight</StyledCol>
                <Col span={8} style={{ paddingLeft: '26px', whiteSpace: 'nowrap' }}>
                  <LinkButton onClick={() => setVisible(true)}>See Details</LinkButton>
                </Col>
                <Col span={1}>=</Col>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol>{validateDATTooltip() ? '$0' : convertToNumber(datRate.total)}</StyledCol>
                </Col>
                <StyledCol span={6}>Greenscreens</StyledCol>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Col span={2} style={{ fontSize: '16px', paddingLeft: '12px' }}>
                    {gsRate?.confidencePercentage ?? 0}%
                  </Col>
                  <Col span={2}>
                    <Col style={{ fontSize: '16px', paddingLeft: '0' }}>X</Col>
                  </Col>
                  <Col span={5} style={{ fontSize: '16px' }}>
                    {validateGS() ? 'N/A' : convertToNumber(gsRate?.rate)}
                  </Col>
                </div>
                <Col span={1}>=</Col>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol> {validateGS() ? 0 : convertToNumber(gsRate?.total)} </StyledCol>
                </Col>
                <StyledCol span={7}>Base total</StyledCol>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol> {`$${sumDG}`}</StyledCol>
                </Col>
                <StyledCol span={24}>ADJUSMENT</StyledCol>
                <StyledCol span={6}>Datalake</StyledCol>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Col span={2} style={{ fontSize: '16px', paddingLeft: '12px' }}>
                    {validateDataLake() ? '0' : inNumber(gsDatalake?.confidencePercentage, 2)}%
                  </Col>
                  <Col span={2}>
                    <Col style={{ fontSize: '16px', paddingLeft: '5px' }}>X</Col>
                  </Col>
                  <Col span={5} style={{ fontSize: '16px' }}>
                    {validateDataLake() ? '$0' : convertToNumber(gsDatalake?.rate)}
                  </Col>
                </div>
                <Col span={1}>=</Col>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol> {validateDataLake() ? '$0' : convertToNumber(gsDatalake?.total)} </StyledCol>
                </Col>
                <StyledCol span={6}>Base total</StyledCol>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Col span={2} style={{ fontSize: '16px', paddingLeft: '12px' }}>
                    {`${inNumber(rateBasic.base_rate.confidencePercentage, 2) ?? 0}%`}
                  </Col>
                  <Col span={2}>
                    <Col style={{ fontSize: '16px' }}>X</Col>
                  </Col>
                  <Col span={5} style={{ fontSize: '16px' }}>
                    {`$${sumDG}`}
                  </Col>
                </div>
                <Col span={1}>=</Col>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol>{validateRateBasic() ? `N/A` : convertToNumber(rateBasic.base_rate.total)}</StyledCol>
                </Col>
                <StyledCol span={7}>Adjusted total</StyledCol>
                <Col span={7} style={{ paddingLeft: '36px' }}>
                  <StyledCol>{validateRateBasic() ? `N/A` : convertToNumber(rateBasic.priceBase)}</StyledCol>
                </Col>
                <Divider style={{ margin: '12px 0', background: 'rgba(217, 217, 217, 0.4)' }} />
              </Row>
              <Row gutter={[16, 32]}>
                <Col xl={10} xxl={9} style={{ paddingRight: '0' }}>
                  <span>
                    {ruleAdjustments &&
                      multiSteptAdjustments &&
                      `Rules Adjustment (${ruleAdjustments.length + multiSteptAdjustments.length})`}
                  </span>
                  <span>
                    {ruleAdjustments.length + multiSteptAdjustments.length === 1
                      ? ' rule applied '
                      : ' rules applied'}
                  </span>
                </Col>
                <Col span={8} offset={6} style={{ paddingRight: '0' }}>
                  <RulesModal />
                </Col>
              </Row>
              <Collapse defaultActiveKey="1" ghost expandIconPosition="right">
                <Panel header="Trend Adjustment" key="1">
                  <Row gutter={[16, 8]} justify="space-between">
                    <div style={{ display: 'flex', width: '100%' }}>
                      <Col style={{ width: '67%', paddingRight: '0' }}>Extra Stops</Col>
                      <Col style={{ width: '100%', paddingLeft: '0' }}>
                        <StyledCol>{rateBasic.extraStops}</StyledCol>
                      </Col>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <Col style={{ width: '67%', paddingRight: '0' }}>Special Services</Col>
                      <Col style={{ width: '58%', paddingLeft: '0' }}>
                        <StyledCol>
                          {validatePrice() ? `N/A` : convertToNumber(rateBasic.priceSpecialServices)}
                        </StyledCol>
                      </Col>
                      <Col span={6} style={{ paddingRight: '15px', paddingLeft: '0' }}>
                        <SpecialServicesModal />
                      </Col>
                    </div>
                  </Row>
                </Panel>
              </Collapse>
              <Row gutter={[16, 32]} justify="space-between">
                <Col span={4} style={{ whiteSpace: 'nowrap' }}>
                  <StyledCol>TOTAL COST</StyledCol>
                </Col>
                <Col xxl={{ span: 7 }} xl={{ span: 8 }} style={{ paddingLeft: '46px' }}>
                  <StyledCol>{validateRateCost() ? `N/A` : convertToNumber(rateBasic.rateCost)}</StyledCol>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          <Modal
            title="DAT Pricing Details"
            width={600}
            visible={visible}
            onCancel={() => {
              setVisible(!visible)
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={7}>DAT Contract</Col>
              <Col span={3}>{datRate?.datContract?.confidencePercentage ?? 0}%</Col>
              <Col span={1}>
                <TextPrimary>X</TextPrimary>
              </Col>
              <Col span={4}>{convertToNumber(datRate?.datContract?.rate ?? '')}</Col>
              <Col span={1}>=</Col>
              <Col span={7}>
                <TextPrimary>{convertToNumber(datRate?.datContract?.total ?? '')}</TextPrimary>
              </Col>

              <Col span={7}>DAT High</Col>
              <Col span={3}>{datRate?.datHigh?.confidencePercentage ?? 0}%</Col>
              <Col span={1}>
                <TextPrimary>X</TextPrimary>
              </Col>
              <Col span={4}>{convertToNumber(datRate?.datHigh?.rate ?? '')}</Col>
              <Col span={1}>=</Col>
              <Col span={7}>
                <TextPrimary>{convertToNumber(datRate?.datHigh?.total ?? '')}</TextPrimary>
              </Col>

              <Col span={7}>DAT Low</Col>
              <Col span={3}>{datRate?.datLow?.confidencePercentage ?? '0'}%</Col>
              <Col span={1}>
                <TextPrimary>X</TextPrimary>
              </Col>
              <Col span={4}>{convertToNumber(datRate?.datLow?.rate ?? '')}</Col>
              <Col span={1}>=</Col>
              <Col span={7}>
                <TextPrimary>{convertToNumber(datRate?.datLow?.total ?? '')}</TextPrimary>
              </Col>

              <Col span={7}>DAT Weight</Col>
              <Col span={3}>{datRate?.datAvg?.confidencePercentage ?? 0}%</Col>
              <Col span={1}>
                <TextPrimary>X</TextPrimary>
              </Col>
              <Col span={4}>{convertToNumber(datRate?.datAvg?.rate ?? '')}</Col>
              <Col span={1}>=</Col>
              <Col span={7}>
                <TextPrimary>{convertToNumber(datRate?.datAvg?.total ?? '')}</TextPrimary>
              </Col>
            </Row>
            <div style={{ paddingBottom: '1.5rem !important', width: '1rem', height: '1rem' }} />
          </Modal>
        </>
      ) : (
        <div>
          <p>
            Empty section, please, enter the data to make the quote and you will be able to view the Dashboard
            information.
          </p>
        </div>
      )}
    </Box>
  )
}

export default CarrierCost
