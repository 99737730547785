import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserList from 'containers/users/UserList'
import TitleList from 'components/TitleList'
import { unloadUser } from 'store/duck/createUser.duck'
import { requestUserEditAdmin } from 'store/duck/userEditAdmin.duck'

import CreateUserModal from './utils/CreateUserModal'
import { CustomerContainer } from './styles'

const UserListPage = () => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const { editUser, userSelected } = useSelector((state) => state.createUser)

  useEffect(() => {
    if (editUser) {
      setVisible(true)

      dispatch(requestUserEditAdmin(userSelected._id))
    }
  }, [dispatch, editUser, userSelected._id])

  useEffect(() => {
    if (!visible) {
      dispatch(unloadUser())
    }
  }, [dispatch, visible])

  return (
    <CustomerContainer id="container">
      <div style={{ marginBottom: 15, marginTop: 20 }}>
        <TitleList title="Users" />
      </div>
      <UserList setVisible={setVisible} />

      <CreateUserModal visible={visible} handleOnClose={() => setVisible(false)} />
    </CustomerContainer>
  )
}

export default UserListPage
