import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ListRates from 'containers/rates/ListRates'
import HistoryLanes from 'containers/rates/HistoryLanes'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/rates-list" component={ListRates} />
      <Route exact path="/hist-lanes" component={HistoryLanes} />
    </Switch>
  )
}

export default Routes
