import { MdClose } from 'react-icons/md'
import React from 'react'

import colors from 'providers/theme/config/colorPallete'

const ClosablePill = ({ text, onClose }) => (
  <div
    style={{
      padding: '8px 16px',
      backgroundColor: '#BDC8D5',
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <span style={{ fontSize: 16, fontWeight: 400, color: colors.dark_grey.default }}>{text}</span>

    <MdClose
      size={24}
      color={colors.dark_blue.default}
      style={{ marginBottom: 1, marginLeft: 10, cursor: 'pointer' }}
      onClick={() => onClose()}
    />
  </div>
)

export default ClosablePill
