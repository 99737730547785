import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  editRule: false,
  resetForm: false,
  ruleSelected: {},
}

export const { requestCreateRule, successCreateRule, failureCreateRule, clearData, loadRule, addRule, startCreateRule } = createActions({
  REQUEST_CREATE_RULE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_RULE: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_RULE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_DATA: () => ({
    success: false,
    error: false,
    data: [],
    fetching: false,
  }),
  LOAD_RULE: (data) => ({
    ...defaultState,
    editRule: true,
    ruleSelected: data,
  }),
  ADD_RULE: () => ({
    ...defaultState,
    editRule: false,
    resetForm: true,
  }),
  START_CREATE_RULE: () => ({
    ...defaultState,
    resetForm: false,
  }),
})

const createRule = handleActions(
  {
    [requestCreateRule]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateRule]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureCreateRule]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearData]: produce((draft, { payload: { success, error } }) => {
      draft.success = success
      draft.error = error
    }),
    [loadRule]: produce((draft, { payload: { editRule, ruleSelected } }) => {
      draft.editRule = editRule
      draft.ruleSelected = ruleSelected
    }),
    [addRule]: produce((draft, { payload: { editRule, resetForm } }) => {
      draft.editRule = editRule
      draft.resetForm = resetForm
    }),
    [startCreateRule]: produce((draft, { payload: { resetForm } }) => {
      draft.resetForm = resetForm
    }),
  },
  defaultState
)

export default createRule
