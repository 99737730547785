import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { clearDeleteRule, requestDeleteRule } from 'store/duck/deleteRule.duck'
import { requestUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { addRule } from 'store/duck/createRule.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import ModalDeleteRules from 'components/ModalDeleteRules'
import Table from 'components/Table'
import FilterRules from 'components/Form/FilterRules'
import CreateRuleModal from 'containers/rules/CreateNewRule'
import notify from 'utils/notify'
import TextBodyModal from './utils/TextBodyModal'
import geograpchicColumns from './utils/GeographicColumns'
import lengthColumns from './utils/LengthColumns'
import simpleColumns from './utils/SimpleColumns'
import marketRules from './utils/MarketColumns'
import dateRangeColumns from './utils/DateRangeColumns'
import RulesContainer from '../styles'
import useGetRulesIds from './utils/useGetRulesIds'
import equipmentColumns from './utils/EquipmentColumns'

const ListRules = () => {
  const dispatch = useDispatch()

  const { shippingRule, referenceRule, typesId, queryParams } = useGetRulesIds()

  const deleteRule = useSelector((state) => state.deleteRule)
  const { rules, success, totalRules } = useSelector((state) => state.getRules)
  const [visible, setVisible] = useState(false)
  const [rulesData, setRulesData] = useState([])
  const [status, setStatus] = useState('All')
  const [columns, setColumns] = useState([])
  const [params, setParams] = useState({ page: 1, limit: 20 })
  const [columnsParams, setColumnsParams] = useState({
    type: 'geographicRule',
    shipping: 'outState',
    reference: 'state',
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [rulesToDelete, setRulesToDelete] = useState({ rules: [], multiStep: [], ruleSets: [] })
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.updateStatusRule
  )
  const { trucktypes } = useSelector((state) => state.getTruckTypeList)

  useEffect(() => {
    if (successUpdate) {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
    }

    if (errorUpdate) {
      notify('error', 'Error updating the ruleset.')
      dispatch(clearMessageSnackBar())
    }
  }, [successUpdate, errorUpdate, msmUpdate, dispatch, params, msgSnack])

  const showModalDelete = () => {
    setOpenDelete(true)
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
  }

  const handleData = useCallback(
    (rows) => {
      setRulesToDelete({
        ruleSets: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...rule?.rulesetsInfo?.ruleSetsNames]),
        multiStep: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...rule?.multiStepsRuleInfo.multistepNames]),

        rules: rows,
      })
    },
    [rulesData]
  )

  const multipleDelete = () => {
    batch(() => {
      rulesToDelete.rules.map((id) => dispatch(requestDeleteRule(id)))
    })
  }

  useEffect(() => {
    if (deleteRule.successDelete) {
      closeModalDelete()
    }
    if (!deleteRule.error) return
    dispatch(clearDeleteRule())
  }, [deleteRule, dispatch])

  useEffect(() => {
    setRulesData([])
    if (success) {
      const getColumns = {
        geographicRule: geograpchicColumns(dispatch, columnsParams.shipping, columnsParams.reference, setVisible),
        lengthRule: lengthColumns(dispatch, setVisible),
        simpleRule: simpleColumns(dispatch, columnsParams.shipping, setVisible),
        dateRangeRule: dateRangeColumns(dispatch, columnsParams.shipping, setVisible),
        truckType: equipmentColumns(dispatch, trucktypes, setVisible),
        marketRule: marketRules(dispatch, columnsParams.shipping, setVisible),
      }
      setColumns(getColumns[columnsParams.type])
      setRulesData(rules)
    }
  }, [dispatch, trucktypes, columnsParams, rules, success])

  const handleCreateRule = () => {
    dispatch(addRule())
    setVisible(true)
  }
  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])
  const closeModal = (some) => {
    setVisible(some)
  }
  return (
    <RulesContainer>
      <CreateRuleModal
        typesId={typesId}
        shippingRule={shippingRule}
        referenceRule={referenceRule}
        visible={visible}
        onCloseModal={closeModal}
        columnsParams={columnsParams}
      />
      <FilterRules
        queryParams={queryParams}
        handleColumnsParams={setColumnsParams}
        onClickNewRule={handleCreateRule}
        changeStatus={(newStatus) => setStatus(newStatus)}
        page={params.page}
        limit={params.limit}
      />

      <Table
        columns={columns}
        data={rulesData}
        loading={!success}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRules }}
        limitPerPage={50}
        paginationOptions={['50', '100', '150', '250']}
        updatedPagination
        onChange={handleTableChange}
        rowSelection
        ruleSelected={columnsParams.type}
        status={status}
        dispatch={dispatch}
        pauseRequest={requestUpdateStatusRule}
        avoidNotify
        openDeleteModal={showModalDelete}
        handleData={handleData}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.ruleSets}
            multiSteps={rulesToDelete.multiStep}
            close={closeModalDelete}
            componentBody={() => (
              <TextBodyModal
                multiQuantity={rulesToDelete.multiStep.length}
                ruleSetQuantity={rulesToDelete.ruleSets.length}
              />
            )}
            onClick={() => multipleDelete()}
            fetching={deleteRule.fetching}
          />
        }
        addHeight={-30}
      />
    </RulesContainer>
  )
}

export default ListRules
