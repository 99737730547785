import React, { useState, useCallback } from 'react'
import { ButtonDatalake, SpaceDatalake } from './style'

export default function HeaderTableDataLake({ filter }) {
  const [check, setCheck] = useState(false)
  const changeColorAndFilter = useCallback(() => {
    const type = typeof filter
    if (type === 'function') {
      filter()
    }
    setCheck(!check)
  }, [filter, check])

  return (
    <SpaceDatalake wrap>
      <ButtonDatalake type={check ? 'primary' : ''} size="samll" onClick={changeColorAndFilter}>
        Outlier
      </ButtonDatalake>
    </SpaceDatalake>
  )
}
