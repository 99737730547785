import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'
import { PingTruck, PingLocation } from 'assets/img'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  origin: '',
  destination: '',
  markers: [],
  routeMarkers: [],
  stopMarkers: [],
  index: 0,
}

export const {
  requestGetZipHere,
  successGetZipHere,
  failureGetZipHere,
  clearGetZipHere,
  removeStopMarkers,
  saveRouteMarkers,
  updateMarkers,
  loadRouteMarkers,
  loadSonarData,
  finishRate,
  resetRate,
} = createActions({
  REQUEST_GET_ZIP_HERE: (entry, index) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    entry,
    index,
  }),
  SUCCESS_GET_ZIP_HERE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ZIP_HERE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_ZIP_HERE: () => ({
    message: '',
    fetching: false,
    error: false,
    success: false,
    target: 'origin',
    routeMarkers: [],
  }),
  REMOVE_STOP_MARKERS: (stopMarkers) => ({
    stopMarkers,
  }),
  SAVE_ROUTE_MARKERS: (routeMarkers) => ({
    routeMarkers,
  }),
  UPDATE_MARKERS: (markers) => ({
    markers,
  }),
  LOAD_ROUTE_MARKERS: (dataMarkers) => ({
    dataMarkers,
  }),
  LOAD_SONAR_DATA: (origin, destination) => ({
    origin,
    destination,
  }),
  FINISH_RATE: () => ({
    markers: [],
    stopMarkers: [],
    data: [],
  }),
  RESET_RATE: () => ({
    ...defaultState,
  }),
})

const getZipHere = handleActions(
  {
    [requestGetZipHere]: produce((draft, { payload: { fetching, data, error, success, index } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.index = index
    }),
    [successGetZipHere]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      const zip = data.data.items.find((x) => x.address.postalCode)
      let currentMarkers = [...draft.markers]
      const currentStopMarkers = [...draft.stopMarkers]

      const sortMarkers = currentMarkers[0] ? [currentMarkers[0]] : []
      currentStopMarkers.forEach((element) => {
        sortMarkers.push(element)
      })
      if (currentMarkers[1]) {
        sortMarkers.push(currentMarkers[1])
      }

      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      if (draft.index === 0) {
        draft.data = {
          origin: {
            zipCode: zip.address.postalCode,
            city: zip.address.label.replace(', United States', ''),
            state: zip.address.stateCode,
            digitZip3: zip.address.postalCode.substr(0, 3),
          },
        }
        draft.origin = zip.address.postalCode.substring(0, 3)
        const addMarker = {
          coordinate: { lat: zip.position.lat, lng: zip.position.lng },
          content: {
            type: 'Origin',
            city: zip.address.city,
            zipCode: zip.address.postalCode,
          },
          icon: PingTruck,
        }
        if (currentMarkers.length > 0 && currentMarkers[0].content.type === 'Origin') {
          currentMarkers[0] = addMarker
        } else {
          currentMarkers.unshift(addMarker)
        }
        draft.markers = currentMarkers
        draft.index = null
      }

      if (draft.index === sortMarkers.length) {
        const destination = currentMarkers.filter((ele) => ele.content.type === 'Destination')
        if (destination.length < 1) {
          draft.data = {
            destination: {
              zipCode: zip.address.postalCode,
              city: zip.address.label.replace(', United States', ''),
              state: zip.address.stateCode,
              digitZip3: zip.address.postalCode.substr(0, 3),
            },
          }
          draft.destination = zip.address.postalCode.substring(0, 3)
          const addMarker = {
            coordinate: { lat: zip.position.lat, lng: zip.position.lng },
            content: {
              type: 'Destination',
              city: zip.address.city,
              zipCode: zip.address.postalCode,
            },
            icon: PingLocation,
            city: draft.index,
          }

          currentMarkers[draft.index] = addMarker
          draft.markers = currentMarkers
          draft.routeMarkers = [...currentMarkers, ...currentStopMarkers]
          draft.stopMarkers = currentStopMarkers
          draft.index = null
        } else {
          draft.data = {
            destination: {
              zipCode: zip.address.postalCode,
              city: zip.address.label.replace(', United States', ''),
              state: zip.address.stateCode,
              digitZip3: zip.address.postalCode.substr(0, 3),
            },
          }
          draft.destination = zip.address.postalCode.substring(0, 3)
          const addMarker = {
            coordinate: { lat: zip.position.lat, lng: zip.position.lng },
            content: {
              type: 'Destination',
              city: zip.address.city,
              zipCode: zip.address.postalCode,
            },
            icon: PingLocation,
          }
          const newWayPoint = { ...currentMarkers.filter((ele) => ele.content.type === 'Destination')[0] }

          newWayPoint.content.type = 'Way Point'

          currentStopMarkers.push(newWayPoint)
          currentMarkers.pop()
          currentMarkers = [...currentMarkers, addMarker]
          draft.markers = currentMarkers
          draft.routeMarkers = [...currentMarkers, ...currentStopMarkers]
          draft.stopMarkers = currentStopMarkers
          draft.index = null
        }
      }

      if (sortMarkers[draft.index]) {
        draft.data = {
          destination: {
            zipCode: zip.address.postalCode,
            city: zip.address.label.replace(', United States', ''),
            state: zip.address.stateCode,
            digitZip3: zip.address.postalCode.substr(0, 3),
          },
        }
        const { type } = sortMarkers[draft.index].content
        if (type === 'Origin') {
          draft.origin = zip.address.postalCode.substring(0, 3)
        }
        if (type === 'Destination') {
          draft.destination = zip.address.postalCode.substring(0, 3)
        }

        const addMarker = {
          coordinate: { lat: zip.position.lat, lng: zip.position.lng },
          content: {
            type: sortMarkers[draft.index].content.type,
            city: zip.address.city,
            zipCode: zip.address.postalCode,
          },
          icon: PingLocation,
        }

        sortMarkers[draft.index] = addMarker

        const markrs = sortMarkers.filter((item) => item.content.type !== 'Way Point')
        const stops = sortMarkers.filter((item) => item.content.type === 'Way Point')
        draft.markers = markrs
        draft.stopMarkers = stops
        draft.routeMarkers = [...markrs, ...stops]
      } else if (draft.index !== null) {
        draft.data = {
          wayPoint: {
            city: zip.address.label.replace(', United States', ''),
            zipCode: zip.address.postalCode,
          },
        }
        draft.stopMarkers = [
          ...draft.stopMarkers,
          {
            coordinate: { lat: zip.position.lat, lng: zip.position.lng },
            content: {
              type: 'Way Point',
              city: zip.address.city,
              zipCode: zip.address.postalCode,
            },
            icon: PingLocation,
          },
        ]
      }
    }),
    [failureGetZipHere]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetZipHere]: produce((draft, { payload: { message, fetching, error, success, routeMarkers } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.routeMarkers = routeMarkers
      draft.data = []
    }),
    [removeStopMarkers]: produce((draft, { payload: { stopMarkers } }) => {
      draft.stopMarkers = stopMarkers
    }),
    [saveRouteMarkers]: produce((draft, { payload: { routeMarkers } }) => {
      draft.routeMarkers = routeMarkers
    }),
    [updateMarkers]: produce((draft, { payload: { markers } }) => {
      draft.markers = markers
    }),
    [loadRouteMarkers]: produce((draft, { payload: { dataMarkers } }) => {
      draft.routeMarkers = dataMarkers.routeMarkers
      draft.markers = dataMarkers.markers
      draft.stopMarkers = dataMarkers.stopMarkers
    }),
    [loadSonarData]: produce((draft, { payload: { origin, destination } }) => {
      draft.origin = origin
      draft.destination = destination
    }),
    [finishRate]: produce((draft, { payload: { markers, stopMarkers, data } }) => {
      draft.markers = markers
      draft.stopMarkers = stopMarkers
      draft.data = data
    }),
    [resetRate]: produce(
      (
        draft,
        {
          payload: {
            message,
            fetching,
            error,
            success,
            data,
            origin,
            destination,
            markers,
            routeMarkers,
            stopMarkers,
            index,
          },
        }
      ) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.success = success
        draft.data = data
        draft.origin = origin
        draft.destination = destination
        draft.markers = markers
        draft.routeMarkers = routeMarkers
        draft.stopMarkers = stopMarkers
        draft.index = index
      }
    ),
  },
  defaultState
)

export default getZipHere
