import dateManager from 'utils/dateManager'

export default function (state, action) {
  switch (action.type) {
    case 'pagination':
      return {
        ...state,
        params: { ...state.params, page: action.payload.page },
      }
    case 'filters':
      return {
        ...state,
        params: {
          ...(action.payload.text && { text: action.payload.text }),
          ...(action.payload.createdAt && {
            createdAt: dateManager(action.payload.createdAt[0]._d, action.payload.createdAt[1]._d),
          }),
          ...(action.payload.updatedAt && {
            updatedAt: dateManager(action.payload.updatedAt[0]._d, action.payload.updatedAt[1]._d),
          }),
        },
      }
    case 'push':
      return {
        ...state,
        push: {
          ...state.push,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
