import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CustomerList from 'containers/customers/ListCustomers'
import UserProfile from 'containers/users/UserProfile'
import UserListPage from './UserCreate'

const Rates = () => {
  const { login } = useSelector((state) => ({ login: state.login.data.roleId.name }))

  return (
    <Switch>
      <Route exact path="/profile" component={UserProfile} />
      {login === 'Admin' && (
        <>
          <Route exact path="/users" component={UserListPage} />
          <Route exact path="/customers" component={CustomerList} />
        </>
      )}
    </Switch>
  )
}

export default Rates
