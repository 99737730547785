import styled from 'styled-components'
import Table from 'antd/lib/table'
import { Select } from 'antd'

export const AntdTable = styled(Table)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 10px 16px 10px;
  }
  .ant-table-thead > tr > th {
    background-color: ${(props) => (props.bgHeader === '' ? '#fff' : props.bgHeader)};
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;
    .ant-checkbox-wrapper {
      height: 0;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #002555;
    border-color: #002555;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #002555;

    &::after {
      width: 110%;
      top: 50%;
      transform: translate(-50%, -50%) scale(-0.5);
      background-color: #ffffff;
      height: 4px;
    }
  }
  table {
    border-bottom: 1px solid #d9d9d9;

    border-collapse: separate;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f9fafe;
    border-color: rgba(0, 0, 0, 0.03);
  }

  td.ant-table-cell.ant-table-cell-ellipsis {
    line-height: 20px;
  }

  td.ant-table-cell {
    line-height: 20px;
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover {
    background: pink;
    border-color: rgba(0, 0, 0, 0.03);
  }

  && table thead {
    position: sticky;
    z-index: 999;
    display: ${(props) => props.header};
  }
  && table thead {
    inset-block-start: 0;
  }
  && thead > tr > th {
    border-bottom: #d9d9d9 solid 1px;
  }
  && tbody {
    width: 100px !important;
    border-top: #d9d9d9 solid 1px;
    overflow-x: hidden;
    overflow-y: auto !important;
    height: ${(props) => (props.heightAntTable === '' ? '' : props.heightAntTable)};
  }
  && tbody > tr:hover > td {
    background: #f9fafe;
  }

  .ant-select .ant-select-selector {
    border-radius: 8px;
    border-color: ${(props) =>
      props?.theme?.colors?.dark_blue?.default ? props.theme.colors.dark_blue.default : ''};
  }

  .ant-checkbox-wrapper {
    height: ${(props) => props.alignRowTable};
    margin-top: -19px;
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background-color: #f9fafe;
    height: 90px;
  }
`

export const AntdSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #002555 !important;
  }
`

export const TableContainer = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  overflow-x: auto;
  border: ${(props) => (props.borderTableNone ? '' : '#d9d9d9 solid 1px')};
  border-radius: 4px;
  padding-bottom: 12px;
  background-color: white;
`
