import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Checkbox from 'antd/lib/checkbox'
import Text from 'antd/lib/typography/Text'
import { pick } from 'lodash/object'
import { concat } from 'lodash/array'
import { isEmpty } from 'lodash/lang'
import { sortBy } from 'lodash/collection'
import { useDispatch, useSelector } from 'react-redux'
import TitleDrawer from 'components/TitleDrawer'
import SelectOption from 'components/SelectOption'
import DrawerItem from 'components/Form/DrawerItem'
import PrimarySubmit from 'components/PrimarySubmit'
import FloatLabel from 'components/Label/FloatLabel'
import CategoriesAddedTable from 'components/CategoriesAddedTable'
import notify from 'utils/notify'
import { stringRules } from 'utils/inputRules'
import { BsPlusSquareFill } from 'react-icons/bs'
import colors from 'providers/theme/config/colorPallete'
import { requestGetRules } from 'store/duck/getRules.duck'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import { requestGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { requestGetMasterRules } from 'store/duck/getMasterRules.duck'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { clearCreateRuleset, requestCreateRuleset } from 'store/duck/createRuleSet.duck'
import { clearGetRulesetById, requestGetRulesetById } from 'store/duck/getRulesetById.duck'
import { requestGetRuleSetsHistory } from 'store/duck/getRuleSetsHistory.duck'
import StyledTabs from 'components/Tabs'
import {
  MULTI_STEPS,
  RULE_DATE_RANGE,
  RULE_GEOGRAPHIC,
  RULE_LENGTH,
  RULE_SETS,
  RULE_SIMPLE,
  RULE_EQUIPMENT,
  RULE_MARKET,
} from 'utils/constants'
import getNonMasterMultiStepRules from 'utils/getNonMasterMultiStepRules'
import { FiPauseCircle } from 'react-icons/fi'
import jsonDiff from 'utils/jsonDiff'
import HistoryItems from 'components/HistoryItem'
import TextInput from 'components/Form/TextInput'
import DropdownApiInput from 'components/Form/DropdownApiInput'
import { EnumStatus } from 'utils/getStatus'
import { debounce } from 'lodash'
import { clearReturnRuleset, requestReturnRuleset } from 'store/duck/returnRuleSet.duck'
import filterRulesToRuleset from 'utils/filterRulesToRuleset'
import filterArrayByProps from 'utils/filterArrayByProps'
import SelectEntitiesWithChips from './utils/SelectEntitiesWithChips'
import ClosablePill from './utils/ClosablePill'
import Divider from './utils/Divider'
import useGetRulesIds from '../../ListRules/utils/useGetRulesIds'
import { StyledModal } from '../../ListMultiStep/utils/CreateMultistepRule/styles'

const defaultValues = {
  name: '',
  customer: '',
  ruleType: RULE_GEOGRAPHIC,
  blockRatesOnSameWeekend: true,
}

const CreateRulesetForm = ({
  onSubmit,
  queryParams,
  loadedRulesetData,
  editRuleset,
  status,
  pauseStatus = () => '',
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const {
    fetching: fetchingCustomers,
    error: errorCustomers,
    message: errorMessageCustomers,
    customers,
  } = useSelector((state) => state.getCustomers)
  const {
    fetching: fetchingGetRules,
    rules: oneCategoryRules,
    error: errorGetRules,
    message: errorMessageGetRules,
  } = useSelector((state) => state.getRules)
  const { multiSteps = [], error: errorGetMultiSteps, fetching: fetchingGetMultiSteps } = useSelector(
    (state) => state.getMultiSteps
  )
  const fetchedMasterRules = useSelector((state) => state.getMasterRules)

  const [expandedRowKey, setExpandedRowKey] = useState(defaultValues.ruleType)
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [ruleType, setRuleType] = useState(defaultValues.ruleType)
  const [showMasterRules, setShowMasterRules] = useState(true)

  const setDropdownOptionsRef = useRef(null)
  const [dropdownGeographicInState, setDropdownGeographicInState] = useState([])
  const [selectedGeographicInState, setSelectedGeographicInState] = useState([])
  const [dropdownGeographicInZip, setDropdownGeographicInZip] = useState([])
  const [selectedGeographicInZip, setSelectedGeographicInZip] = useState([])
  const [dropdownGeographicOutState, setDropdownGeographicOutState] = useState([])
  const [selectedGeographicOutState, setSelectedGeographicOutState] = useState([])
  const [dropdownGeographicOutZip, setDropdownGeographicOutZip] = useState([])
  const [selectedGeographicOutZip, setSelectedGeographicOutZip] = useState([])

  const [dropdownLength, setDropdownLength] = useState([])
  const [selectedLength, setSelectedLength] = useState([])

  const [dropdownDatePickup, setDropdownDatePickup] = useState([])
  const [selectedDatePickup, setSelectedDatePickup] = useState([])
  const [dropdownDateDelivery, setDropdownDateDelivery] = useState([])
  const [selectedDateDelivery, setSelectedDateDelivery] = useState([])

  const [dropdownSimplePickup, setDropdownSimplePickup] = useState([])
  const [selectedSimplePickup, setSelectedSimplePickup] = useState([])
  const [dropdownSimpleDelivery, setDropdownSimpleDelivery] = useState([])
  const [selectedSimpleDelivery, setSelectedSimpleDelivery] = useState([])

  const [dropdownEquipment, setDropdownEquipment] = useState([])
  const [selectedEquipment, setSelectedEquipment] = useState([])

  const [selectedMultistep, setSelectedMultistep] = useState([])

  const [selectedMarketPickup, setSelectedMarketPickup] = useState([])
  const [dropdownMarketPickup, setDropdownMarketPickup] = useState([])

  const [selectedMarketDelivery, setSelectedMarketDelivery] = useState([])
  const [dropdownMarketDelivery, setDropdownMarketDelivery] = useState([])

  const masterRules = useMemo(() => {
    return fetchedMasterRules
  }, [fetchedMasterRules])

  useEffect(() => {
    if (oneCategoryRules && setDropdownOptionsRef.current) {
      setDropdownOptionsRef.current(oneCategoryRules)
    }
  }, [oneCategoryRules])

  const handleValuesChange = (changedValues) => {
    if (changedValues.ruleType) {
      setRuleType(changedValues.ruleType)
    }
  }

  const submitCreateRuleset = (formData) => {
    const rules = concat(
      [],
      selectedGeographicInState,
      selectedGeographicInZip,
      selectedGeographicOutState,
      selectedGeographicOutZip,
      selectedLength,
      selectedDatePickup,
      selectedDateDelivery,
      selectedSimplePickup,
      selectedSimpleDelivery,
      selectedEquipment,
      selectedMarketPickup,
      selectedMarketDelivery
    )

    onSubmit({
      ...formData,
      rules,
      multisteps: selectedMultistep,
      customers: selectedCustomers,
    })
  }

  const addCustomer = () => {
    if (selectedCustomer) {
      setSelectedCustomers((prev) => [...prev, selectedCustomer])

      form.setFieldsValue({ customer: null })
    }
  }

  const removeCustomer = (idToRemove) => {
    setSelectedCustomers((prev) => prev.filter((item) => item._id !== idToRemove))
  }

  const addMarginAndFlatDropdownOptions = (item) => ({
    ...item,
    value: item.name,
    label: `${item.name} (Percentage ${item.rule.margin}% - Flat ${item.rule.flat})`,
  })

  const addMarginAndFlatToMultistepDropdownOptions = (item) => ({
    ...item,
    value: item.multistepName,
    label: `${item.multistepName} (Percentage ${item.margin}% - Flat ${item.flat})`,
  })

  const getSelectedMasterRules = (RULE_TYPE, rulesIds = {}) =>
    (masterRules.data?.[RULE_TYPE] ?? [])
      .filter((rule) => !rulesIds.typeRuleId || rule.typeRuleId._id === rulesIds.typeRuleId)
      .filter(
        (rule) =>
          !rulesIds.typeShippingId ||
          (rule.rule.typeShippingId._id
            ? rule.rule.typeShippingId._id === rulesIds.typeShippingId
            : rule.rule.typeShippingId === rulesIds.typeShippingId)
      )
      .filter(
        (rule) =>
          !rulesIds.typeReferenceGeographicId ||
          (rule.rule.typeReferenceGeographicId._id
            ? rule.rule.typeReferenceGeographicId._id === rulesIds.typeReferenceGeographicId
            : rule.rule.typeReferenceGeographicId === rulesIds.typeReferenceGeographicId)
      )

  const getSimpleMasterRules = (RULE_TYPE, rulesIds = {}) => {
    return (masterRules.data?.[RULE_TYPE] ?? [])
      .filter((item) => (item !== null ? !rulesIds.typeRuleId || item.typeRuleId._id === rulesIds.typeRuleId : ''))
      .filter((rule) =>
        rule.rule.typeShippingId._id
          ? rule.rule.typeShippingId._id === rulesIds.typeShippingId
          : rule.rule.typeShippingId === rulesIds.typeShippingId
      )
  }

  const getMultiStepMasterRules = () => {
    return masterRules.data.Multisteps ?? []
  }

  useEffect(() => {
    if (loadedRulesetData) {
      form.setFieldsValue({
        name: loadedRulesetData.rulesetName,
        ignoreMasterRules: loadedRulesetData.ignoreMasterRules,
        blockRatesOnSameWeekend: loadedRulesetData.blockRatesOnSameWeekend ?? true,
      })

      setShowMasterRules(!loadedRulesetData.ignoreMasterRules)

      setSelectedCustomers(
        loadedRulesetData.customers.map((customer) => ({ _id: customer._id, value: customer.name }))
      )
      const getSelectedRules = (RULE_TYPE, rulesIds = {}) =>
        loadedRulesetData.rules
          .filter((rule) => (rule !== null ? rule.ruleModel === RULE_TYPE : ''))
          .filter((rule) =>
            rule !== null ? !rulesIds.typeRuleId || rule.typeRuleId._id === rulesIds.typeRuleId : ''
          )
          .filter((rule) =>
            rule !== null
              ? !rulesIds.typeShippingId || rule.rule.typeShippingId._id === rulesIds.typeShippingId
              : ''
          )
          .filter((rule) =>
            rule !== null
              ? !rulesIds.typeReferenceGeographicId ||
                rule.rule.typeReferenceGeographicId._id === rulesIds.typeReferenceGeographicId
              : ''
          )
          .map(addMarginAndFlatDropdownOptions)

      const filters = {
        ruleModel: RULE_GEOGRAPHIC,
      }

      const newSelectedInState = filterRulesToRuleset(
        loadedRulesetData.rules,
        filters,
        loadedRulesetData.ignoreMasterRules === true,
        queryParams.geographic.inState
      )
      const newSelectedInZip = filterRulesToRuleset(
        loadedRulesetData.rules,
        filters,
        loadedRulesetData.ignoreMasterRules === true,
        queryParams.geographic.inZip
      )

      const newSelectedOutState = filterRulesToRuleset(
        loadedRulesetData.rules,
        filters,
        loadedRulesetData.ignoreMasterRules === true,
        queryParams.geographic.outState
      )
      const newSelectedOutZip = filterRulesToRuleset(
        loadedRulesetData.rules,
        filters,
        loadedRulesetData.ignoreMasterRules === true,
        queryParams.geographic.outZip
      )
      setSelectedGeographicInState(newSelectedInState)
      setSelectedGeographicInZip(newSelectedInZip)
      setSelectedGeographicOutState(newSelectedOutState)
      setSelectedGeographicOutZip(newSelectedOutZip)
      const existsAnyGeographicRule =
        newSelectedInState.length + newSelectedInZip.length + newSelectedOutState.length + newSelectedOutZip.length

      const newSelectedDateDelivery = getSelectedRules(RULE_DATE_RANGE, queryParams.dateRange.inbound)
      const newSelectedDatePickup = getSelectedRules(RULE_DATE_RANGE, queryParams.dateRange.outbound)
      setSelectedDateDelivery(newSelectedDateDelivery)
      setSelectedDatePickup(newSelectedDatePickup)
      const existsAnyDateRule = newSelectedDateDelivery.length + newSelectedDatePickup.length

      const newSelectedLength = getSelectedRules(RULE_LENGTH, queryParams.length)
      setSelectedLength(newSelectedLength)
      const existsAnyLengthRule = newSelectedLength.length

      const simpleFilter = {
        ruleModel: RULE_SIMPLE,
        crossRule: false,
      }
      const selectSimpleRule = (rulesIds = {}) =>
        filterArrayByProps(simpleFilter, loadedRulesetData.rules)
          .filter((item) =>
            item !== null ? !rulesIds.typeRuleId || item.typeRuleId._id === rulesIds.typeRuleId : ''
          )
          .filter((rule) =>
            rule.rule.typeShippingId._id
              ? rule.rule.typeShippingId._id === rulesIds.typeShippingId
              : rule.rule.typeShippingId === rulesIds.typeShippingId
          )
          .map(addMarginAndFlatDropdownOptions)
      const newSelectedSimpleDelivery = selectSimpleRule(queryParams.simple.inbound)
      const newSelectedSimplePickup = selectSimpleRule(queryParams.simple.outbound)

      setSelectedSimpleDelivery(newSelectedSimpleDelivery)
      setSelectedSimplePickup(newSelectedSimplePickup)
      const existsAnySimpleRule = newSelectedSimpleDelivery.length + newSelectedSimplePickup.length

      const marketFilter = {
        ruleModel: RULE_MARKET,
        crossRule: false,
      }
      const selectMarketRule = (rulesIds = {}) =>
        filterArrayByProps(marketFilter, loadedRulesetData.rules)
          .filter((item) =>
            item !== null ? !rulesIds.typeRuleId || item.typeRuleId._id === rulesIds.typeRuleId : ''
          )
          .filter((rule) =>
            rule.rule.typeShippingId._id
              ? rule.rule.typeShippingId._id === rulesIds.typeShippingId
              : rule.rule.typeShippingId === rulesIds.typeShippingId
          )
          .map(addMarginAndFlatDropdownOptions)
      const newSelectedMarketDelivery = selectMarketRule(queryParams.market.inbound)
      const newSelectedMarketPickup = selectMarketRule(queryParams.market.outbound)

      setSelectedMarketDelivery(newSelectedMarketDelivery)
      setSelectedMarketPickup(newSelectedMarketPickup)
      const existsAnyMarketRule = newSelectedMarketDelivery.length + newSelectedMarketPickup.length

      const MultiSteps = getNonMasterMultiStepRules(loadedRulesetData?.multisteps)

      const newSelectedMultistep = MultiSteps.map(addMarginAndFlatToMultistepDropdownOptions)
      setSelectedMultistep(newSelectedMultistep)
      const existsAnyMultiStep = newSelectedMultistep.length

      const newSelectedEquipment = getSelectedRules(RULE_EQUIPMENT)
      setSelectedEquipment(newSelectedEquipment)
      const existsAnyEquipment = newSelectedEquipment.length

      const existingCategoriesWithAtLeastOneRule = [
        existsAnyDateRule ? RULE_DATE_RANGE : undefined,
        existsAnyGeographicRule ? RULE_GEOGRAPHIC : undefined,
        existsAnyLengthRule ? RULE_LENGTH : undefined,
        existsAnyMultiStep ? MULTI_STEPS : undefined,
        existsAnySimpleRule ? RULE_SIMPLE : undefined,
        existsAnyEquipment ? RULE_EQUIPMENT : undefined,
        existsAnyMarketRule ? RULE_MARKET : undefined,
      ].filter(Boolean)

      if (existingCategoriesWithAtLeastOneRule.length > 0) {
        setRuleType(existingCategoriesWithAtLeastOneRule[0])
        setExpandedRowKey(existingCategoriesWithAtLeastOneRule[0])
      }
    }
  }, [
    form,
    loadedRulesetData,
    queryParams.dateRange.inbound,
    queryParams.dateRange.outbound,
    queryParams.geographic.inState,
    queryParams.geographic.inZip,
    queryParams.geographic.outState,
    queryParams.geographic.outZip,
    queryParams.length,
    queryParams.simple.inbound,
    queryParams.simple.outbound,
    queryParams.market.inbound,
    queryParams.market.outbound,
  ])

  useEffect(() => {
    if (!loadedRulesetData?.ignoreMasterRules || showMasterRules) {
      dispatch(requestGetMasterRules())
    }
  }, [dispatch, loadedRulesetData, showMasterRules])

  useEffect(() => {
    form.setFieldsValue({ ruleType })
  }, [form, ruleType])

  useEffect(() => {
    setExpandedRowKey(ruleType)

    document
      .querySelector('.ant-modal-header')
      .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [ruleType])

  useEffect(() => {
    if (masterRules.error) {
      notify('error', `Get master rules error! ${masterRules.message}`)
    }
  }, [masterRules.error, masterRules.message])

  useEffect(() => {
    if (errorCustomers) {
      notify('error', `Get customers error! ${errorMessageCustomers}`)
    }
  }, [errorCustomers, errorMessageCustomers])

  useEffect(() => {
    if (errorGetRules) {
      notify('error', `Error getting rules! ${errorMessageGetRules}`)
    }
  }, [errorGetRules, errorMessageGetRules])

  useEffect(() => {
    if (errorGetMultiSteps) {
      notify('error', 'Error getting multi-step rules!')
    }
  }, [errorGetMultiSteps])

  const [textInput, setTextInput] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnTextSearch = useCallback(
    debounce((textToSearch) => setTextInput(textToSearch), 400),
    [setTextInput]
  )

  const searching = (text) => {
    debouncedOnTextSearch(text)
    return dispatch(requestGetCustomers({ text }))
  }

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '14px' }}>
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
            {editRuleset ? 'Edit the information of this RuleSet' : 'Add the information of this new RuleSet'}
          </Text>
        </Col>
        <Col>
          {editRuleset && (
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FiPauseCircle
                onClick={status !== EnumStatus.Paused ? pauseStatus : undefined}
                style={
                  status !== EnumStatus.Paused
                    ? { fontSize: '19px', color: colors.dark_blue.default, cursor: 'pointer' }
                    : { fontSize: '19px', color: colors.light_grey.default, cursor: 'not-allowed' }
                }
              />
              <Text
                underline
                disabled={status === EnumStatus.Paused}
                style={
                  status !== EnumStatus.Paused
                    ? {
                        marginLeft: '7px',
                        color: '#002555',
                        fontWeight: 400,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }
                    : {
                        marginLeft: '7px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }
                }
                onClick={status !== EnumStatus.Paused ? pauseStatus : undefined}
              >
                Pause Rule
              </Text>
            </Row>
          )}
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name="create-ruleset-form"
        initialValues={defaultValues}
        onValuesChange={handleValuesChange}
        onFinish={submitCreateRuleset}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row gutter={26}>
          <Col span={12}>
            <TextInput label="RuleSets Name *" name="name" rules={stringRules('Multi Step-Rule´s name')} />
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <DropdownApiInput
                  label="Assign RuleSet to Customer Company *"
                  formProps={{
                    name: 'customer',
                    rules: [
                      () => ({
                        async validator() {
                          if (selectedCustomers.length === 0) {
                            throw new Error('There should be at least one customer')
                          }
                        },
                      }),
                    ],
                  }}
                  inputProps={{
                    options: sortBy(
                      customers
                        .map((item) => item.customer)
                        .map((customer) => ({ ...customer, value: customer.name }))
                        .map((customer) => pick(customer, ['_id', 'value']))
                        .filter((customer) => !selectedCustomers.map((item) => item._id).includes(customer._id)),
                      'value'
                    ).filter((item) => item.value.toString().toLowerCase().match(textInput)),
                  }}
                  loading={fetchingCustomers}
                  onSearch={searching}
                  onChange={(option) => setSelectedCustomer(option)}
                  onPressEnter={() => addCustomer()}
                />
              </div>

              <BsPlusSquareFill
                style={{
                  fontSize: '18px',
                  color: colors.light_blue.default,
                  marginTop: 12,
                  marginLeft: 18,
                  cursor: 'pointer',
                }}
                onClick={() => addCustomer()}
              />
            </div>
          </Col>
        </Row>

        {selectedCustomers.length > 0 && (
          <div style={{ marginTop: 4 }}>
            <TitleDrawer title="Companies added" />

            <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginTop: 10, flexWrap: 'wrap' }}>
              {selectedCustomers.map((customer) => (
                <ClosablePill
                  key={customer._id}
                  text={customer.value}
                  onClose={() => removeCustomer(customer._id)}
                />
              ))}
            </div>
          </div>
        )}

        <Divider />

        <FloatLabel label="Category *" show>
          <DrawerItem name="ruleType" rules={[{ required: true, message: 'Please select the rule category' }]}>
            <Select size="large">
              <SelectOption key={RULE_DATE_RANGE} value={RULE_DATE_RANGE}>
                Date Range
              </SelectOption>
              <SelectOption key={RULE_EQUIPMENT} value={RULE_EQUIPMENT}>
                Equipment
              </SelectOption>
              <SelectOption key={RULE_GEOGRAPHIC} value={RULE_GEOGRAPHIC}>
                Geographic
              </SelectOption>
              <SelectOption key={RULE_LENGTH} value={RULE_LENGTH}>
                Length of Haul
              </SelectOption>
              <SelectOption key={RULE_MARKET} value={RULE_MARKET}>
                Market
              </SelectOption>
              <SelectOption key={MULTI_STEPS} value={MULTI_STEPS}>
                MultiSteps
              </SelectOption>
              <SelectOption key={RULE_SIMPLE} value={RULE_SIMPLE}>
                Simple
              </SelectOption>
            </Select>
          </DrawerItem>
        </FloatLabel>

        <Divider />

        {ruleType === RULE_GEOGRAPHIC && (
          <>
            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Inbound (State)"
              title="Inbound (State) added"
              name="inboundState"
              options={dropdownGeographicInState.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_GEOGRAPHIC, queryParams.geographic.inState)}
              selectedEntities={selectedGeographicInState}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedGeographicInState(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownGeographicInState

                dispatch(
                  requestGetRules({
                    ...queryParams.geographic.inState,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Inbound (3 DigitZip)"
              title="Inbound (3 DigitZip) added"
              name="inboundZip"
              options={dropdownGeographicInZip.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_GEOGRAPHIC, queryParams.geographic.inZip)}
              selectedEntities={selectedGeographicInZip}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedGeographicInZip(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownGeographicInZip

                dispatch(
                  requestGetRules({
                    ...queryParams.geographic.inZip,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Outbound (State)"
              title="Outbound (State) added"
              name="outboundState"
              options={dropdownGeographicOutState.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_GEOGRAPHIC, queryParams.geographic.outState)}
              selectedEntities={selectedGeographicOutState}
              onChangeSelectedEntities={(newSelectedEntities) =>
                setSelectedGeographicOutState(newSelectedEntities)
              }
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownGeographicOutState

                dispatch(
                  requestGetRules({
                    ...queryParams.geographic.outState,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Outbound (3 DigitZip)"
              title="Outbound (3 DigitZip) added"
              name="outboundZip"
              options={dropdownGeographicOutZip.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_GEOGRAPHIC, queryParams.geographic.outZip)}
              selectedEntities={selectedGeographicOutZip}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedGeographicOutZip(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownGeographicOutZip

                dispatch(
                  requestGetRules({
                    ...queryParams.geographic.outZip,
                    text,
                  })
                )
              }}
            />
          </>
        )}

        {ruleType === RULE_LENGTH && (
          <SelectEntitiesWithChips
            form={form}
            loading={fetchingGetRules}
            label="Rules"
            title="Rules added"
            name="lengthRules"
            options={dropdownLength.map(addMarginAndFlatDropdownOptions)}
            masterRules={getSelectedMasterRules(RULE_LENGTH, queryParams.length)}
            selectedEntities={selectedLength}
            onChangeSelectedEntities={(newSelectedEntities) => setSelectedLength(newSelectedEntities)}
            onSearch={(text) => {
              setDropdownOptionsRef.current = setDropdownLength

              dispatch(
                requestGetRules({
                  ...queryParams.length,
                  text,
                })
              )
            }}
          />
        )}

        {ruleType === RULE_DATE_RANGE && (
          <>
            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Pickup"
              title="Pickup added"
              name="datePickupRules"
              options={dropdownDatePickup.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_DATE_RANGE, queryParams.dateRange.outbound)}
              selectedEntities={selectedDatePickup}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedDatePickup(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownDatePickup

                dispatch(
                  requestGetRules({
                    ...queryParams.dateRange.outbound,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Delivery"
              title="Delivery added"
              name="dateDeliveryRules"
              options={dropdownDateDelivery.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_DATE_RANGE, queryParams.dateRange.inbound)}
              selectedEntities={selectedDateDelivery}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedDateDelivery(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownDateDelivery

                dispatch(
                  requestGetRules({
                    ...queryParams.dateRange.inbound,
                    text,
                  })
                )
              }}
            />
          </>
        )}

        {ruleType === RULE_SIMPLE && (
          <>
            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Pickup"
              title="Pickup added"
              name="simplePickupRules"
              options={dropdownSimplePickup.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSimpleMasterRules(RULE_SIMPLE, queryParams.simple.outbound)}
              selectedEntities={selectedSimplePickup}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedSimplePickup(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownSimplePickup

                dispatch(
                  requestGetRules({
                    ...queryParams.simple.outbound,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Delivery"
              title="Delivery added"
              name="simpleDeliveryRules"
              options={dropdownSimpleDelivery.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSimpleMasterRules(RULE_SIMPLE, queryParams.simple.inbound)}
              selectedEntities={selectedSimpleDelivery}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedSimpleDelivery(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownSimpleDelivery

                dispatch(
                  requestGetRules({
                    ...queryParams.simple.inbound,
                    text,
                  })
                )
              }}
            />
          </>
        )}

        {ruleType === RULE_MARKET && (
          <>
            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Pickup"
              title="Pickup added"
              name="marketPickupRules"
              options={dropdownMarketPickup.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSimpleMasterRules(RULE_MARKET, queryParams.market.outbound)}
              selectedEntities={selectedMarketPickup}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedMarketPickup(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownMarketPickup

                dispatch(
                  requestGetRules({
                    ...queryParams.market.outbound,
                    text,
                  })
                )
              }}
            />

            <Divider />

            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Delivery"
              title="Delivery added"
              name="marketDeliveryRules"
              options={dropdownMarketDelivery.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSimpleMasterRules(RULE_MARKET, queryParams.market.inbound)}
              selectedEntities={selectedMarketDelivery}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedMarketDelivery(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownMarketDelivery
                dispatch(
                  requestGetRules({
                    ...queryParams.market.inbound,
                    text,
                  })
                )
              }}
            />
          </>
        )}

        {ruleType === RULE_EQUIPMENT && (
          <>
            <SelectEntitiesWithChips
              form={form}
              loading={fetchingGetRules}
              label="Equipment"
              title="Equipments added"
              name="equipmentRules"
              options={dropdownEquipment.map(addMarginAndFlatDropdownOptions)}
              masterRules={getSelectedMasterRules(RULE_EQUIPMENT)}
              selectedEntities={selectedEquipment}
              onChangeSelectedEntities={(newSelectedEntities) => setSelectedEquipment(newSelectedEntities)}
              onSearch={(text) => {
                setDropdownOptionsRef.current = setDropdownEquipment

                dispatch(
                  requestGetRules({
                    typeRuleId: queryParams.equipment?.[0].typeRuleId,
                    text,
                  })
                )
              }}
            />
          </>
        )}

        {ruleType === MULTI_STEPS && (
          <SelectEntitiesWithChips
            form={form}
            loading={fetchingGetMultiSteps}
            label="Multisteps"
            title="Multisteps added"
            name="multistepsRules"
            options={multiSteps.map(addMarginAndFlatToMultistepDropdownOptions)}
            selectedEntities={selectedMultistep}
            masterRules={getMultiStepMasterRules()}
            onChangeSelectedEntities={(newSelectedEntities) => setSelectedMultistep(newSelectedEntities)}
            onSearch={(text) => {
              dispatch(
                requestGetMultiSteps({
                  text,
                })
              )
            }}
          />
        )}

        <Divider />

        <div style={{ marginBottom: 12 }}>
          <TitleDrawer title="Categories Added" />
        </div>
        <CategoriesAddedTable
          selectedGeographicInState={selectedGeographicInState}
          selectedGeographicInZip={selectedGeographicInZip}
          selectedGeographicOutState={selectedGeographicOutState}
          selectedGeographicOutZip={selectedGeographicOutZip}
          selectedLength={selectedLength}
          selectedDatePickup={selectedDatePickup}
          selectedDateDelivery={selectedDateDelivery}
          selectedSimplePickup={selectedSimplePickup}
          selectedSimpleDelivery={selectedSimpleDelivery}
          selectedEquipment={selectedEquipment}
          selectedMultistep={selectedMultistep}
          selectedMarketPickup={selectedMarketPickup}
          selectedMarketDelivery={selectedMarketDelivery}
          expandedRowKey={expandedRowKey}
          setExpandedRowKey={setExpandedRowKey}
          setRuleType={setRuleType}
        />
        <Row>
          <Col span={12}>
            <Form.Item name="blockRatesOnSameWeekend" valuePropName="checked">
              <Checkbox style={{ marginBottom: 10, marginTop: 20 }}>
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>
                  Do not allow shipping on the same weekend
                </Text>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const CreateRulesetModal = ({ visible, handleOnClose }) => {
  const dispatch = useDispatch()
  const { queryParams } = useGetRulesIds()
  const [loading, setLoading] = useState(false)
  const { message, error, success, editRuleset, rulesetSelected } = useSelector((state) => state.createRuleSet)
  const { success: successUpdateRuleset } = useSelector((state) => state.updateRuleset)
  const { data: rulesetData } = useSelector((state) => state.getRulesetById)
  const isLoadedRulesetData = !isEmpty(rulesetData)
  const { ruleSets: ruleSetsHistory } = useSelector((state) => state.getRuleSetsHistory)
  const [status, setStatus] = useState('Activated')
  const { success: successReturn } = useSelector((state) => state.returnRuleset)

  useEffect(() => {
    if (successReturn) {
      handleOnClose(false)
      dispatch(clearReturnRuleset())
    }
  }, [successReturn, handleOnClose, dispatch])

  const pauseStatus = () => {
    setStatus(EnumStatus.Paused)
  }

  const submitCreateRuleset = (formData) => {
    setLoading(true)

    const rulesetApiData = {
      rulesetName: formData.name,
      rules: formData.rules.map((item) => item._id),
      multisteps: formData.multisteps.map((item) => item._id),
      customers: formData.customers.map((item) => item._id),
      ignoreMasterRules: formData.ignoreMasterRules,
      blockRatesOnSameWeekend: formData.blockRatesOnSameWeekend,
    }

    if (editRuleset) {
      const previous = { ...rulesetData }
      delete previous.__v
      delete previous.createdAt
      delete previous.deleted
      delete previous.updatedAt
      rulesetApiData.status = status
      previous.customers = previous.customers.map((rule) => rule._id)
      previous.multisteps = previous.multisteps.map((rule) => rule._id)
      previous.rules = previous.rules.map((rule) => rule._id)
      const nextData = jsonDiff(previous, rulesetApiData)

      if (Object.keys(nextData).length > 0) {
        nextData.id = rulesetData._id
        dispatch(requestMessageSnackBar('RuleSet has been successfully edited'))
        dispatch(requestUpdateRuleset(nextData))
      } else {
        setLoading(true)
        handleOnClose(false)
      }
    } else {
      dispatch(requestCreateRuleset(rulesetApiData))
    }
  }

  const handleCancel = useCallback(() => {
    handleOnClose(false)

    dispatch(clearGetRulesetById())
  }, [dispatch, handleOnClose])

  useEffect(() => {
    if (visible && editRuleset) {
      dispatch(requestGetRulesetById(rulesetSelected._id))
      dispatch(requestGetRuleSetsHistory({ id: rulesetSelected._id }))
    }
    if (editRuleset) {
      setStatus(rulesetSelected.status)
    }
  }, [dispatch, editRuleset, rulesetSelected.status, rulesetSelected._id, visible])

  useEffect(() => {
    if (!visible) {
      return
    }

    setLoading(false)
    if (success || error || successUpdateRuleset) {
      if (success) {
        notify('success', 'Ruleset has been successfully created')
      } else if (error) {
        notify('error', `Error getting rules! ${message}`)
      }

      handleCancel()
      dispatch(clearCreateRuleset())
    }
  }, [dispatch, error, handleCancel, message, success, successUpdateRuleset, visible])

  const DEFAULTACTIVEKEYEDITTAB = '1'
  const [activeKeyEditTab, setActiveKeyEditTab] = useState(DEFAULTACTIVEKEYEDITTAB)

  return (
    <StyledModal
      width={900}
      title={<TitleDrawer title={editRuleset ? 'Edit RuleSets' : 'Create RuleSets'} />}
      visible={visible}
      bodyStyle={{ padding: editRuleset ? '0px 24px' : '32px 25px 0px' }}
      afterClose={() => {
        if (editRuleset) {
          setActiveKeyEditTab(DEFAULTACTIVEKEYEDITTAB)
        } else {
          handleCancel()
        }
        dispatch(clearCreateRuleset())
      }}
      onCancel={handleCancel}
      footer={
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ width: '50%' }}>
              <PrimarySubmit
                label={editRuleset ? 'Edit RuleSet' : 'Create RuleSet'}
                formName="create-ruleset-form"
                loading={loading}
              />
            </div>
          </div>
        </div>
      }
      destroyOnClose
    >
      {editRuleset ? (
        <StyledTabs
          activeKey={activeKeyEditTab}
          onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
          tabBarStyle={{ width: '100%' }}
        >
          <StyledTabs.TabPane tab="Rules Info" key="1">
            <CreateRulesetForm
              status={status}
              pauseStatus={pauseStatus}
              onSubmit={submitCreateRuleset}
              queryParams={queryParams}
              loadedRulesetData={isLoadedRulesetData ? rulesetData : undefined}
              editRuleset={editRuleset}
            />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="History" key="2">
            <HistoryItems
              large
              data={ruleSetsHistory}
              category={RULE_SETS}
              rules
              id="rulesetId"
              request={requestReturnRuleset}
            />
          </StyledTabs.TabPane>
        </StyledTabs>
      ) : (
        <CreateRulesetForm
          onSubmit={submitCreateRuleset}
          queryParams={queryParams}
          loadedRulesetData={isLoadedRulesetData ? rulesetData : undefined}
          editRuleset={editRuleset}
        />
      )}
    </StyledModal>
  )
}

export default CreateRulesetModal
