import styled from 'styled-components'
import Select from 'antd/lib/select'

const StyledSelect = styled(Select)`
  padding-top: 2px;

  .ant-select-arrow {
    color: #002555;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-selector span { 
    padding-right: 0px !important;
  }
`

export default StyledSelect
