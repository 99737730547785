import moment from 'moment'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx/xlsx.mjs'

const downloadRates = ({ title, fileName, rates }) => {
  const wb = XLSX.utils.book_new()
  wb.Props = {
    Title: title,
  }
  function returnStops(rateStops) {
    const stops = [...rateStops]
    if (rateStops.length === 0) {
      return ''
    }
    return stops
      .map((stop) => `${stop.origin.city}(${stop.origin.zipCode})`)
      .concat(
        `${rateStops[rateStops.length - 1].destination.city}(${rateStops[rateStops.length - 1].destination.zipCode})`
      )
      .join(' - ')
  }

  wb.SheetNames.push(title)
  wb.Sheets[title] = XLSX.utils.aoa_to_sheet(
    [
      [
        'Customer',
        'Equipment name',
        'Equipment price',
        'Origin zipcode',
        'Origin city',
        'Origin state',
        'Origin digitZip3',
        'Destination zipcode',
        'Destination city',
        'Destination state',
        'Destination digitZip3',
        'Stops with zipcodes',
        'Stops',
        'Pickup Date',
        'Delivery Date',
        'Weight',
        'Total stops',
        'Total milles',
        'Rate',
        'Special services with quantities',
        'Special services',
        'Creation Date',
        'Created By',
        'Active',
      ],
    ].concat(
      rates.map((rate) => [
        rate.customer.name,
        rate.truckTypeId.name,
        rate.truckTypeId.price,
        rate.lane.origin.zipCode,
        rate.lane.origin.city,
        rate.lane.origin.state,
        rate.lane.origin.digitZip3,
        rate.lane.destination.zipCode,
        rate.lane.destination.city,
        rate.lane.destination.state,
        rate.lane.destination.digitZip3,
        returnStops(rate.stops),
        JSON.stringify(rate.stops),
        moment(rate.pickupDate).startOf('day').toDate(),
        moment(rate.deliveryDate).startOf('day').toDate(),
        rate.weight,
        rate.totalStops,
        rate.totalMilles,
        rate.rate,
        rate.specialServices
          .map((specialService) => `${specialService.serviceId.name}(${specialService.quantity})`)
          .join(', '),
        JSON.stringify(rate.specialServices),
        moment(rate.createdAt).startOf('day').toDate(),
        rate.createdBy ? `${rate.createdBy.name} ${rate.createdBy.lastName}` : '',
        rate.active ? 'true' : 'false',
      ])
    )
  )

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
}

export default downloadRates
