import React, { useState, useEffect, useCallback } from 'react'
import Table from 'components/Table'
import HeaderTableDataLake from 'components/HeaderTableDataLake'
import filterDatalake from 'utils/filterDatalake'
import Columns from './columns'

export default function DatalakeTable({ Datalake = {} }) {
  const [data, setData] = useState([])
  const [filter, usefilter] = useState('items')

  const changefilter = useCallback(() => usefilter(filter === 'items' ? '' : 'items'), [filter])

  useEffect(() => {
    const size = Object.keys(Datalake)
    if (size.length > 0) {
      setData(filterDatalake(Datalake, filter))
    }
  }, [Datalake, filter])

  return (
    <>
      <HeaderTableDataLake filter={changefilter} />
      <Table
        addHeight="-50"
        bgHeader="#F9FAFE"
        columns={Columns()}
        data={data}
        loading={false}
        rowKey="loadProNumber"
        width="100%"
        padding="4px 0px"
        pagination={false}
        showBulkActions={false}
        borderTableNone
      />
    </>
  )
}
