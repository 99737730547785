import React from 'react'
import ConfirmPassword from '../ConfirmPassword'

const ActiveAccount = () => {
  return (
    <ConfirmPassword
      title="Active your account"
      description="Enter the password to active your account."
      passwordInputLabel="password"
      submitButtonText="Activate Account"
    />
  )
}

export default ActiveAccount
