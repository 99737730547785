import React from 'react'
import StyledTabs from 'components/Tabs'
import ServicesList from 'containers/settings/ServicesList'
import TruckTypeList from 'containers/settings/TruckTypeList'
import TitleList from 'components/TitleList'
import StyledDiv from './styles'

export default function Tabs() {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ margin: '0px', padding: '0px' }}>
        <StyledDiv>
          <TitleList title="General Settings" />
        </StyledDiv>
        <StyledTabs
          height="100%"
          defaultActiveKey="1"
          tabBarStyle={{ width: '96%', margin: '0px auto', color: '#002555', fontWeight: 600, marginBottom: 15 }}
        >
          <StyledTabs.TabPane tab="Special Services" key="1">
            <ServicesList />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Equipment Type" key="2">
            <TruckTypeList />
          </StyledTabs.TabPane>
        </StyledTabs>
      </div>
    </div>
  )
}
