import React, { useEffect, useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Space from 'antd/lib/space'
import { MdArrowDropDown, MdChevronLeft, MdChevronRight, MdSkipNext, MdSkipPrevious } from 'react-icons/md'
import BulkActions from 'components/BulkActions/BulkActions'
import colors from 'providers/theme/config/colorPallete'
import useWindowSize from 'hooks/useWindowSize'
import { AntdTable, TableContainer, AntdSelect } from './styles'
import { StyledButton } from '../MoreDropdown/styles'
import './style.css'

/**
 *
 * @param {string} columns added column
 */

const Table = ({
  columns,
  data,
  loading,
  expandible,
  pagination,
  onChange,
  onChangeSelectedRows,
  rowKey,
  onRow,
  rowSelection,
  rowClassName,
  width,
  addHeight = 10,
  padding,
  size,
  updatedPagination,
  ruleSelected,
  dispatch,
  status,
  margin = 'auto',
  entityNameForBulk = 'rule',
  entityNameForTextOnTopOfTableForBulk = `${entityNameForBulk}s`,
  header,
  onExpand,
  expandedRowKeys,
  expandedRowRender,
  pauseRequest,
  deleteRequest,
  avoidNotify = false,
  CustomModalDelete,
  openPauseModal,
  openDeleteModal,
  handleData,
  /** Boolean indicating whether the button should render as disabled */
  limitPerPage = 20,
  paginationOptions = ['10', '20', '50', '100', '250'],
  bulkPadding = '',
  bulkMargin = '',
  pauseProps = {},
  deleteProps = {},
  showBulkActions = true,
  customBulkActions,
  bgHeader = '',
  borderTableNone = false,
  heightAntTable,
  alignRowTable,
  locale,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(limitPerPage)
  const [rows, setRows] = useState([])
  const { height: heightWindow } = useWindowSize()

  useEffect(() => {
    if (updatedPagination) {
      onChange({ current: currentPage, limit })
    }
  }, [currentPage, limit, onChange, updatedPagination])

  const handleChangeLimit = (newLimit) => {
    setLimit(Number(newLimit))
    setCurrentPage(1)
  }

  useEffect(() => {
    handleChangeLimit(limit)
    setRows([])
  }, [limit, ruleSelected])

  useEffect(() => {
    setCurrentPage(1)
  }, [status])

  const rowSelec = {
    selectedRowKeys: rows,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKey) => {
      setRows(selectedRowKey)
    },
  }

  const cleanSelected = () => {
    setRows([])
  }

  useEffect(() => {
    onChangeSelectedRows(rows)
  }, [onChangeSelectedRows, rows])

  const heightResponsive = Math.floor((heightWindow ?? 50) - 324 + addHeight)
  return (
    <>
      <BulkActions
        padding={bulkPadding}
        margin={bulkMargin}
        data={rows}
        ruleSelected={ruleSelected}
        dispatch={dispatch}
        cleanData={cleanSelected}
        pauseRequest={pauseRequest}
        deleteRequest={deleteRequest}
        avoidNotify={avoidNotify}
        CustomModalDelete={CustomModalDelete}
        openPauseModal={openPauseModal}
        openDeleteModal={openDeleteModal}
        handleData={handleData}
        entityNameForBulk={entityNameForBulk}
        entityNameForTextOnTopOfTableForBulk={entityNameForTextOnTopOfTableForBulk}
        pauseProps={pauseProps}
        deleteProps={deleteProps}
        showBulkActions={showBulkActions}
        customBulkActions={customBulkActions}
      />
      <TableContainer margin={margin} width={width} padding={padding} borderTableNone={borderTableNone}>
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            height: heightResponsive,
            paddingBottom: '0px',
          }}
        >
          <AntdTable
            expandedRowKeys={[expandedRowKeys]}
            onExpand={onExpand}
            header={header}
            bgHeader={bgHeader}
            size={size}
            expandable={expandible}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={updatedPagination ? false : pagination}
            onChange={updatedPagination ? undefined : onChange}
            rowKey={rowKey}
            onRow={onRow}
            rowSelection={rowSelection ? rowSelec : null}
            rowClassName={rowClassName}
            expandIconColumnIndex={-1}
            expandedRowRender={expandedRowRender}
            expandIcon={() => null}
            heightAntTable={heightAntTable}
            alignRowTable={alignRowTable}
            locale={locale}
            tableLayout="fixed"
          />
        </div>
        {updatedPagination && (
          <Row justify="end" align="middle" style={{ marginTop: 12, marginRight: 16 }}>
            <Col>
              <span style={{ color: colors.dark_grey.default, fontSize: 16 }}>Rows per page</span>
              <AntdSelect
                value={limit}
                size="large"
                onChange={handleChangeLimit}
                style={{ width: 100, marginLeft: 15 }}
                suffixIcon={
                  <MdArrowDropDown
                    size="1.2rem"
                    color={colors.dark_blue.default}
                    style={{ position: 'absolute', top: 0, left: 0, transform: 'translate(-25%, -25%)' }}
                  />
                }
              >
                {paginationOptions.map((p) => (
                  <AntdSelect.Option value={p}>{p}</AntdSelect.Option>
                ))}
              </AntdSelect>
              <span style={{ marginLeft: 24, fontSize: 16, color: colors.dark_grey.default }}>
                {1 + (currentPage - 1) * limit}-{Math.min(currentPage * limit, pagination.total)} of{' '}
                {pagination.total}
              </span>
              <Space size="middle" style={{ marginLeft: 32 }}>
                <StyledButton disabled={currentPage === 1} shape="circle">
                  <MdSkipPrevious
                    size="24px"
                    onClick={() => setCurrentPage(() => 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={currentPage === 1} shape="circle">
                  <MdChevronLeft
                    size="24px"
                    onClick={() => setCurrentPage((page) => page - 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={1 + currentPage * limit > pagination.total} shape="circle">
                  <MdChevronRight
                    size="24px"
                    onClick={() => setCurrentPage((page) => page + 1)}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
                <StyledButton disabled={1 + currentPage * limit > pagination.total} shape="circle">
                  <MdSkipNext
                    size="24px"
                    onClick={() => setCurrentPage(() => Math.ceil(pagination.total / limit))}
                    style={{ verticalAlign: 'middle', marginBottom: 4 }}
                  />
                </StyledButton>
              </Space>
            </Col>
          </Row>
        )}
      </TableContainer>
    </>
  )
}

export default Table
