import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  origin: '',
  destination: '',
}

export const {
  requestGetCityFromZip,
  successGetCityFromZip,
  failureGetCityFromZip,
} = createActions({
  REQUEST_GET_CITY_FROM_ZIP: ( entry ) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    entry,
  }),
  SUCCESS_GET_CITY_FROM_ZIP: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_CITY_FROM_ZIP: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getZipHere = handleActions(
  {
    [requestGetCityFromZip]: produce((draft, { payload: { fetching, data, error, success, target } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.target = target
    }),
    [successGetCityFromZip]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      const params = new URLSearchParams(data.config.url)
      const url = params && params.get('query').split(' ')[0]
      const temporal = []
      data.data.suggestions.map((item, index) => {
        if (item.address.postalCode === url )
          temporal.push({
            key: `${item.address.postalCode}-${index}`,
            value: `${item.address.city}, ${item.address.state} - ${item.address.postalCode}`,
            searchparameter:  `${item.address.city}, ${item.address.state}, ${item.address.postalCode}`,
          })
        return null
      })
      const mapTemporal = new Map(temporal.map((item) => {
        return [item.value, item]
      }))
      const filteredTemporal = [...mapTemporal.values()]
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error   
      draft.data = temporal.length > 1 ? filteredTemporal : temporal 
    }),
    [failureGetCityFromZip]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default getZipHere
