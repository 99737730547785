import React, { useState } from 'react'

import { FloatLabelContainer, Label } from './styles'

const FloatLabel = ({ children, label, show, style }) => {
  const [focus, setFocus] = useState(false)

  return (
    <FloatLabelContainer onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <Label float={focus || show} leftBeforeFloat={style?.leftBeforeFloat}>
        {label}
      </Label>
    </FloatLabelContainer>
  )
}

export default FloatLabel
