import styled from 'styled-components'

export const FloatLabelContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
`

export const Label = styled.label`
  font-size: ${(props) => (props.float ? '12px !important' : '16px !important')};
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  color: ${(props) => (props.float && props?.theme?.colors?.dark_grey?.default ? props.theme.colors.dark_grey.default : '#a1a1a1')};
  position: absolute;
  pointer-events: none;
  left: ${(props) => (props.float ? '12px' : props.leftBeforeFloat ?? '12px')};
  top: ${(props) => (props.float ? '-8px' : '8px')};
  transition: 0.2s ease all;
  background-image: ${(props) =>
    props.float ? 'linear-gradient(to top, #ffffff, #fefeff, #fcfcfe, #fbfbfe, #f9fafe)' : undefined};
  box-sizing: ${(props) => (props.float ? 'border-box' : undefined)};
  z-index: 1;
`
