import React from 'react'
import Input from 'antd/lib/input'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'

const TextAreaInput = ({ label, placeholder, ...props }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrawerItem style={{ marginBottom: 0 }} {...props}>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => (
            <Input.TextArea style={{ width: '100%', fontSize: 16 }} rows={3} value={value} onChange={onChange} />
          )}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default TextAreaInput
