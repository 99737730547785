import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Col from 'antd/lib/col'
import Graph from 'containers/rates/Graph'
import ColorLegendSmall from 'components/ColorLegend'
import { setGraphics } from 'store/duck/getRate.duck'
import { ContainerGraphics, HeaderGraph } from './styles'

const colors = ['#002555', '#3594FF', '#8C54FF', '#F6A15B', '#F44D6C', '#56B158', '#d73027', '#542788']

const Graphics = () => {
  const dispatch = useDispatch()
  const { filterParams, success, rate, graphic } = useSelector((state) => ({
    years: state.getRate.years,
    filterParams: state.getRate.filter,
    success: state.getRate.success,
    rate: state.getRate.graphics,
    graphic: state.getRate.rate.graphics,
  }))

  

  useEffect(() => {
    if (success) dispatch(setGraphics())
  }, [dispatch, filterParams, success])

  return (
    <ContainerGraphics>
      {rate.length > 0 && (
        <HeaderGraph>
          <span style={{ fontSize: '16px', fontWeight: 700, color: '#002555' }}>Lane History</span>
        </HeaderGraph>
      )}
      <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
        <Col>
          {success && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '16px', fontWeight: 700, color: '#002555' }}>Sources</span>
              <ColorLegendSmall colors={colors} data={graphic} />
            </div>
          )}
        </Col>
        <Graph data={graphic} success={success} />
      </div>
    </ContainerGraphics>
  )
}

export default Graphics
