import moment from 'moment'

const rangeManager = (firstDate, secondDate) => {
  const newDateOne = moment(new Date(firstDate)).format('YYYY-MM-DD')
  const newDateTwo = moment(new Date(secondDate)).format('YYYY-MM-DD')
  const rangeDate = `${newDateOne},${newDateTwo}`
  return rangeDate
}

export const editFormat = (entry) => {
  return moment(entry).format('YYYY-MM')
}

export default rangeManager
