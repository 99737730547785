import PropTypes from 'prop-types'
import Table from './Table'

Table.propTypes = {
  /**
   * Components used in story
   * Displays Prop Tables with these components
   * @default []
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }).isRequired
  ).isRequired,
  data: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.bool.isRequired]),
  loading: PropTypes.bool.isRequired,
  expandible: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.bool.isRequired]),
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  onChangeSelectedRows: PropTypes.func,
  bordered: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.string,
  rowSelection: PropTypes.bool,
  ruleSelected: PropTypes.string,
  status: PropTypes.string.isRequired,
  header: PropTypes.string,
  bgHeader: PropTypes.string,
  pauseRequest: PropTypes.func,
  limitPerPage: PropTypes.number,
  paginationOptions: PropTypes.arrayOf(PropTypes.string),
}

Table.defaultProps = {
  status: '',
  rowSelection: false,
  ruleSelected: '',
  /** Boolean indicating whether the button should render as disabled */
  size: 'default',
  bgHeader: '',
  rowKey: 'key',
  expandible: false,
  loading: false,
  bordered: true,
  pagination: { position: ['bottomLeft'] },
  width: '90%',
  padding: '16px',
  onChange: () => {},
  onChangeSelectedRows: () => {},
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      filters: [
        {
          text: 'London',
          value: 'London',
        },
        {
          text: 'Sidney',
          value: 'Sidney',
        },
        {
          text: 'New York',
          value: 'New York',
        },
      ],
      onFilter: (value, record) => record.address.indexOf(value) === 0,
    },
  ],
  data: [
    {
      _id: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
    },
    {
      _id: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
    },
    {
      _id: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '4',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '5',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '6',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '7',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '8',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '9',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '10',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '11',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
    {
      _id: '12',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
  ],
}

export default Table
