import React, { useState } from 'react'
import Modal from 'components/Modal'
import Row from 'antd/lib/row'
import Timeline from 'antd/lib/timeline'
import Col from 'antd/lib/col'
import StyledTabs from 'components/Tabs'
import SpecialServicesModal from 'containers/dashboard/SpecialServicesModal'
import returnStops from 'utils/returnStops'
import RulesModal from 'containers/dashboard/RulesModal'
import Collapse from 'components/Collapse'
import cleanServices from 'utils/cleanServices'
import { Fuel } from 'assets/svg'
import { LinkButton } from 'components/Button/TextButton'
import { Divider } from 'antd'
import TextPrimary, { StyledCol } from 'components/Text/TextPrimary'
import { toNumber } from 'lodash'
import { TitleValue, TextValue, SmallBox } from './styles'

const convertToNumber = (string = '', fixed = '') => {
  if (typeof string === 'string') {
    return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  }
  return 0
}

export default function ModalQuote({ visible = false, onCancel = () => '', data = {} }) {
  const { Panel } = Collapse
  const margin = data?.customerRateInfo?.margin / 100
  const flat = data?.customerRateInfo?.flat
  const rate = data?.rate

  const { details } = data || {}
  const { base_rate: base, ruleAdjustment, multiStepAdjustment, internal_adjustment: gsDatalake } = details || {}
  const { datRate, gsRate } = base || {}

  const [show, setShow] = useState(false)

  const validateDataLake = () => {
    if (gsDatalake && gsDatalake.rate) {
      if (gsDatalake.rate !== '$0.00') {
        return false
      }
    }
    return true
  }

  const validateDATTooltip = () => {
    if (datRate && datRate.datLow) {
      return datRate.datLow.rate === '$0.00'
    }
    return true
  }
  const validateRateBasic = () => {
    if (details && details.priceBase) {
      return details.priceBase === '$0.00'
    }
    return true
  }
  const validatePrice = () => {
    if (data.specialServices) {
      return data.specialServices.length === 0
    }
    return true
  }

  const validateRateCost = () => {
    if (details && details.rateCost) {
      return details.rateCost === '$0.00'
    }
    return true
  }
  const validateGS = () => {
    if (gsRate) {
      return gsRate.rate === '$0.00'
    }
    return true
  }

  // const inNumber = (string, fixed) => `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  const inNumber = (string = '', fixed = '') => {
    if (typeof string === 'string') {
      return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
    }
    return 0
  }
  const datRateTotal = validateDATTooltip() ? 0 : inNumber(datRate.total)
  const gsRateTotal = validateGS() ? 0 : inNumber(gsRate?.total)
  const sumDG = toNumber(datRateTotal) + toNumber(gsRateTotal)

  return (
    <Modal onCancel={onCancel} visible={visible} title="View Rate" height="600px" width="550px">
      <StyledTabs defaultActiveKey="1" tabBarStyle={{ width: '100%', marginBottom: 10 }}>
        <StyledTabs.TabPane tab="Info" key="1">
          <Row gutter={[16, 8]} style={{ paddingTop: '15px' }}>
            <Col span={6}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <SmallBox color="#f4f4f5">
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Base Markup</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>${data?.customerRateInfo?.flat}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3', fontSize: '12px' }}>Percentage markup</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.customerRateInfo?.margin}%</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Weight</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.weight}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Mileage</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data.totalMilles}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Customer</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.customer?.name}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>

                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Pick up Date</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.pickupDate}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>

                <Col span={24}>
                  <SmallBox color="#f4f4f5 ">
                    <Row>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TitleValue style={{ color: '#B0B4C3' }}>Delivery Date</TitleValue>
                      </Col>
                      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextValue style={{ color: '#464851' }}>{data?.deliveryDate}</TextValue>
                      </Col>
                    </Row>
                  </SmallBox>
                </Col>
              </Row>
            </Col>
            <Col span={18}>
              <Row>
                <Col
                  span={24}
                  style={{ display: 'flex', backgroundColor: 'white', padding: '10px', borderRadius: '4px' }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex' }}>
                      <TitleValue>Rate #</TitleValue>
                      <TextValue>{data?._id}</TextValue>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Equipment Type</TextValue>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>{data?.truckTypeId?.name}</TextValue>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Carrier Cost</TextValue>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>{data?.details?.rateCost}</TextValue>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Customer Rate</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>${data?.rate}</TextValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              marginBottom: '2px',
                            }}
                          >
                            <TextValue style={{ fontSize: '11px', color: 'black' }}>
                              {data?.LH ? `$${data?.LH} (LH)` : ''}
                            </TextValue>
                            {data?.LH ? (
                              <img
                                alt=""
                                style={{ marginLeft: '3px', width: '14px', height: '14px', paddingBottom: '1px' }}
                                src={Fuel}
                              />
                            ) : (
                              ''
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Margin ($)</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>
                            $
                            {data?.details?.rateCost
                              ? (convertToNumber(data?.details?.rateCost) * margin + flat).toFixed(2)
                              : '0'}
                          </TextValue>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between">
                        <Col span={12}>
                          <TextValue>Est Percentage (%)</TextValue>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <TextValue>
                            {data?.details?.rateCost
                              ? (
                                  ((convertToNumber(data?.details?.rateCost) * margin + flat) / rate) *
                                  100
                                ).toFixed(1)
                              : ''}
                            %
                          </TextValue>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24} style={{ marginTop: '10px' }}>
                  <TitleValue style={{ paddingBottom: '10px' }}>Lane</TitleValue>
                  <div style={{ padding: '0px 20px' }}>
                    <Timeline style={{ fontSize: '14px' }}>
                      {data.lane &&
                        returnStops(data).map((item) => <Timeline.Item key={item}>{item}</Timeline.Item>)}
                    </Timeline>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="Details" key="2">
          <Row gutter={[16, 8]} justify="space-between" style={{ marginLeft: '0' }}>
            <StyledCol span={24}>BASE RATE</StyledCol>

            <StyledCol span={7}>DAT Weight</StyledCol>
            <Col span={8}>
              <LinkButton onClick={() => setShow(true)}>See Details</LinkButton>
            </Col>
            <Col span={1}>=</Col>
            <Col span={7}>
              <StyledCol>{validateDATTooltip() ? '$0' : convertToNumber(datRate.total)}</StyledCol>
            </Col>

            <StyledCol span={6}>Greenscreens</StyledCol>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Col span={2} style={{ display: 'flex' }}>
                {gsRate?.confidencePercentage ?? 0}%
              </Col>
              <Col span={2}>
                <Col style={{ display: 'flex' }}>X</Col>
              </Col>
              <Col span={5} style={{ display: 'flex' }}>
                {validateGS() ? 'N/A' : convertToNumber(gsRate?.rate)}
              </Col>
            </div>
            <Col span={1}>=</Col>
            <Col span={7}>
              <StyledCol>{validateGS() ? 0 : convertToNumber(gsRate?.total)} </StyledCol>
            </Col>

            <StyledCol span={7}>Base total</StyledCol>
            <Col span={7}>
              <StyledCol>
                {' '}
                {/* 100 */}
                {`$${sumDG}`}
              </StyledCol>
            </Col>
            <StyledCol span={24}>ADJUSMENT</StyledCol>
            <StyledCol span={6}>Datalake</StyledCol>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Col style={{ display: 'flex' }}>
                {validateDataLake() ? '0' : inNumber(gsDatalake?.confidencePercentage, 2)}%
              </Col>
              <Col span={2}>
                <Col style={{ fontSize: '16px', paddingLeft: '5px' }}>X</Col>
              </Col>
              <Col span={5} style={{ display: 'flex' }}>
                {validateDataLake() ? '$0' : convertToNumber(gsDatalake?.rate)}
              </Col>
            </div>
            <Col span={1}>=</Col>
            <Col span={7}>
              <StyledCol style={{ display: 'flex' }}>
                {validateDataLake() ? '$0' : convertToNumber(gsDatalake?.total)}
              </StyledCol>
            </Col>
            <StyledCol span={6}>Base total</StyledCol>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Col span={2} style={{ display: 'flex' }}>
                {`${inNumber(details?.base_rate?.confidencePercentage ?? '', 2) ?? 0}%`}
              </Col>
              <Col span={2}>
                <Col style={{ fontSize: '16px' }}>X</Col>
              </Col>
              <Col span={5} style={{ display: 'flex' }}>
                {`$${sumDG}`}
              </Col>
            </div>
            <Col span={1}>=</Col>
            <Col span={7} style={{ paddingLeft: '36px' }}>
              <StyledCol> {validateRateBasic() ? `N/A` : convertToNumber(details.base_rate.total)}</StyledCol>
            </Col>
            <StyledCol span={7}>Adjusted total</StyledCol>
            <Col span={7} style={{ paddingLeft: '36px' }}>
              <StyledCol>{validateRateBasic() ? `N/A` : convertToNumber(details.priceBase)}</StyledCol>
            </Col>
            <Divider style={{ margin: '12px 0', background: 'rgba(217, 217, 217, 0.4)' }} />
          </Row>
          <Row gutter={[16, 32]}>
            <Col xl={10} xxl={9} style={{ paddingRight: '0' }}>
              <span>
                {ruleAdjustment &&
                  multiStepAdjustment &&
                  `Rules Adjustment (${ruleAdjustment.length + multiStepAdjustment.length})`}
              </span>
              <span>
                {ruleAdjustment && ruleAdjustment.length + ruleAdjustment.length === 1
                  ? ' rule applied '
                  : ' rules applied'}
              </span>
            </Col>
            <Col span={8} offset={6} style={{ paddingRight: '0' }}>
              <RulesModal ruleAdjustment={ruleAdjustment ?? []} multiStepAdjustment={multiStepAdjustment ?? []} viewRate />
            </Col>
          </Row>
          <Collapse defaultActiveKey="1" ghost expandIconPosition="right">
            <Panel header="Trend Adjustment" key="1">
              <Row gutter={[16, 8]} justify="space-between">
                <div style={{ display: 'flex', width: '100%' }}>
                  <Col style={{ width: '67%', paddingRight: '0' }}>Extra Stops</Col>
                  <Col style={{ width: '100%', paddingLeft: '0' }}>
                    <StyledCol>{data?.totalStops ?? 0}</StyledCol>
                  </Col>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Col style={{ width: '67%', paddingRight: '0' }}>Special Services</Col>
                  <Col style={{ width: '58%', paddingLeft: '0' }}>
                    <StyledCol>
                      {validatePrice() ? `N/A` : `$${convertToNumber(details.priceSpecialServices ?? '')}`}
                    </StyledCol>
                  </Col>
                  <Col span={6} style={{ paddingRight: '15px', paddingLeft: '0' }}>
                    <SpecialServicesModal services={cleanServices(data.specialServices ?? [])} />
                  </Col>
                </div>
              </Row>
            </Panel>
          </Collapse>
          <Row gutter={[16, 32]} justify="space-between">
            <Col span={4} style={{ whiteSpace: 'nowrap' }}>
              <StyledCol>TOTAL COST</StyledCol>
            </Col>
            <Col xxl={{ span: 7 }} xl={{ span: 8 }} style={{ paddingLeft: '46px' }}>
              <StyledCol>{validateRateCost() ? `N/A` : `$${convertToNumber(details?.rateCost)}`}</StyledCol>
            </Col>
          </Row>
        </StyledTabs.TabPane>
        <Modal
          title="DAT Pricing Details"
          width={600}
          visible={show}
          onCancel={() => {
            setShow(!show)
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={7}>DAT Contract</Col>
            <Col span={3}>{datRate?.datContract?.confidencePercentage ?? 0}%</Col>
            <Col span={1}>
              <TextPrimary>X</TextPrimary>
            </Col>
            <Col span={4}>{convertToNumber(datRate?.datContract?.rate ?? '')}</Col>
            <Col span={1}>=</Col>
            <Col span={7}>
              <TextPrimary>{convertToNumber(datRate?.datContract?.total ?? '')}</TextPrimary>
            </Col>

            <Col span={7}>DAT High</Col>
            <Col span={3}>{datRate?.datHigh?.confidencePercentage ?? 0}%</Col>
            <Col span={1}>
              <TextPrimary>X</TextPrimary>
            </Col>
            <Col span={4}>{convertToNumber(datRate?.datHigh?.rate ?? '')}</Col>
            <Col span={1}>=</Col>
            <Col span={7}>
              <TextPrimary>{convertToNumber(datRate?.datHigh?.total ?? '')}</TextPrimary>
            </Col>

            <Col span={7}>DAT Low</Col>
            <Col span={3}>{datRate?.datLow?.confidencePercentage ?? '0'}%</Col>
            <Col span={1}>
              <TextPrimary>X</TextPrimary>
            </Col>
            <Col span={4}>{convertToNumber(datRate?.datLow?.rate ?? '')}</Col>
            <Col span={1}>=</Col>
            <Col span={7}>
              <TextPrimary>{convertToNumber(datRate?.datLow?.total ?? '')}</TextPrimary>
            </Col>

            <Col span={7}>DAT Weight</Col>
            <Col span={3}>{datRate?.datAvg?.confidencePercentage ?? 0}%</Col>
            <Col span={1}>
              <TextPrimary>X</TextPrimary>
            </Col>
            <Col span={4}>{convertToNumber(datRate?.datAvg?.rate ?? '')}</Col>
            <Col span={1}>=</Col>
            <Col span={7}>
              <TextPrimary>{convertToNumber(datRate?.datAvg?.total ?? '')}</TextPrimary>
            </Col>
          </Row>
          <div style={{ paddingBottom: '1.5rem !important', width: '1rem', height: '1rem' }} />
        </Modal>
      </StyledTabs>
    </Modal>
  )
}
