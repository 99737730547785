import React, { useMemo, useCallback } from 'react'
import Spin from 'antd/lib/spin'
import Grid from 'antd/lib/grid'
import { ResponsiveLine } from '@nivo/line'
import StyledContainer from './styles'

const { useBreakpoint } = Grid

const Graph = ({ data = [], success = false }) => {
  const screen = useBreakpoint()

  const chartColors = useMemo(
    () => ({
      Dat: '#002555',
      Sonar: '#F6A15B',
    }),
    []
  )
  const getColor = useCallback((bar) => chartColors[bar.id], [chartColors])


  return (
    <StyledContainer
      style={
        screen.xxl
          ? { display: 'flex', width: '100%', height: '350px' }
          : { display: 'flex', width: '100%', height: '350px', overflow: 'scroll hidden' }
      }
    >
      {success ? (
        <div style={{ position: 'relative', width: 'inherit', height: 'inherit', display: 'flex' }}>
          <ResponsiveLine
            data={data}
            width={530}
            margin={{ top: 10, right: 0, bottom: 40, left: 40 }}
            xScale={{ type: 'point' }}
            colors={getColor}
            axisLeft={{
              format: (value) => `$ ${value}`,
            }}
            yScale={{
              type: 'linear',
              stacked: false,
              min: 'auto',
              max: 'auto',
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            theme={{
              fontSize: 8.5,
            }}
            enableGridX
            enableGridY
            pointSize={6}
            pointColor="#ffffff"
            pointBorderWidth={1}
            lineWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableSlices="x"
            areaOpacity={0.1}
            useMesh
            enableArea
            sliceTooltip={({ slice }) => {
              return (
                <div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                    zIndex: '200',
                  }}
                >
                  <strong style={{ textJustify: 'center' }}>{slice.points[0].data.x}</strong>
                  {slice.points.map((point) => (
                    <React.Fragment key={point.id}>
                      <div
                        style={{
                          color: point.serieColor,
                          padding: '3px 0',
                        }}
                      >
                        <strong>{point.serieId} Total</strong> [{point.data.y}]
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              )
            }}
          />
        </div>
      ) : (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <Spin />
        </div>
      )}
    </StyledContainer>
  )
}

export default Graph
