import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import { DatePicker, Select } from 'antd/lib'
import Input from 'antd/lib/input'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import { MdClose } from 'react-icons/md'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { FiPauseCircle } from 'react-icons/fi'
import colors from 'providers/theme/config/colorPallete'
import { requestGetTruckTypeList } from 'store/duck/getTruckTypeList.duck'
import { descriptionRules, stringRules } from 'utils/inputRules'
import handleMarketState from 'utils/handleMarketState'
import { useDispatch, useSelector } from 'react-redux'
import { requestCreateRule, clearData, startCreateRule, addRule } from 'store/duck/createRule.duck'
import { requestUpdateRule, clearUpdateRule } from 'store/duck/updateRule.duck'
import { requestGetRuleHistory, clearGetRuleHistory } from 'store/duck/getRuleHistory.duck'
import { requestDeleteRule } from 'store/duck/deleteRule.duck'
import { clearReturn, requestReturnVersionRule } from 'store/duck/returnVersionRule.duck'
import { clearValidateState } from 'store/duck/validateState.duck'
import StyledTabs from 'components/Tabs'
import TitleDrawer from 'components/TitleDrawer'
import HistoryItems from 'components/HistoryItem'
import SelectOption from 'components/SelectOption'
import PrimarySubmit from 'components/PrimarySubmit'
import DrawerItem from 'components/Form/DrawerItem'
import { InputGeneric } from 'components/Input'
import FloatLabel from 'components/Label/FloatLabel'
import NumberInput from 'components/Form/NumerInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import AddToRulesetsModal from 'components/AddToRulesetsModal'
import useAutocompleteState from 'hooks/useAutocompleteState'
import useAutocompleteZip from 'hooks/useAutocompleteZip'
import { EnumStatus } from 'utils/getStatus'
import { requestGetMarkets } from 'store/duck/getMarkets.duck'
import {
  RULE_GEOGRAPHIC,
  RULE_SIMPLE,
  RULE_DATE_RANGE,
  RULE_LENGTH,
  EQUIPMENT_TYPE,
  RULE_MARKET,
} from 'utils/constants'
import notify from 'utils/notify'
import days from 'utils/days.json'
import jsonDiff from 'utils/jsonDiff'
import CreateMultistepRuleModal from 'containers/rules/ListMultiStep/utils/CreateMultistepRule'
import parseStatesToHashMap from 'utils/parseStatesToHashMap'
import { StyledText, StyledCol, StyledModal } from './styles' 

const getNumberWithoutMileageSuffix = (value) => parseInt(value, 10)

const defaultValues = {
  name: '',
  ruleType: null,
  ruleShipping: null,
  margin: null,
  flat: null,
  guide: null,
  mileageEnd: null,
  mileageInit: null,
  day: null,
  date: [],
  description: '',
  crossRule: false,
  expirationDate: null,
  market: null,
  state: null,
}

const ControlInput = ({ value, onChange, hasValue, children }) => {
  useEffect(() => {
    hasValue(value)
  }, [hasValue, value])

  return children({ value, onChange })
}

const CreateRuleForm = ({
  variant,
  onCloseModal,
  typesId,
  shippingRule,
  referenceRule,
  columnsParams,
  autocompleteZip,
  formName,
  onChangeLoading,
  clickedOpenCreateMultiStep,
  clickedAddToRulesets,
  setClickedOpenCreateMultiStep,
  setClickedAddToRulesets,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { success, error, message, editRule, ruleSelected, resetForm } = useSelector((state) => state.createRule)
  const { success: successUpdate, error: errorUpdate, message: messageUpdate } = useSelector(
    (state) => state.updateRule
  )
  const [loading, setLoading] = useState(false)
  const [ruleType, setRuleType] = useState('')
  const [ruleShipping, setRuleShipping] = useState('')
  const [dataZipCode, validStatus, zipField, setZip, handleZipChange] = autocompleteZip
  const [
    dataGeographic,
    validState,
    stateField,
    setPlace,
    handleStateChange,
    setValidStatus,
  ] = useAutocompleteState()
  const { trucktypes } = useSelector((state) => state.getTruckTypeList)
  const [crossRule, setcrossRule] = useState(false)

  const [status, setStatus] = useState('Activated')
  const allowCloseModalRef = useRef(true)

  const [createMultistepVisible, setCreateMultistepVisible] = useState(false)
  const [addToRulesetsVisible, setAddToRulesetsVisible] = useState(false)

  const [margin, setMargin] = useState('')
  const [flat, setFlat] = useState('')
  const [marketState, setMArketState] = useState('')
  const [marketId, setMarketId] = useState('')
  const { data: markets } = useSelector((state) => state.markets)

  useEffect(() => {
    if (marketState !== '') {
      dispatch(requestGetMarkets(marketState))
    }
  }, [marketState, dispatch])

  useEffect(() => {
    if (editRule) {
      setStatus(ruleSelected.status)
      if (ruleSelected?.rule?.typeReferenceEquipmentId) {
        setRuleType('truckType')
        form.setFieldsValue({ typeReferenceEquipmentId: ruleSelected?.rule?.typeReferenceEquipmentId })
      }
      if (ruleSelected?.rule?.market && dataGeographic.length > 0) {
        const statesToSearch = parseStatesToHashMap(dataGeographic)
        form.setFieldsValue({ state: statesToSearch[ruleSelected?.rule?.market?.state] })
      }
    }
  }, [ruleSelected.rule, form, editRule, ruleSelected.status, dispatch, dataGeographic])

  const pauseStatus = () => {
    setStatus(EnumStatus.Paused)
  }

  const handleCloseModal = useCallback(() => {
    if (allowCloseModalRef.current) {
      onCloseModal(false)
    }
    allowCloseModalRef.current = true
  }, [onCloseModal])

  useEffect(() => {
    if (success) {
      notify('success', 'Rule has been successfully created')
      handleCloseModal()
    } else if (message && error) {
      notify('error', `Error creating rule: ${message}`)
    } else {
      return
    }
    setLoading(false)
    dispatch(clearData())
  }, [form, error, message, success, dispatch, handleCloseModal])

  useEffect(() => {
    if (successUpdate && editRule) {
      notify('success', 'Rule has been successfully edited')
      onCloseModal(false)
    } else if (errorUpdate) {
      notify('error', `Error updating rule: ${messageUpdate}`)
    } else {
      return
    }
    setLoading(false)
    dispatch(clearUpdateRule())
  }, [errorUpdate, successUpdate, dispatch, form, messageUpdate, editRule, onCloseModal])

  useEffect(() => {
    if (!editRule && resetForm) {
      form.setFieldsValue(defaultValues)
      setRuleType('')
      setRuleShipping('')
      setLoading(false)
    }
  }, [editRule, form, resetForm])

  useLayoutEffect(() => {
    if (loading || !editRule || Object.keys(ruleSelected).length <= 0) {
      return
    }
    const statesToSearch = parseStatesToHashMap(dataGeographic)
    const loadRuleType = {
      [RULE_GEOGRAPHIC]: 'geographicRule',
      [RULE_SIMPLE]: 'simpleRule',
      [RULE_DATE_RANGE]: 'dateRangeRule',
      [RULE_LENGTH]: 'lengthRule',
      [EQUIPMENT_TYPE]: 'truckType',
      [RULE_MARKET]: 'marketRule',
    }[ruleSelected.ruleModel]

    const [reference] =
      Object.entries(referenceRule).find(([, typeShippingId]) => {
        return ruleSelected.rule.typeReferenceGeographicId === typeShippingId
      }) ?? []
    const [typeShipping] =
      Object.entries(shippingRule).find(([, typeShippingId]) => {
        return ruleSelected.rule.typeShippingId === typeShippingId
      }) ?? []

    const simpleAndDateRangeShipping = typeShipping === 'inShipping' ? 'inbound' : 'outbound'
    const shipping = {
      geographicRule: {
        referenceState: simpleAndDateRangeShipping === 'inbound' ? 'inState' : 'outState',
        referenceZip: simpleAndDateRangeShipping === 'inbound' ? 'inZip' : 'outZip',
      }[reference],
      simpleRule: simpleAndDateRangeShipping,
      dateRangeRule: simpleAndDateRangeShipping,
      marketRule: simpleAndDateRangeShipping,
    }[loadRuleType]

    const valueExpirationDate = ruleSelected.expirationDate ? ruleSelected.expirationDate : null
    const validValue = valueExpirationDate !== null && valueExpirationDate !== ''
    const expirationDated = validValue ? moment.utc(ruleSelected.expirationDate) : null
    const setFormData = {
      name: ruleSelected.name,
      ruleType: loadRuleType,
      ruleShipping: shipping,
      margin: ruleSelected.rule.margin,
      flat: ruleSelected.rule.flat,
      mileageEnd: ruleSelected.rule.mileageEnd ?? null,
      mileageInit: ruleSelected.rule.mileageInit ?? null,
      day: ruleSelected.rule.day ?? null,
      guide: ruleSelected.rule.guide ?? null,
      expirationDate: expirationDated,
    }
    if (loadRuleType === 'dateRangeRule') {
      setFormData.startDate = moment.utc(ruleSelected.rule.dateInit)
      setFormData.endDate = moment.utc(ruleSelected.rule.dateEnd)
      setFormData.description = ruleSelected.rule.description
    }
    if (loadRuleType === 'marketRule') {
      setFormData.market = ruleSelected?.rule?.market?.marketAreaName ?? null
      setFormData.state = statesToSearch[ruleSelected?.rule?.market?.state]
    }
    form.setFieldsValue(setFormData)
    setRuleType(setFormData.ruleType)
    setRuleShipping(setFormData.ruleShipping)

    setcrossRule(ruleSelected.crossRule ?? false)
  }, [form, editRule, shippingRule, ruleSelected, referenceRule, loading, dataGeographic])

  useEffect(() => {
    onChangeLoading(loading)
  }, [loading, onChangeLoading])

  const disabledDate = (current) => {
    return current < moment().subtract(2, 'months')
  }

  const submitCreateRule = (data) => {
    if (!createMultistepVisible) {
      setLoading(true)
    } else {
      setLoading(false)
    }

    setValidStatus('')
    const dateEdited = moment(data.expirationDate)
    const expirationDated = dateEdited.isValid() ? data.expirationDate._d : null

    const ruleData = {
      name: data.name,
      expirationDate: expirationDated,
      rule: {
        margin: data.margin,
        flat: data.flat,
      },
    }
    switch (data.ruleType) {
      case 'geographicRule': {
        ruleData.typeRuleId = typesId.typeGeographic
        ruleData.rule.guide = data.guide
        if (editRule) ruleData.rule._id = ruleSelected.rule._id
        switch (data.ruleShipping) {
          case 'outState':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceState
            break
          case 'outZip':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceZip
            break
          case 'inState':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceState
            break
          case 'inZip':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceZip
            break
          default:
            break
        }
        break
      }
      case 'lengthRule': {
        ruleData.typeRuleId = typesId.typeLength
        ruleData.rule.mileageInit = getNumberWithoutMileageSuffix(data.mileageInit)
        ruleData.rule.mileageEnd = getNumberWithoutMileageSuffix(data.mileageEnd)
        break
      }
      case 'simpleRule': {
        ruleData.typeRuleId = typesId.typeSimple
        ruleData.rule.day = data.day
        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'dateRangeRule': {
        ruleData.typeRuleId = typesId.typeDate
        ruleData.rule.dateInit = data.startDate._d
        ruleData.rule.dateEnd = data.endDate._d
        ruleData.rule.description = data.description
        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'truckType': {
        ruleData.typeRuleId = typesId.typeEquipment
        ruleData.rule.typeReferenceEquipmentId = data.typeReferenceEquipmentId
        break
      }
      case 'marketRule': {
        ruleData.typeRuleId = typesId.typeMarket
        ruleData.rule.market = marketId

        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }

      default:
        break
    }
    ruleData.crossRule = crossRule
    if (editRule) {
      // eslint-disable-next-line prefer-const
      let previous = { ...ruleSelected }
      if (data.ruleType === 'marketRule') { 
        previous = Object.assign(previous, { rule: { ...previous.rule, market: ruleSelected?.rule?.market?._id } })
      }
      delete previous.__v
      delete previous.createdAt
      delete previous.createdBy
      delete previous.deleted
      delete previous.multiStepsRuleInfo
      delete previous.ruleModel
      delete previous.rulesetsInfo
      delete previous.updatedAt
      previous.typeRuleId = previous.typeRuleId._id
      ruleData.status = status
      const nextData = jsonDiff(previous, ruleData)
      const sizeObject = [...Object.keys(nextData)]
      if (sizeObject.length > 0) {
        nextData.id = ruleSelected._id
        dispatch(requestUpdateRule(nextData))
      } else if (!createMultistepVisible) {
        onCloseModal(false)
      }
    } else {
      dispatch(requestCreateRule([ruleData]))
    }
  }

  const handleValuesChange = useCallback(
    (changedValues) => {
      if (resetForm) {
        dispatch(startCreateRule())
      }

      const getDefaultRuleShipping = {
        geographicRule: 'outState',
        simpleRule: 'outbound',
        dateRangeRule: 'outbound',
        marketRule: 'outbound',
      }

      const newRuleShipping = changedValues.ruleType
        ? getDefaultRuleShipping[changedValues.ruleType]
        : changedValues.ruleShipping ?? form.getFieldValue('ruleShipping')
      const newRuleType = changedValues.ruleType ?? form.getFieldValue('ruleType')

      form.setFieldsValue({
        ruleType: newRuleType,
        ruleShipping: newRuleShipping,
      })
      setRuleShipping(newRuleShipping)
      setRuleType(newRuleType)
      setMargin(changedValues.margin ?? form.getFieldValue('margin'))
      setFlat(changedValues.flat ?? form.getFieldValue('flat'))
    },
    [dispatch, form, resetForm]
  )

  useEffect(() => {
    handleValuesChange({ ruleType: columnsParams.type, ruleShipping: columnsParams.shipping })
  }, [columnsParams, handleValuesChange])

  useEffect(() => {
    if (ruleType === 'truckType') {
      dispatch(requestGetTruckTypeList({ active: true }))
    }
  }, [dispatch, ruleType])

  const renderShippingFilter = () => {
    if (ruleType === 'lengthRule' || ruleType === 'truckType') {
      return null
    }
    if (ruleType === 'geographicRule') {
      return (
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Type *" show>
              <DrawerItem name="ruleShipping">
                <Select disabled={ruleType === '' || editRule} size="large">
                  <SelectOption key="outState" value="outState">
                    Pick up State
                  </SelectOption>
                  <SelectOption key="outZip" value="outZip">
                    Pick up Zip
                  </SelectOption>
                  <SelectOption key="inState" value="inState">
                    Delivery State
                  </SelectOption>
                  <SelectOption key="inZip" value="inZip">
                    Delivery Zip
                  </SelectOption>
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
      )
    }
    return (
      <Row gutter={10}>
        <Col span={24}>
          <FloatLabel label="Type *" show>
            <DrawerItem name="ruleShipping">
              <Select disabled={ruleType === ''} placeholder="Type *" size="large">
                <SelectOption key="outbound" value="outbound">
                  Pick up
                </SelectOption>
                <SelectOption key="inbound" value="inbound">
                  Delivery
                </SelectOption>
              </Select>
            </DrawerItem>
          </FloatLabel>
        </Col>
      </Row>
    )
  }

  const renderTypeEquipment = () => {
    if (ruleType !== 'truckType') {
      return null
    }
    if (ruleType === 'truckType') {
      return (
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Type *" show>
              <DrawerItem
                name="typeReferenceEquipmentId"
                rules={[{ required: true, message: 'Please enter the Equipment Type' }]}
              >
                <Select disabled={ruleType === ''} size="large">
                  {trucktypes.map((item, index) => (
                    <SelectOption value={`${item._id}`} key={index}>
                      {item.name}
                    </SelectOption>
                  ))}
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
      )
    }
    return (
      <Row gutter={10}>
        <Col span={24}>
          <FloatLabel label="Type *" show>
            <DrawerItem name="ruleShipping">
              <Select disabled={ruleType === ''} placeholder="Type *" size="large">
                <SelectOption key="outbound" value="outbound">
                  Pick up
                </SelectOption>
                <SelectOption key="inbound" value="inbound">
                  Delivery
                </SelectOption>
              </Select>
            </DrawerItem>
          </FloatLabel>
        </Col>
      </Row>
    )
  }

  const renderGeographicRule = () => {
    const zipInput = () => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="Zip *">
            {(hasValue) => (
              <DrawerItem
                name="guide"
                rules={[{ required: true, message: 'Please select a 3 Digit zip' }]}
                hasFeedback
                validateStatus={zipField === 0 && validStatus}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      defaultActiveFirstOption={false}
                      placeholder="Zip"
                      notFoundContent={null}
                      onSearch={setZip}
                      onChange={(...props) => {
                        handleZipChange(props[0], 0)
                        onChange(...props)
                      }}
                      options={dataZipCode}
                      value={value}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )

    const stateInput = ({ stateFieldToValidate }) => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="State *">
            {(hasValue) => (
              <DrawerItem
                name="guide"
                rules={[{ required: true, message: 'Please select the state' }]}
                hasFeedback
                validateStatus={stateField === stateFieldToValidate && validState}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      options={dataGeographic}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      onSearch={setPlace}
                      value={value}
                      onChange={(...props) => {
                        handleStateChange(props[0])
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )

    return {
      outState: stateInput({ stateFieldToValidate: 0 }),
      outZip: zipInput(),
      inState: stateInput({ stateFieldToValidate: 0 }),
      inZip: zipInput(),
    }[ruleShipping]
  }

  const renderLengthRule = () => {
    return (
      <Row gutter={10}>
        <Col span={24} style={{ marginBottom: 10 }}>
          <StyledText>If mileage is between</StyledText>
        </Col>

        <Col span={12}>
          <DrawerItem
            name="mileageInit"
            rules={[
              {
                required: true,
                message: 'Please enter a number',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const mileageStart = getNumberWithoutMileageSuffix(value)
                  const mileageEnd = getNumberWithoutMileageSuffix(getFieldValue('mileageEnd') || '0')

                  if (mileageStart >= mileageEnd) {
                    throw new Error('Mileage start should be less than mileage end')
                  }
                },
              }),
            ]}
            dependencies={['mileageEnd']}
          >
            <NumberFormat
              suffix=" mi"
              displayType="input"
              type="text"
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              customInput={(props) => <InputGeneric size="large" {...props} />}
              allowNegative={false}
            />
          </DrawerItem>
        </Col>
        <Col span={12}>
          <DrawerItem
            name="mileageEnd"
            rules={[
              {
                required: true,
                message: 'Please enter a number',
              },
            ]}
          >
            <NumberFormat
              suffix=" mi"
              displayType="input"
              type="text"
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              customInput={(props) => <InputGeneric size="large" {...props} />}
              allowNegative={false}
            />
          </DrawerItem>
        </Col>
      </Row>
    )
  }

  const newDatePicker = (value, onChange) => (
    <DatePicker
      style={{ width: '100%' }}
      placeholder=""
      disabledDate={disabledDate}
      size="large"
      suffixIcon={null}
      value={value}
      onChange={onChange}
    />
  )

  const renderDescription = () => {
    return (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="Description">
            {(hasValue) => (
              <DrawerItem name="description" rules={descriptionRules}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Input.TextArea
                      disabled={ruleType === ''}
                      style={{ width: '100%', fontSize: 16 }}
                      rows={3}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
  }

  const renderDates = () => {
    return (
      <Row gutter={10}>
        <Col span={12}>
          <InputWithLabel label="Start date*">
            {(hasValue) => (
              <DrawerItem name="startDate" rules={[{ required: true, message: 'Please select a start date' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => newDatePicker(value, onChange)}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
        <Col span={12}>
          <InputWithLabel label="End date*">
            {(hasValue) => (
              <DrawerItem name="endDate" rules={[{ required: true, message: 'Please select a end date' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => newDatePicker(value, onChange)}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
  }

  const renderStateMarket = () => {
    const stateInput = ({ stateFieldToValidate }) => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="State *">
            {(hasValue) => (
              <DrawerItem
                name="state"
                hasFeedback
                validateStatus={stateField === stateFieldToValidate && validState}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      options={dataGeographic}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      onSearch={setPlace}
                      value={value}
                      onChange={(...props) => {
                        setMArketState(handleMarketState(props))
                        handleStateChange(props[0])
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
        <Col span={24}>
          <InputWithLabel label="Market *">
            {(hasValue) => (
              <DrawerItem name="market" rules={[{ required: true, message: 'Please select one option' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      options={markets ?? []}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      value={value}
                      onChange={(...props) => {
                        setMarketId(props[1].uid)
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )

    return stateInput({ stateFieldToValidate: 0 })
  }

  const openCreateMultistep = useCallback(async () => {
    await form.validateFields()

    allowCloseModalRef.current = false
    await form.submit()

    setCreateMultistepVisible(true)
  }, [form])

  const openAddToRulesets = useCallback(async () => {
    await form.validateFields()

    setAddToRulesetsVisible(true)
  }, [form])

  useEffect(() => {
    if (clickedOpenCreateMultiStep) {
      openCreateMultistep().then()

      setClickedOpenCreateMultiStep(false)
    }
  }, [clickedOpenCreateMultiStep, openCreateMultistep, setClickedOpenCreateMultiStep])

  useEffect(() => {
    if (clickedAddToRulesets) {
      openAddToRulesets().then()

      setClickedAddToRulesets(false)
    }
  }, [clickedAddToRulesets, openAddToRulesets, setClickedAddToRulesets])

  const changedDefaultValues = useMemo(
    () => ({
      margin,
      flat,
    }),
    [margin, flat]
  )

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: 16 }}>
            {editRule ? 'Edit the information of this Rule' : 'Add the information of this new rule'}
          </Text>
        </Col>
        <Col>
          {editRule && (
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FiPauseCircle
                onClick={status !== EnumStatus.Paused && pauseStatus}
                style={
                  status !== EnumStatus.Paused
                    ? { fontSize: '19px', color: colors.dark_blue.default, cursor: 'pointer' }
                    : { fontSize: '19px', color: colors.light_grey.default, cursor: 'not-allowed' }
                }
              />
              <Text
                underline
                disabled={status === EnumStatus.Paused}
                style={
                  status !== EnumStatus.Paused
                    ? {
                        marginLeft: '7px',
                        color: '#002555',
                        fontWeight: 400,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }
                    : {
                        marginLeft: '7px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }
                }
                onClick={status !== EnumStatus.Paused && pauseStatus}
              >
                Pause Rule
              </Text>
            </Row>
          )}
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={submitCreateRule}
        onValuesChange={handleValuesChange}
        style={{ marginTop: 16 }}
        hideRequiredMark
      >
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Category *" show>
              <DrawerItem name="ruleType" rules={[{ required: true, message: 'Please select the rule category' }]}>
                <Select size="large" disabled={editRule}>
                  <SelectOption key="dateRangeRule" value="dateRangeRule">
                    Date Range
                  </SelectOption>
                  <SelectOption key="truckType" value="truckType">
                    Equipment Type
                  </SelectOption>
                  <SelectOption key="geographicRule" value="geographicRule">
                    Geographic
                  </SelectOption>
                  <SelectOption key="lengthRule" value="lengthRule">
                    Length
                  </SelectOption>
                  <SelectOption key="marketRule" value="marketRule">
                    Market
                  </SelectOption>
                  <SelectOption key="simpleRule" value="simpleRule">
                    Simple
                  </SelectOption>
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
        {renderShippingFilter()}
        {renderTypeEquipment()}
        <Row gutter={10}>
          <Col span={24}>
            <InputWithLabel label="Name *">
              {(hasValue) => (
                <DrawerItem name="name" rules={stringRules('Rule´s name')}>
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => (
                      <InputGeneric disabled={ruleType === ''} size="large" value={value} onChange={onChange} />
                    )}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <InputWithLabel label="End date">
              {(hasValue) => (
                <DrawerItem name="expirationDate">
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => newDatePicker(value, onChange)}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
        </Row>
        {ruleType === 'geographicRule' ? renderGeographicRule() : null}
        {ruleType === 'lengthRule' ? renderLengthRule() : null}
        {ruleType === 'simpleRule' && (
          <Row gutter={10}>
            <Col span={24}>
              <InputWithLabel label="Day *">
                {(hasValue) => (
                  <DrawerItem name="day" rules={[{ required: true, message: 'Please select a day' }]}>
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <Select size="large" value={value} onChange={onChange}>
                          {days.map((entry) => {
                            return (
                              <Select.Option key={entry} value={entry}>
                                {entry}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
            </Col>
          </Row>
        )}
        {ruleType === 'dateRangeRule' ? renderDescription() : null}
        {ruleType === 'dateRangeRule' ? renderDates() : null}
        {ruleType === 'marketRule' ? renderStateMarket() : null}
        <Row gutter={10}>
          <StyledCol span={24}>
            <Text
              style={{
                color: '#545454',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic',
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              Make these rate adjustments
            </Text>
          </StyledCol>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <NumberInput
              name="margin"
              label="Percentage *"
              suffix="%"
              rules={[
                { required: true, message: 'Please enter a margin' },
                {
                  type: 'number',
                  max: 100,
                  message: 'The margin should not be greater than 100',
                  transform: Number,
                },
                {
                  type: 'number',
                  min: -100,
                  message: 'The margin should not be less than -100',
                  transform: Number,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              name="flat"
              label="Flat *"
              prefix="$"
              rules={[{ required: true, message: 'Please enter a flat' }]}
              labelStyle={{ leftBeforeFloat: '24px' }}
            />
          </Col>
        </Row>
      </Form>
      <Checkbox
        style={{ margin: '7px 0px' }}
        checked={crossRule}
        onChange={() => {
          setcrossRule(!crossRule)
        }}
      >
        <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Master Rule</Text>
      </Checkbox>

      {variant !== 'addForMultiStep' && (
        <CreateMultistepRuleModal
          visible={createMultistepVisible}
          onCloseModalBySuccessfulCreation={() => {
            setCreateMultistepVisible(false)

            onCloseModal(false)
          }}
          onCloseModal={setCreateMultistepVisible}
          changedDefaultValues={changedDefaultValues}
        />
      )}

      <AddToRulesetsModal
        visible={addToRulesetsVisible}
        onClose={() => setAddToRulesetsVisible(false)}
        onTriggerSubmitForm={() => form.submit()}
      />
    </>
  )
}

const defaultActiveKeyEditTab = '1'

const CreateRuleModal = ({
  typesId,
  shippingRule,
  referenceRule,
  columnsParams,
  visible,
  onCloseModal,
  addNewRuleForCreateMultistep,
}) => {
  const dispatch = useDispatch()
  const { success, data, editRule, ruleSelected } = useSelector((state) => state.createRule)
  const { getHistory, successHistory } = useSelector((state) => ({
    getHistory: state.getRuleHistory.history,
    successHistory: state.getRuleHistory.success,
  }))
  const { success: returnSuccess, message: returnMessage, error: returnError } = useSelector(
    (state) => state.returnVersionRule
  )
  const { success: successRules, rules } = useSelector((state) => state.getRules)
  const { success: successCreateMultistep } = useSelector((state) => state.createMultiStep)
  const [loading, setLoading] = useState()

  const [createdRuleId, setCreatedRuleId] = useState('')
  const [deleteRuleWhenClosingModal, setDeleteRuleWhenClosingModal] = useState(false)

  const [clickedOpenCreateMultiStep, setClickedOpenCreateMultiStep] = useState(false)
  const [clickedAddToRulesets, setClickedAddToRulesets] = useState(false)

  const [activeKeyEditTab, setActiveKeyEditTab] = useState(defaultActiveKeyEditTab)
  const autocompleteZip = useAutocompleteZip()
  const { data: dataValidate } = useSelector((state) => state.validateState)
  const hasState = JSON.stringify(dataValidate) !== '{}'

  useEffect(() => {
    if (visible && ruleSelected?._id) {
      dispatch(requestGetRuleHistory({ id: ruleSelected._id }))
    }
  }, [dispatch, visible, ruleSelected])

  useEffect(() => {
    if (returnSuccess && !successRules) {
      notify('success', returnMessage)
      dispatch(clearReturn())
      onCloseModal(false)
    }
    if (returnError) {
      notify('error', returnMessage)
      dispatch(clearReturn())
    }
    if (successHistory) {
      dispatch(clearGetRuleHistory())
    }
  }, [
    dispatch,
    returnSuccess,
    ruleSelected,
    returnMessage,
    returnError,
    successRules,
    rules,
    successHistory,
    onCloseModal,
  ])

  useEffect(() => {
    if (success) {
      setDeleteRuleWhenClosingModal(true)
      setCreatedRuleId(data.id)
    }
  }, [success, data.id])

  useEffect(() => {
    if (successCreateMultistep) {
      setDeleteRuleWhenClosingModal(false)
    }
  }, [successCreateMultistep])

  const variant = (() => {
    if (addNewRuleForCreateMultistep) {
      return 'addForMultiStep'
    }
    if (editRule) {
      return 'edit'
    }
    return 'create'
  })()

  const title = {
    addForMultiStep: 'Add New Rule',
    edit: 'Edit Rule',
    create: 'Create New Rule',
  }[variant]

  const heightByRuleType = {
    [RULE_DATE_RANGE]: 625,
    [RULE_GEOGRAPHIC]: 500,
    [RULE_LENGTH]: 470,
    [RULE_SIMPLE]: 500,
    [EQUIPMENT_TYPE]: 430,
  }

  const formName = variant === 'addForMultiStep' ? 'add-rule-form' : 'create-rule-form'
  const heightRule = `${ruleSelected?.ruleModel ? heightByRuleType[ruleSelected?.ruleModel] : 500}px`
  const isOpenAnotherForm = clickedOpenCreateMultiStep ? true : clickedAddToRulesets

  return (
    <StyledModal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          {variant === 'addForMultiStep' && (
            <AiOutlineArrowLeft
              style={{ color: '#000000', fontSize: '20px', marginRight: 6, cursor: 'pointer' }}
              onClick={() => onCloseModal(false)}
            />
          )}
          <TitleDrawer title={title} />
        </div>
      }
      width={500}
      height={heightRule}
      style={{ top: 100 }}
      afterClose={() => {
        if (editRule) {
          setActiveKeyEditTab(defaultActiveKeyEditTab)
          dispatch(addRule())
        }
        if (hasState) dispatch(clearValidateState())
      }}
      onCancel={() => {
        onCloseModal(false)
        if (deleteRuleWhenClosingModal && isOpenAnotherForm) {
          dispatch(requestDeleteRule(createdRuleId))
          setDeleteRuleWhenClosingModal(false)
          setCreatedRuleId('')
        }
      }}
      visible={visible}
      bodyStyle={{ padding: editRule ? '0px 16px' : '16px 24px 0px' }}
      destroyOnClose
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            flexGrow: 1,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <PrimarySubmit label={editRule ? 'Edit Rule' : 'Save Rule'} formName={formName} loading={loading} />
          {variant !== 'addForMultiStep' && (
            <>
              <Button
                type="text"
                style={{
                  backgroundColor: 'white',
                  marginLeft: 0,
                  marginTop: 16,
                  border: `1px solid ${colors.light_blue.default}`,
                  borderRadius: 4,
                  height: 40,
                }}
                onClick={() => setClickedOpenCreateMultiStep(true)}
              >
                <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Create Multi-Step rule</span>
              </Button>
              <Button
                type="text"
                style={{ marginLeft: 0, marginTop: 16, height: 40 }}
                onClick={() => setClickedAddToRulesets(true)}
              >
                <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Add to RuleSets</span>
              </Button>
            </>
          )}
          {variant === 'addForMultiStep' && (
            <Button
              type="text"
              style={{ backgroundColor: 'white', marginLeft: 0, marginTop: 16 }}
              onClick={() => onCloseModal(false)}
            >
              <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Cancel Creation</span>
            </Button>
          )}
        </div>
      }
      closeIcon={<MdClose size={24} color="#000000" />}
    >
      {editRule ? (
        <StyledTabs
          activeKey={activeKeyEditTab}
          onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
          tabBarStyle={{ width: '100%', marginBottom: 10 }}
        >
          <StyledTabs.TabPane tab="Rules Info" key="1">
            <CreateRuleForm
              variant={variant}
              onCloseModal={onCloseModal}
              typesId={typesId}
              shippingRule={shippingRule}
              referenceRule={referenceRule}
              columnsParams={columnsParams}
              addNewRuleForCreateMultistep={addNewRuleForCreateMultistep}
              autocompleteZip={autocompleteZip}
              formName={formName}
              onChangeLoading={(newLoading) => setLoading(newLoading)}
              clickedOpenCreateMultiStep={clickedOpenCreateMultiStep}
              clickedAddToRulesets={clickedAddToRulesets}
              setClickedOpenCreateMultiStep={setClickedOpenCreateMultiStep}
              setClickedAddToRulesets={setClickedAddToRulesets}
            />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="History" key="2">
            <HistoryItems
              rules
              id="ruleId"
              request={requestReturnVersionRule}
              data={getHistory}
              category={ruleSelected?.ruleModel ?? 'geographicRule'}
              height={`${heightRule - 50}px`}
            />
          </StyledTabs.TabPane>
        </StyledTabs>
      ) : (
        <CreateRuleForm
          variant={variant}
          onCloseModal={onCloseModal}
          typesId={typesId}
          shippingRule={shippingRule}
          referenceRule={referenceRule}
          columnsParams={columnsParams}
          addNewRuleForCreateMultistep={addNewRuleForCreateMultistep}
          autocompleteZip={autocompleteZip}
          formName={formName}
          onChangeLoading={(newLoading) => setLoading(newLoading)}
          clickedOpenCreateMultiStep={clickedOpenCreateMultiStep}
          clickedAddToRulesets={clickedAddToRulesets}
          setClickedOpenCreateMultiStep={setClickedOpenCreateMultiStep}
          setClickedAddToRulesets={setClickedAddToRulesets}
        />
      )}
    </StyledModal>
  )
}

export default CreateRuleModal
