import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import App from './app'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('ally-logistics/truckload-pricing')
  setupLogRocketReact(LogRocket)
  LogRocket.identify('Ally Logistic User')
}
